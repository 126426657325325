import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { ReactiveFormsModule } from "@angular/forms";
import {
    BsDatepickerModule,
    BsDatepickerConfig,
} from "ngx-bootstrap/datepicker";

import {
    DatepickerComponent,
    SelectpickerComponent,
} from "./controls/controls";
import { FilesComponent, ImageFileUploader } from "./files/files.component";
import { DatseFilterComponent } from "./datse-filter/datse-filter.component";
import { PageHeaderInfoComponent } from "./page-header-info/page-header-info.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { MaterialModule } from "../material";
import {
    ConfirmationDialogComponent,
    NoDataComponent,
    PreloadedStructureDialogComponent,
    StructureDialogComponent,
} from "./dialogs/dialogs";
import {
    TreeComponent,
    WorkplaceCollapseComponent,
    WorkplaceEntryViewComponent,
    WorkplaceTreeViewComponent,
} from "./tree/tree.component";
import { UserEntityForm } from "./users/users";
import {
    ArabicDatePipe,
    TranslateDigitsPipe,
    FormatDate,
} from "./pipes/translate-digits.pipe";
import { ChangeUserWorkplaceForm } from "./users/move.user";
import { EmployeeServiceSummaryComponent } from "./summary/service.summary";
import { DataImporterComponent } from "./data-injection/data-injection";

@NgModule({
    declarations: [
        DatepickerComponent,
        SelectpickerComponent,
        FilesComponent,
        ImageFileUploader,
        DatseFilterComponent,
        PageHeaderInfoComponent,
        SpinnerComponent,
        StructureDialogComponent,
        ConfirmationDialogComponent,
        TreeComponent,
        UserEntityForm,
        TranslateDigitsPipe,
        ArabicDatePipe,
        FormatDate,
        ChangeUserWorkplaceForm,
        NoDataComponent,
        EmployeeServiceSummaryComponent,
        WorkplaceTreeViewComponent,
        WorkplaceEntryViewComponent,
        WorkplaceCollapseComponent,
        PreloadedStructureDialogComponent,
        DataImporterComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserModule,
        MaterialModule,
        DataTablesModule,
        BsDatepickerModule.forRoot(),
    ],
    exports: [
        DatepickerComponent,
        SelectpickerComponent,
        FilesComponent,
        ImageFileUploader,
        DatseFilterComponent,
        PageHeaderInfoComponent,
        SpinnerComponent,
        StructureDialogComponent,
        UserEntityForm,
        TranslateDigitsPipe,
        ArabicDatePipe,
        FormatDate,
        ChangeUserWorkplaceForm,
        NoDataComponent,
        EmployeeServiceSummaryComponent,
        WorkplaceTreeViewComponent,
        WorkplaceEntryViewComponent,
        WorkplaceCollapseComponent,
        PreloadedStructureDialogComponent,
        DataImporterComponent,
    ],
    providers: [
        BsDatepickerConfig,
        DatePipe,
        TranslateDigitsPipe,
        ArabicDatePipe,
    ],
})
export class SharedComponentsModule {}
