<div class="wrapper">
    <div class="contents">
        <div class="container">
            <app-page-header-info title2="ألوثائق و ألمستمسكات ألرسمية"></app-page-header-info>

            <div class="content-container my-7 mx-md-4" >
                <div class="card " [ngClass]="{'d-none':view!='list'}">
                    <div class="card-header">
                        <h4><i class="fa fa-id-card-alt pl-3"></i>ألوثائق وألمستمسكات ألمضافه  </h4>
                        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
                            <button class="btn btn-print" (click)="newOrEdit('new')" title="أضافة  مستمسك">
                                <i class="fa fa-plus-circle fa-2x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body px-0">
                        <table id="officaldocuments" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger"
                            class="table w-100 table-bordered table-striped border-0 my-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th class="width-sm-40">نوع ألوثيقه </th>
                                    <th class="d-none d-md-table-cell text-center "> تاريخ ألأضافه</th>
                                    <th class=" d-none d-md-table-cell text-center ">تاريخ اخر تحديث </th>
                                    <th > ألملفات</th>
                                    <th class="text-center"><i class="fa fa-ellipsis-v"></i></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of Items;let i=index;">
                                    <th>{{i+1}}</th>
                                    <th>{{item.type}}</th>
                                    <td class="text-center d-none d-md-table-cell">{{(item.createdAt |formatDate)| arabicDate}}</td>
                                    <td class="text-center d-none d-md-table-cell">{{(item.updatedAt |formatDate) | arabicDate}}</td>
                                    <td class="pr-3">
                                        <div class="d-flex justify-content-around">
                                            <img *ngIf="item.document.faceone" (click)="preview(item.document.faceone)"
                                                [src]="this.fileService.getFileUrl(item.document.faceone)" 
                                                class="document-image  img-thumbnail " />
                                            <img *ngIf="item.document.facetwo"  (click)="preview(item.document.facetwo)"
                                                [src]="this.fileService.getFileUrl(item.document.facetwo)" 
                                                class="document-image  img-thumbnail " />
                                        </div>
                                    </td>
                                    <th class="text-center w-10">
                                        <div class="dropdown">
                                            <button class="btn " type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true">
                                                <i class="fa fa-ellipsis-v text-secondary"></i>
                                            </button>
                                            <ul class="dropdown-menu bg-creamy " aria-labelledby="dropdownMenuButton">
                                                <li class="dropdown-item "
                                                    (click)="SelectedDocument=item;newOrEdit('edit')">
                                                    <i class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                                                    تعديل {{item.type}}
                                                </li>
                                                <li class="dropdown-item"
                                                    (click)="this.SelectedDocument=item;removeDocument();">
                                                    <i class="fa fa-trash text-danger fa-lg px-1 pl-2 "></i>
                                                    حذف {{item.type}}
                                                </li>
                                            </ul>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <app-official-documents-form *ngIf="view=='edit'" 
                    [userId]="this.userId" [Item]="SelectedDocument" 
                    [SelectedId]="this.selectedId"
                    (viewChange)="this.getEmployeeOfficalDocuments();">
                </app-official-documents-form>
                <app-official-documents-form *ngIf="view=='new'" 
                    [userId]="this.userId" 
                    (viewChange)="this.getEmployeeOfficalDocuments();">
                </app-official-documents-form>
            </div>

        </div>
    </div>

    <div class="drag-sidebar left-sidebar border-right">
        <button class="btn close-sidebar-button border border-radius-25 btn-light">
            <i class="fa fa-times hover-danger text-dark"></i>
        </button>
        <div class="drag-list" cdkDropList [cdkDropListConnectedTo]="['container']" id="controlsMenue"
            [cdkDropListData]="documentHelper.documentTypes">
            <div class="drag-item-box" *ngFor="let type of documentHelper.documentTypes; let i = index"
                [cdkDragData]="type.type" cdkDrag [cdkDragDisabled]="isDocumentMaxReached(type) || view!='new'">
                <div class="drag-handle" cdkDragHandle>
                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path
                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </div>
                <span>{{type.type}}</span>
                <i class="fas fa-check-circle  checked-item" aria-hidden="true" *ngIf="isDocumentMaxReached(type)"></i>
            </div>
        </div>
    </div>
</div>