export class Employee{
    /*****  Arabic Name Info *******/ 
    aFirstName:string="";
    aFatherName:String="";
    aGrandFatherName:string="";
    aFourthName:string="";
    aSureName:string="";

    /*****  English Name Info *******/ 
    eFirstName:string="";
    eFatherName:String="";
    eGrandFatherName:string="";
    eFourthName:string="";
    eSureName:string="";

    /*****  Mother Name Info *******/ 
    motherName:string="";
    birthDate:Date=new Date();
    gender:String="ذكر"
    mobile:string='';
    email:string="";
    address:Address=new Address();

    religion:string="مسلم"
    nationalisim:string="عربي"


    getArabicFullName():string{
        return this.aFirstName+" "+this.aFatherName+"  "+this.aGrandFatherName+" "+this.aFourthName+"  "+this.aSureName
    }
    getEnglishFullName():string{
        return this.eFirstName+" "+this.eFatherName+"  "+this.eGrandFatherName+" "+this.eFourthName+"  "+this.eSureName
    }
}


export class Address{
    province:string="نينوى";
    county:string="ألموصل";
    city:string="";
    quarter:string="";
    nearestPlace:string="";
    street:string="";
    subStreet:string="";
    unitNumber:string="";
}