import { Injectable } from "@angular/core";
import { ConstantsUtils } from "../../../../classes/constants";
@Injectable({
    providedIn: "root",
})
export class LocationService {
    constructor() {}
    getProvinces() {
        return ConstantsUtils.getProvincesList();
    }
    getCountries() {
        return ConstantsUtils.getCountriesList();
    }
    getCities(province: string): string[] {
        var cities = ConstantsUtils.getProvinceCitiesByName(province);
        return cities;
    }
}
