import * as moment from 'moment'

export class DatePeriod{
    fromDate:Date=new Date()
    toDate:Date=new Date()
}

export class TimeUtil {
    static format(date:string) {
        return moment(date).format('YYYY-MM-DD');
    }

    static formatTime(minutes:number){
       return moment.utc().startOf('day').add({minutes}).format('HH:mm');
    }

    static timeDuration(from:string,to:string):number{
        var fromTime=moment(from,"HH:mm")
        var toTime=moment(to,"HH:mm")
        return  moment.duration(toTime.diff(fromTime)).asMinutes();
    }

    static timeDurationToDays(totalMinutes:number):number{
        return moment.duration(totalMinutes*4, 'minutes').days()
    }

}



export class DateUtil {
    static durationYMD(fromDate: Date, toDate: Date): string {
        var from = moment(fromDate);
        var to = moment(toDate);
        var diffDuration = moment.duration(to.diff(from));

        var years=diffDuration.years()>0? " "+ diffDuration.years().toString().padStart(2,'0') +" سنه - ":"";
        var months=(diffDuration.months()>0 || years.length)? " "+ diffDuration.months().toString().padStart(2,'0') +"  شهر - ":"";
        var days=" "+diffDuration.days().toString().padStart(2,'0') +"  يوم";

        return years + months +days;
    }
    static formatDate(date:string) {
        return moment(date).format('YYYY-MM-DD');
    }
    static dateDifInDays(fromDate: Date, toDate: Date){
        var from = moment(fromDate);
        var to = moment(toDate);
        var diffDuration = Math.round(moment.duration(to.diff(from)).asDays());
        return diffDuration;

    }

    static formatTotalDuration(totalDays:number){
        var diffDuration=moment.duration(totalDays,'days');
        var years=diffDuration.years()>0? " "+ diffDuration.years().toString().padStart(2,'0') +"  سنه - ":"";
        var months=(diffDuration.months()>0 || years.length)? " "+ diffDuration.months().toString().padStart(2,'0') +"  شهر - ":"";
        var days="  "+ diffDuration.days().toString().padStart(2,'0') +"  يوم";
        return years + months +days;
    }

    static findYearMonthsDaysObject(totalDays:number){
        var diffDuration=moment.duration(totalDays,'days');
        var years=diffDuration.years().toString();
        var months=diffDuration.months().toString();
        var days=diffDuration.days().toString();
        return {
            years,months,days
        }
    }
}
