<div>
    <div class="card mt-5">
        <div class="card-header">
            <h4><i class="fa fa-user-tag"></i> {{title}}</h4>
            <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
                <button class="btn btn-print" (click)="newOrEdit('new')" title="أضافة  منصب أداري جديد  ">
                    <i class="fa fa-plus-circle fa-2x"></i>
                </button>
            </div>
        </div>
        <div class="card-block px-0">
            <table id="timeoff" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger"
                class="table w-100 border-bottom table-striped ">
                <thead >
                    <tr >
                        <th class="d-none d-md-table-cell border-left">#</th>
                        <th class="d-sm-flex d-md-table-cell"> تاريخ ألتكليف</th>
                        <th  class="d-none d-md-table-cell"> تاريخ ألأعفاء</th>
                        <th class="w-25"> ألجهة  ألمسؤول عنها</th>
                        <th class="text-center d-print-none"></th>
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let item of items;let i=index;" >
                        <th class="d-none d-md-table-cell border-left">{{i+1}}</th>
                        <td class="d-block d-md-table-cell pr-3">{{item.fromDate|formatDate |arabicDate}}</td>
                        <td class="d-block d-md-table-cell pr-3">{{item.toDate|formatDate | arabicDate}}</td>
                        <td class="w-50 border-right" >
                            <div class="my-2" style="min-height: 100px !important;">
                                <app-workplace-tree-view [treeData]="getWorkplaceTree(item)"></app-workplace-tree-view>
                            </div>
                            <div class="pb-2 ">
                                <div class=" d-md-flex justify-content-md-between w-100 badge badge-light border-radius-20 border border-success  p-2 d-block" [ngClass]="{'border-danger':item.assignmentType!='أصل'}">
                                    <span class="d-block py-1" >{{item.positionTitle}} </span>
                                    <span class="d-block py-1" *ngIf="item.privateGrade&& item.privateGrade.length">{{item.privateGrade}}</span>
                                    <span class="d-block py-1" >  ( {{item.assignmentType}} ) </span>
                                </div>
                            </div> 
                        </td>
                        <td class="border-right d-print-none">
                            <div class="dropdown">
                                <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                    aria-haspopup="true">
                                    <i class="fa fa-ellipsis-v text-secondary"></i>
                                </button>
                                <ul class="dropdown-menu bg-creamy " aria-labelledby="dropdownMenuButton">
                                    <li class="dropdown-item " (click)="SelectedItem=item;newOrEdit('edit')"><i
                                            class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                                        تعديل ألمنصب ألأداري</li>
                                    <li class="dropdown-item" (click)="this.SelectedItem=item;this.removePosition();">
                                        <i class="fa fa-trash text-danger fa-lg px-1 pl-2 "></i> حذف ألمنصب ألأداري</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>