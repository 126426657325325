import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { DatatableUtil } from "../../../../classes/datatable";
import { DataTableDirective } from "angular-datatables";
import { ModalService } from "src/app/services/modal.service";
import { ActivatedRoute } from "@angular/router";
import {
    GradeModel,
    StageModel,
} from "src/app/models/hr/employee/upgradesbonuses";
import { Grade, grades, GradesUtils } from "src/app/classes/grades";
import {
    BonusService,
    GradeService,
} from "src/app/services/employee/grades-bonuses.service";
import { DateUtil } from "src/app/classes/Utils";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "src/app/shared-components/dialogs/dialogs";
@Component({
    selector: "app-upgrades",
    templateUrl: "./upgrades.component.html",
    styleUrls: ["./upgrades.component.scss"],
})
export class UpgradesTableComponent implements OnInit, AfterViewInit {
    public userId: string;
    view: string = "list";
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    items: any[] = [];

    SelectedItem: any = null;
    constructor(
        private gradeService: GradeService,
        private Activeroute: ActivatedRoute,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {}
    async ngAfterViewInit() {
        this.userId = await this.Activeroute.parent.parent.snapshot.params[
            "userId"
        ];
        this.dt.setDtElement(this.dtElement);
        this.findAll();
        this.dt.trigger();
    }

    findAll() {
        this.gradeService.findAll(this.userId).subscribe((response) => {
            this.items = response;
            this.dt.reload();
            this.view = "list";
        });
    }

    newOrEdit(view: string) {
        if (view == "edit") {
            this.SelectedItem = Object.assign(
                new GradeModel(),
                this.SelectedItem
            );
        }
        this.view = view;
    }

    formatDate(date: string) {
        return DateUtil.formatDate(date);
    }

    delet() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "600px",
            position: {
                top: "65px",
            },
            data: {
                title: ` حذف ألدرجة ألوظيفية  ( ${this.SelectedItem.grade} )`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع ألبيانات ألمتعلقة بهذه ألدرجه ألوظيفية بما فيها ألعلاوات وألملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage: "هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.gradeService
                    .remove(this.SelectedItem._id)
                    .subscribe((response) => {
                        this.findAll();
                    });
            }
        });
    }
}

@Component({
    selector: "app-upgrade-form",
    templateUrl: "./upgrade-form.component.html",
})
export class UpgradeFormComponent implements OnInit {
    @Input() userId: string;
    @Input() Item = new GradeModel();
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    SelectedStage: any = null;
    SelectedIndex: any = 0;
    changing = false;
    isLoading: Boolean = false;

    gradesList = GradesUtils.getGradesList();
    jobTitles: string[] = [];
    reasons: string[] = [
        "ترفيع أعتيادي",
        "أضافة شهادة",
        "تغيير عنوان",
        "تعيين جديد",
        "تنزيل درجه",
    ];

    employeeCategories: string[] = ["فني", "أداري"];
    facultyTitles: string[] = [
        "مدرس مساعد",
        "مدرس جامعي",
        "مدرس جامعي اول",
        "استاذ مساعد",
        "استاذ مشارك",
        "استاذ",
    ];

    constructor(
        private gradesService: GradeService,
        public dialog: MatDialog,
        public modal: ModalService
    ) {}

    ngOnInit(): void {
        this.Item.deserveDate = new Date(this.Item.deserveDate);
        this.Item.assignDate = new Date(this.Item.assignDate);
        if (this.Item._id) {
            var index = this.gradesList.indexOf(this.Item.grade);
            this.jobTitles = grades[index].jobTitles;
        } else {
            this.jobTitles = GradesUtils.getGradeJobTitles(this.Item.grade);
        }
    }

    onGradeChange(grade: string) {
        this.changing = true;
        this.Item.grade = grade;
        this.jobTitles = GradesUtils.getGradeJobTitles(grade);
        this.Item.jobTitle = this.jobTitles[0];
        setTimeout(() => {
            this.changing = false;
        }, 100);
    }
    onJobTitleChange(jobTitle: string) {
        this.Item.employeeType =
            this.facultyTitles.indexOf(jobTitle) == -1 ? "موظف" : "تدريسي";
        this.Item.jobTitle = jobTitle;
    }

    submit() {
        this.isLoading = true;
        if (this.Item._id) {
            this.gradesService
                .update(this.Item._id, this.Item.toFormData())
                .subscribe({
                    next: () => {
                        this.isLoading = false;
                        this.viewChange.emit("true");
                    },
                    error: () => {
                        this.isLoading = false;
                    },
                    complete: () => {
                        this.isLoading = false;
                    },
                });
        } else {
            this.gradesService
                .create(this.userId, this.Item.toFormData())
                .subscribe({
                    next: () => {
                        this.isLoading = false;
                        this.viewChange.emit("true");
                    },
                    error: () => {
                        this.isLoading = false;
                    },
                    complete: () => {
                        this.isLoading = false;
                    },
                });
        }
    }

    removeGradeFile(gradeId: string, fileId: string) {
        this.gradesService.removeGradeFile(gradeId, { fileId }).subscribe();
    }
}

@Component({
    selector: "app-bonuses-list",
    templateUrl: "./bonus-list.component.html",
})
export class BonusListComponent implements OnInit, AfterViewInit {
    @Input() title: string = "";
    @Input() gradeId: string = null;
    @Input() userId: string = null;
    Items: any[] = [];
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();

    SelectedItem: any = null;

    view: string = "list";
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    constructor(
        private gradesService: GradeService,
        private bonusesService: BonusService,
        public dialog: MatDialog
    ) {}
    ngOnInit(): void {}

    async ngAfterViewInit() {
        this.dt.setDtElement(this.dtElement);
        this.findBonuses();
        this.dt.trigger();
    }

    findBonuses() {
        this.bonusesService.findAll(this.gradeId).subscribe((response) => {
            this.Items = response;
            this.dt.reload();
            this.view = "list";
        });
    }

    newOrEdit(view: string) {
        if (view == "edit") {
            this.SelectedItem = Object.assign(
                new StageModel(),
                this.SelectedItem
            );
        } else {
            this.SelectedItem = new StageModel();
        }
        this.view = view;
    }

    delete() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "500px",
            position: {
                top: "65px",
            },
            data: {
                title: ` حذف ألمرحله ( ${this.SelectedItem.stage} )`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات هذه ألعلاوة والملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage: "هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.bonusesService
                    .remove(this.SelectedItem._id)
                    .subscribe((response) => {
                        this.findBonuses();
                    });
            }
        });
    }
}

@Component({
    selector: "app-bonus-form",
    templateUrl: "./bonus-form.component.html",
})
export class BonusFormComponent implements OnInit {
    @Input() gradeId: string = "";
    @Input() stage: StageModel = new StageModel();
    StagesList: number[] = [];
    isLoading: Boolean = false;

    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    constructor(private bonusesService: BonusService) {}
    ngOnInit(): void {
        this.StagesList = Array.from(Array(20).keys());
        this.stage.assignDate = new Date(this.stage.assignDate);
        this.stage.deserveDate = new Date(this.stage.deserveDate);
    }

    submit() {
        this.isLoading = true;
        if (this.stage._id) {
            this.bonusesService
                .update(this.stage._id, this.stage.toFormData())
                .subscribe({
                    next: () => {
                        this.isLoading = false;
                        this.viewChange.emit("true");
                    },
                    error: () => {
                        this.isLoading = false;
                    },
                    complete: () => {
                        this.isLoading = false;
                    },
                });
        } else {
            this.bonusesService
                .create(this.gradeId, this.stage.toFormData())
                .subscribe({
                    next: () => {
                        this.isLoading = false;
                        this.viewChange.emit("true");
                    },
                    error: () => {
                        this.isLoading = false;
                    },
                    complete: () => {
                        this.isLoading = false;
                    },
                });
        }
    }

    removeStageFile(stageId: string, fileId: string) {
        this.bonusesService.removeBonusFile(stageId, { fileId }).subscribe();
    }
}
