
import { Component, Input, OnInit, ViewChild ,AfterViewInit, Output,EventEmitter} from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { SystemGradeService } from "src/app/services/system-manager/system-grades.service";




@Component({
    selector: "app-permanent-grades",
    templateUrl: "./permanent-grades.component.html",
})
export class PermanentGradesComponent implements OnInit ,AfterViewInit{
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    view:string="list";
    Items:any[]=[];
    SelectedItem:any=null;
    constructor(
        private gradeService:SystemGradeService
    ) {}

    ngOnInit(): void {
        
    }

    async ngAfterViewInit() {
        this.dt.setDtElement(this.dtElement);
        this.findAll()
        this.dt.trigger();
    }

    findAll(){
        this.gradeService.findAll().subscribe((response)=>{
            this.Items=response;
            this.dt.reload()
            this.view="list"
        })
    }

    insertMany(){
        this.gradeService.insertMany().subscribe((response)=>{
            this.findAll();
        })
    }

    deleteMany(){
        this.gradeService.removeAll().subscribe(()=>{
            this.findAll();
        })
    }

    newOrEdit(view:string){
        this.view=view;
    }
}



@Component({
    selector: "app-system-grades-form",
    templateUrl: "./system-grades-from.html",
})
export class GradesFormComponent implements OnInit {

  

    degrees:string[]=[
        'دكتوراه',
        'ماجستير',
        'دبلوم عالي',
        'بكالوريوس',
        'بكالوريوس',
        'معهد',
        'أعداديه',
        'متوسطه',
        'أبتدائيه'
    ]
    @Input() Item:any=null;
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private gradeService:SystemGradeService
    ){}
    ngOnInit() {
        
    }

    submit(){
        this.gradeService.update(this.Item._id,this.Item).subscribe(()=>{
            this.viewChange.emit(true)
        })
    }
}


@Component({
    selector: "app-system-jobtitles-form",
    templateUrl: "./system-jobtitles-from.html",
})
export class JobTitlesFormComponent implements OnInit,AfterViewInit {

    @Input() Item:any=null;
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    view:string="list";
    SelectedItem:any=null;
    constructor(
        private gradeService:SystemGradeService
    ){}
    ngOnInit() {
        
    }
    async ngAfterViewInit() {
        this.dt.setDtElement(this.dtElement);
        this.dt.trigger();
    }

    newOrEdit(view:string){
        this.view="edit"
    }

    submit(){
        this.gradeService.update(this.Item._id,this.Item).subscribe(()=>{
            this.view="list"
        })
    }
}



@Component({
    selector: "app-system-stages-form",
    templateUrl: "./system-stages-form.html",
})
export class StagesFormComponent implements OnInit,AfterViewInit {

    @Input() Item:any=null;
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    view:string="list";
    SelectedItem:any=null;
    constructor(
        private gradeService:SystemGradeService
    ){}
    ngOnInit() {
        
    }
    async ngAfterViewInit() {
        this.dt.setDtElement(this.dtElement);
        this.dt.trigger();
    }

    newOrEdit(view:string){
        this.view="edit"
    }

    submit(){
        this.gradeService.update(this.Item._id,this.Item).subscribe(()=>{
            this.viewChange.emit(true)
        })
    }

    saveStage(){
        this.gradeService.update(this.Item._id,this.Item).subscribe(()=>{
            this.view="list"
        })
    }
}



