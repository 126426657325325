<main *ngIf="vm$ | async as vm" style="display: grid; padding: 2rem;">
    <div *ngIf="vm.workplace === null">
        <h1>Workplace not found!</h1>
    </div>
    <div *ngIf="vm.workplace !== null">
        <h1>{{ vm.workplace.name }}</h1>
        <div class="card-block px-0">
            <table id="timeoff" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger"
                class="table table-bordered table-striped border-0">
                <thead>
                    <tr>
                        <th class="width-10">#</th>
                        <th class="width-40">ألاسم</th>
                        <th class="width-40">البريد الالكتروني</th>
                        <th class="text-center width-10"><i class="fa fa-ellipsis-v"></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let employee of vm.employees;let i=index;">
                        <th>{{i+1}}</th>
                        <td>{{employee.fullname}}</td>
                        <td dir="ltr"><input #input style="width: 100%;" type="text" [value]="employee.email"></td>
                        <td class="text-center"><i class="fa fa-save" (click)="updateUserEmail(employee._id, input.value)"></i></td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</main>