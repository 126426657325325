import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { PromotionUtilService } from "./promotion-util-service";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { DgreeService } from "src/app/services/employee/dgrees.service";
import { FacultyPromotionModel } from "src/app/models/hr/employee/faculty-promotions";
import { FacultyPromotionService } from "src/app/services/employee/promotions.service";
import { ConfirmationDialogComponent } from "src/app/shared-components/dialogs/dialogs";

@Component({
    selector: "app-faculty-promotions",
    templateUrl: "./faculty-promotions.component.html",
    styleUrls: ["./faculty-promotions.component.scss"],
})
export class FacultyPromotionsComponent {
    userId: string = null;
    SelectedItem: any = null;
    SelectedIndex: number = null;

    view: string = "list";
    promotions: any[] = [];
    Items: any[] = [];

    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();

    constructor(
        private promotionHttpService: FacultyPromotionService,
        public promotionUtilService: PromotionUtilService,
        private Activeroute: ActivatedRoute,
        private dialog: MatDialog
    ) {}

    async ngAfterViewInit() {
        this.userId = await this.Activeroute.parent.snapshot.params["userId"];
        this.dt.setDtElement(this.dtElement);
        this.getFacultyPromotions();
        this.dt.trigger();
    }

    getFacultyPromotions() {
        this.promotionHttpService.findAll(this.userId).subscribe((response) => {
            this.Items = response;
            this.dt.reload();
            this.view = "list";
        });
    }

    removePromotion() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title: `حذف لقب   ${this.SelectedItem.type}`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات أللقب ألعلمي بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage: "هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.promotionHttpService
                    .remove(this.SelectedItem._id)
                    .subscribe((response) => {
                        this.getFacultyPromotions();
                    });
            }
        });
    }

    hasType(type: string) {
        return this.Items.findIndex((item) => item.type == type) > -1;
    }

    newOrEdit(view: string) {
        if (view == "edit") {
            this.SelectedItem = Object.assign(
                new FacultyPromotionModel(this.SelectedItem.type),
                this.SelectedItem
            );
        }
        this.view = view;
    }
}

@Component({
    selector: "app-promotion-form",
    templateUrl: "./promotion.form.component.html",
})
export class PromotionFormComponent implements OnInit {
    @Input() userId: string = null;
    @Input() Item: FacultyPromotionModel = null;
    @Output() viewChange: EventEmitter<string> = new EventEmitter<string>();
    isSubmitted: boolean = false;
    reasons: string[] = ["أعتيادية", "أضافة شهادة"];
    isLoading: Boolean = false;

    constructor(
        private promotionHttpService: FacultyPromotionService,
        public promotionUtilService: PromotionUtilService,
        private degreeHttpService: DgreeService
    ) {}
    ngOnInit(): void {
        if (this.Item != null) {
            this.Item.promotionDate = new Date(this.Item.promotionDate);
            this.Item.documentDate = new Date(this.Item.documentDate);
        }
    }
    drop(event: CdkDragDrop<any>) {
        this.Item = new FacultyPromotionModel(event.item.data);
    }

    submit() {
        this.isLoading = true;
        if (this.Item._id) {
            this.promotionHttpService
                .update(this.Item._id, this.Item.toFormData())
                .subscribe({
                    next: () => {
                        this.isLoading = false;
                        this.viewChange.emit("true");
                    },
                    error: () => {
                        this.isLoading = false;
                    },
                    complete: () => {
                        this.isLoading = false;
                    },
                });
        } else {
            this.promotionHttpService
                .create(this.userId, this.Item.toFormData())
                .subscribe({
                    next: () => {
                        this.isLoading = false;
                        this.viewChange.emit("true");
                    },
                    error: () => {
                        this.isLoading = false;
                    },
                    complete: () => {
                        this.isLoading = false;
                    },
                });
        }
    }

    removeFile(promotionId: string, fileId: string) {
        this.promotionHttpService
            .removeFile(promotionId, { fileId })
            .subscribe();
    }
}
