import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class UniversityService {
    private name:string="";
    constructor() {
        this.Name="جامعة ألموصل"
    }
    get Name(){
        return this.name
    }
    set Name(value:string){
        this.name=value;
    }
}
