import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-admin',
  templateUrl: './hr-admin.component.html',
})
export class HrAdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
