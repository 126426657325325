export class GradesUtils {
    static getGradesList(){
        var list = grades.map(function (item) {
            return item.grade;
        });
        return list;
    }

    static getGradeJobTitles(grade:string){
        var index=grades.findIndex((g)=>g.grade==grade);
        return grades[index].jobTitles;

    }


}

export interface Grade{
    grade:string;
    numberOfStages:number;
    jobTitles:string[]
}

export const privateGrades:Array<Grade>=[
    {
        grade:" خاصة (عليا أ)",
        numberOfStages:10,
        jobTitles:[
            "وكيل وزارة",
            "محافظ",
            "مستشار",
            "رئيس المجلس العراقي للاختصاصات الطبية",
            "رئيس مجلس شورى الدولة",
            "رئيس الجهاز",
            "رئيس جهاز الاشراف",
            "سفير",
            "رئيس جامعة",
            "مفتش عام",
            "الامين العام",
            "رئيس الديوان",
            "رئيس المحكمة",
            "نواب رئيس المحكمة",
            "رئيس هيئة التقاعد الوطنية",
            "نائب رئيس ديوان الرقابة المالية",
            "مستشار الامن القومي",
            "نائب المستشار",
            "نائب رئيس الهيئة الوطنية للاستثمار",
            "رئيس ديوان مجلس النواب",
            "قضاة المحكمة",
            "قضاة محكمة التمييز",
            "رئيس هيئة الاشراف القضائي",
            "رئيس الادعاء العام",
            "نائب رئيس هيئة النزاهة",
            "فريق اول",
            "فريق",
            "نائب رئيس المفوضية",
            "رئيس الهيئة العراقية للحاسبات والمعلوماتية",
        ]
    },
    {
        grade:"خاصة(عليا ب )",
        numberOfStages:10,
        jobTitles:[
            "نائب محافظ",
            "قاضي",
            "قاضي\/صنف اول",
            "قاضي\/صنف ثاني",
            "قاضي\/صنف ثالث",
            "قاضي\/صنف رابع",
            "مدعي عام \/ صنف اول",
            "مدعي عام \/ صنف ثاني",
            "مدعي عام \/ صنف ثالث",
            "مدعي عام \/ صنف رابع",
            "مدير عام",
            "قائم مقام",
            "عميد",
            "رئيس الهيئة",
            "مساعد رئيس جامعة",
            "مفتش اداري",
            "مستشار مساعد",
            "معاون رئيس الديوان",
            "وزير مفوض",
            "رئيس قسم علمي",
            "لواء",
        ]
    },
]

export const grades:Array<Grade>=[
    {
        grade:"الاولى",
        numberOfStages:5,
        jobTitles:[
            "خبير",
            "استاذ",
            "معاون مدير عام",
            "معاون محافظ للشؤون الادارية",
            "معاون محافظ للشؤون الفنية",
            "معاون محافظ للشؤون الامنية",
            "معاون محافظ",
            "معاون مفتش عام",
            "مدير تنفيذي",
            "مستشار قانوني في الوزارة",
            "رئيس اطباء اختصاص",
            "طبيب استشاري",
            "صيدلي استشاري",
            "طبيب اسنان استشاري",
            "طبيب بيطري استشاري",
            "معاون عميد",
            "مستشار في ملحقية تجارية",
            "سكرتير عام مجلس شورى الدولة",
            "معلم جامعي اقدم اول",
            "مشرف تربوي اقدم اول",
            "مدرس اقدم اول",
            "اختصاص تربوي اقدم اول",
            "مفتش تربوي اقدم",
            "مرشد تربوي اقدم اول",
            "مشرف فني اقدم اول",
            "امام وخطيب اول",
            "عميد\/ عسكري",
            "مدير ناحية",
            "معاون محافظ للشؤون المالية",
        ]
    },
    {
        grade:"الثانية",
        numberOfStages:5,
        jobTitles:[
            "رئيس هيئة الرقابة المالية اقدم",
            "استاذ مشارك",
            "مدير اقدم",
            "مدير مال اقدم",
            "مدير فني اقدم",
            "رئيس مهندسين اقدم",
            "رئيس مهندسين زراعيين اقدم",
            "رئيس مشرفين سياحيين اقدم",
            "رئيس مهندسين تقنيين اقدم\/اجهزة طبية",
            "رئيس مترجمين اقدم",
            "رئيس احصائيين اقدم",
            "رئيس اطباء ممارسين بفرع اقدم",
            "رئيس اطباء ممارسين تخدير",
            "رئيس اطباء عموميين اقدم",
            "رئيس اطباء ممارسين طب عدلي",
            "طبيب اختصاص اقدم",
            "رئيس ابحاث اقدم",
            "رئيس مبرمجين اقدم",
            "رئيس محللين اقدم",
            "رئيس اختصاصي نظم ومعلومات اقدم",
            "مدير حاسبة",
            "رئيس صيادلة اقدم",
            "صيدلي اختصاص اقدم",
            "مدير حسابات اقدم",
            "مدير تدقيق اقدم",
            "رئيس اختصاص الغام اقدم",
            "رئيس امناء مكتبة اقدم",
            "رئيس اطباء اسنان اقدم",
            "طبيب اسنان اختصاص اقدم",
            "رئيس اطباء بيطرين اقدم",
            "طبيب بيطري اختصاص اقدم",
            "رئيس منقب اثار اقدم",
            "امين متحف اقدم",
            "رئيس جيولوجي اقدم",
            "مستشار قانوني مساعد",
            "رئيس كيمياوي اقدم",
            "رئيس فيزياوي اقدم",
            "رئيس بايولوجي اقدم",
            "رئيس بكتريولوجي اقدم",
            "رئيس تقنيين طبيين اقدم",
            "رئيس ممرض وممرضة جامعي اقدم",
            "رئيس مصورين اقدم",
            "رئيس مصممين اقدم",
            "رئيس مصححين اقدم",
            "معاون قضائي اقدم",
            "كاتب عدل اقدم",
            "منفذ عدل اقدم",
            "محقق عدل اقدم",
            "مدير تسجيل عقاري اقدم",
            "مدير قاصرين اقدم",
            "باحث اجتماعي اقدم",
            "امام وخطيب اقدم",
            "سادن اقدم",
            "امام اقدم",
            "واعظ اقدم",
            "مفتش اول",
            "مفتش تربوي",
            "رئيس مهندسين تقنيين اقدم",
            "رئيس مراقب مطار اقدم",
            "رئيس مراقب قاعة اقدم",
            "رئيس مرشدين اقدم",
            "رئيس ضابط مخابرة اقدم",
            "رئيس طيارين اقدم",
            "رئيس مراقبي نقل جوي اقدم",
            "رئيس مراقبيين جويين اقدم",
            "رئيس مخمنيين اقدم",
            "رئيس مراقبيين فنيين اقدم",
            "رئيس متنبئين جويين اقدم",
            "رئيس راصد جوي اقدم",
            "رئيس تحري معلومات اقدم",
            "رئيس مدربين رياضيين اقدم",
            "رئيس مراقبين اقدم",
            "رئيس محققين اقدم",
            "رئيس مدربين فنيين اقدم",
            "ملحق تجاري",
            "محاسب قانوني ثاني",
            "معلم جامعي اقدم ثاني",
            "مشرف تربوي اقدم ثاني",
            "مدرس اقدم ثاني",
            "اختصاص تربوي اقدم ثاني",
            "معلم اقدم ثاني",
            "معلم صناعي اقدم ثاني",
            "مرشد تربوي اقدم ثاني",
            "مشرف فني اقدم ثاني",
            "عقيد",
            "مستشار دبلوماسي",
            "رئيس ربان طائرة اقدم",
            "رئيس ضابط عمليات جوية اقدم",
            "رئيس مهندسي طائرات اقدم",
            "رئيس مهندسين جويين اقدم",
            "رئيس ضابط بحري اقدم",
            "امام وخطيب ثاني",
            "رئيس مضيفين اقدم"
        ]

    },
    {
        grade:"الثالثة",
        numberOfStages:5,
        jobTitles:[
            "رئيس هيئة الرقابة المالية",
            "سكرتير اول",
            "استاذ مساعد",
            "مدير",
            "مدير فني",
            "مدير مال",
            "مدير قطاع",
            "رئيس اطباء عموميين",
            "ممارس طب عدلي اقدم",
            "ممارس تخدير اقدم",
            "رئيس مهندسين",
            "رئيس مهندسين زراعيين",
            "رئيس مهندسين تقنيين\/اجهزة طبية",
            "رئيس مشرفين سياحيين",
            "رئيس مترجمين",
            "رئيس احصائيين",
            "رئيس مبرمجين",
            "رئيس محللي انظمة",
            "رئيس مصورين",
            "رئيس مساحين اقدم",
            "رئيس جيولوجي",
            "مدير اعلامي",
            "رئيس اختصاص نظم ومعلومات",
            "مدير حسابات",
            "مدير تدقيق",
            "اختصاص تربوي اول",
            "رئيس مرشدين اجتماعيين",
            "رئيس مامورين",
            "مدرس اقدم",
            "مشرف تربوي اقدم",
            "معلم اقدم",
            "مشاور قانوني اقدم",
            "رئيس اختصاصي الغام",
            "رئيس امناء مكتبة",
            "منقب اثار اول",
            "امين متحف اول",
            "رئيس رسامين هندسي اقدم",
            "رئيس رسامين اقدم",
            "مشرف فني اقدم",
            "رئيس اطباء ممارسين بفرع",
            "طبيب اختصاص",
            "رئيس صيادلة",
            "صيدلي اختصاص",
            "رئيس اطباء اسنان",
            "طبيب اسنان اختصاص",
            "رئيس اطباء بيطريين",
            "طبيب بيطري اختصاص",
            "رئيس كيمياويين",
            "رئيس فيزياويين",
            "رئيس بايولوجي",
            "رئيس بكتريولوجي",
            "رئيس مرشدين زراعيين اقدم",
            "رئيس تقنيين طبيين",
            "رئيس ممرضين وممرضات جامعات",
            "رئيس مصممين",
            "رئيس مصححين",
            "معاون قضائي اول",
            "كاتب عدل اول",
            "مدير تسجيل عقاري",
            "مدير قاصرين",
            "باحث اجتماعي اول",
            "منفذ عدل اول",
            "محقق عدلي اول",
            "سادن اول",
            "امام اول",
            "واعظ اول",
            "مفتش ثان",
            "رئيس مهندسين تقنيين",
            "رئيس ابحاث",
            "رئيس مراقب مطار",
            "رئيس مراقب قاعة",
            "رئيس مرشدين",
            "رئيس مخمنيين",
            "رئيس مراقبين فنيين",
            "رئيس طيارين",
            "رئيس ضباط اطفاء",
            "رئيس ضباط انقاذ",
            "رئيس مراقبي نقل جوي",
            "رئيس مراقبين جويين",
            "رئيس متنبئين جويين",
            "رئيس راصد جوي",
            "رئيس تحري معلومات",
            "رئيس منتجين اقدم",
            "رئيس مدربيين رياضيين",
            "مرشد تربوي اقدم",
            "معلم جامعي اقدم",
            "رئيس مراقبين",
            "محقق اول",
            "رئيس مدربين فنيين",
            "معاون ملحق",
            "محاسب قانوني ثالث",
            "معلم ومعلم جامعي اقدم",
            "معلم صناعي اقدم",
            "اختصاص تربوي اقدم",
            "رئيس مشرحين",
            "مدير تدقيق حسابات",
            "مقدم",
            "مدير مخازن",
            "رئيس ربان طائرة",
            "رئيس ضابط عمليات جوية",
            "رئيس مهندسي طائرات",
            "رئيس مهندسين جويين",
            "رئيس ضابط بحري",
            "امام وخطيب ثالث",
            "رئيس مضيفين",
            "رئيس ضابط مخابرة",
        ]
    },
    {
        grade:"الرابعة",
        numberOfStages:5,
        jobTitles:[
            "معاون رئيس هيئة الرقابة المالية",
            "مدرس جامعي اول",
            "سكرتير ثان",
            "معاون مدير",
            "مامور مال ثاني",
            "معاون مدير فني",
            "طبيب عمومي اقدم",
            "طبيب اسنان ممارس",
            "طبيب ممارس تخدير",
            "طبيب ممارس عدلي",
            "رئيس قابلات ماهرات اقدم",
            "معاون رئيس مهندسين",
            "معاون رئيس مهندسين زراعيين",
            "معاون رئيس مهندسين تقنيين\/اجهزة طبية",
            "معاون رئيس مترجمين",
            "معاون رئيس احصائيين",
            "معاون رئيس مشرفين سياحيين",
            "محرر اقدم",
            "منظم ارشيف اقدم",
            "معاون رئيس مبرمجين",
            "معاون رئيس ابحاث",
            "معاون رئيس محللي انظمة",
            "معاون رئيس اختصاصي نظم ومعلومات",
            "معاون رئيس مصممين",
            "معاون رئيس مصححين",
            "معاون مدير حسابات",
            "معاون مدير تدقيق",
            "مدير مدرسة(تدريسية)",
            "اختصاص تربوي",
            "مدرس اول",
            "امين متحف ثان",
            "مشرف فني اول",
            "مشرف تربوي اول",
            "رئيس مساحين",
            "رئيس مضمدين اقدم",
            "معلم اول",
            "مشاور قانوني",
            "معاون رئيس اختصاص الغام",
            "معاون رئيس امناء مكتبة",
            "رئيس رسامين هندسي",
            "رئيس رسامين",
            "معاون قضائي ثان",
            "كاتب عدل ثان",
            "منفذ عدل ثان",
            "محقق عدل ثان",
            "باحث اجتماعي ثان",
            "معاون رئيس مخمنيين",
            "معاون رئيس مصوريين",
            "معاون مدير اعلامي",
            "معاون رئيس مرشدين",
            "مامور قسم اقدم",
            "رئيس رقباء اصلاحية",
            "طبيب ممارس بفرع",
            "صيدلي اقدم",
            "طبيب اسنان اقدم",
            "طبيب بيطري ممارس اقدم",
            "معاون رئيس كيمياويين",
            "معاون رئيس فيزياويين",
            "معاون رئيس بايولوجي",
            "معاون رئيس بكتريولوجي",
            "رئيس مرشدين زراعيين",
            "معاون رئيس تقنيين طبين",
            "امام وخطيب ثان",
            "سادن ثان",
            "امام ثان",
            "واعظ ثان",
            "رئيس ممرض او رئيسة ممرضات اقدم",
            "رئيس معاون طبي اقدم",
            "معاون رئيس ممرض وممرضة جامعية",
            "رئيس ممرض او ممرضة ماهرة اقدم",
            "معاون رئيس مهندسين تقنيين",
            "مفتش ثالث",
            "معاون رئيس مراقبي مطار",
            "معاون رئيس مراقب قاعة",
            "معاون رئيس ضابط مخابرة",
            "معاون رئيس طيارين",
            "معاون رئيس جيولوجي",
            "ضابط اطفاء اقدم",
            "ضابط انقاذ اقدم",
            "معاون رئيس مراقبي نقل جوي",
            "معاون رئيس مراقبيين جويين",
            "منقب اثار ثان",
            "معاون رئيس متنبئين جويين",
            "معاون رئيس راصد جوي",
            "معاون رئيس تحري معلومات",
            "رئيس ممرض او ممرضة فني اقدم",
            "رئيس معاون وقائي اقدم",
            "رئيس مساعد مختبر اقدم",
            "رئيس منتجين",
            "معاون رئيس مدربين رياضيين",
            "رئيس صيدلي اقدم",
            "مرشد تربوي اول",
            "معلم جامعي اول",
            "معلم صناعي اول",
            "معاون رئيس مراقبين فنيين",
            "محقق ثاني",
            "معاون رئيس مدربين فنيين",
            "محاسب قانوني رابع",
            "معلم ومعلم جامعي اول",
            "معاون رئيس مشرحين",
            "معاون مدير تدقيق حسابات",
            "نائب ضابط ممتاز",
            "مفوض 1",
            "رائد",
            "معاون مدير مخازن",
            "ربان طائرة اقدم",
            "معاون رئيس ضابط عمليات جوية",
            "معاون رئيس مهندسي طائرات",
            "معاون رئيس مهندسين جويين",
            "بحار سادس",
            "معاون رئيس ضابط بحري",
            "امام وواعظ اول",
            "امام وخطيب",
            "معاون رئيس مضيفين",
        ]
    },
    {
        grade:"الخامسة",
        numberOfStages:5,
        jobTitles:[
            "مدرس جامعي",
            "سكرتير ثالث",
            "رقيب مالي اقدم",
            "رئيس ملاحظين",
            "مامور مال ثالث",
            "رئيس ملاحظين فني",
            "امين مخزن اقدم",
            "مشرف سياحي اقدم",
            "رئيس امناء صناديق",
            "طبيب اسنان عمومي",
            "طبيب عمومي",
            "طبيب تدرج",
            "صيدلي مختبرات",
            "صيدلي سريري",
            "رئيسة قابلات ماهرات",
            "رئيس مقيم اقدم",
            "مهندس اقدم",
            "مهندس زراعي اقدم",
            "مهندس تقني اقدم\/اجهزة طبية",
            "مترجم اقدم",
            "احصائي اقدم",
            "باحث اقدم",
            "محرر اول",
            "منظم ارشيف اول",
            "مبرمج اقدم",
            "محلل انظمة اقدم",
            "اختصاص نظم ومعلومات اقدم",
            "رئيس مشغلي حاسبة",
            "رئيس مشرف بيانات",
            "رئيس حرفيين اقدم",
            "محاسب اقدم",
            "مدقق اقدم",
            "مدرس ثان",
            "مدير مدرسة(تعليمية)",
            "مشرف تربوي",
            "معلم ثان",
            "مشاور قانوني مساعد",
            "اختصاص الغام اقدم",
            "امين مكتبة اقدم",
            "معاون رئيس رسامين هندسي",
            "معاون رئيس رسامين",
            "رئيس كتاب طابعة",
            "رئيس سواق اقدم",
            "مصمم اقدم",
            "مصحح اقدم",
            "امين متحف ثالث",
            "مشرف فني ثان",
            "مخمن اقدم",
            "فاحص نقد اقدم",
            "معاون رئيس مساحين",
            "مهندس تقني اقدم",
            "رئيس مضمدين",
            "مصور اقدم",
            "رئيس ملاحظين اعلامي",
            "مرشد اجتماعي اقدم",
            "مامور قسم اول",
            "رقيب اصلاحية اقدم",
            "حارس اصلاحية اقدم",
            "معاون قضائي ثالث",
            "كاتب عدل ثالث",
            "منفذ عدل ثالث",
            "محقق عدل ثالث",
            "باحث اجتماعي ثالث",
            "طبيب مقيم اقدم",
            "كيمياوي اقدم",
            "فيزياوي اقدم",
            "بايولوجي اقدم",
            "صيدلي عمومي",
            "بكتريولوجي اقدم",
            "طبيب بيطري ممارس",
            "معاون رئيس مرشدين زراعيين",
            "تقني طبي اقدم",
            "رئيس معاون طبي",
            "رئيس معاون مختبر",
            "ممرض او ممرضة جامعية اقدم",
            "رئيس ممرض او ممرضة ماهرة",
            "رئيس ممرض او ممرضة فنية",
            "رئيس ممرض او ممرضة",
            "رئيس ماموري كمارك",
            "سادن ثالث",
            "امام ثالث",
            "واعظ ثالث",
            "قارئ ومؤذن اقدم",
            "خادم ومؤذن اقدم",
            "مؤذن اقدم",
            "خادم اقدم",
            "مفتش رابع",
            "رئيس حراس اقدم",
            "مراقب مطار اقدم",
            "مراقب قاعة اقدم",
            "مرشد اقدم",
            "ضابط مخابرة اقدم",
            "جيولوجي اقدم",
            "مراقب فني اقدم",
            "طيار اقدم",
            "ضابط اطفاء",
            "ضابط انقاذ",
            "مراقب نقل جوي اقدم",
            "مراقب جوي اقدم",
            "منقب اثار ثالث",
            "متنبئ جوي اقدم",
            "راصد جوي اقدم",
            "تحري معلومات اقدم",
            "رئيس معاون وقائي",
            "معاون رئيس منتجين",
            "مدرب رياضي اقدم",
            "رئيس مصورين شعاعيين اقدم",
            "رئيس معالجين طبيعيين اقدم",
            "رئيس صيدلي",
            "مرشد تربوي ثاني",
            "معلم جامعي ثاني",
            "معلم صناعي ثاني",
            "مراقب اقدم",
            "محقق ثالث",
            "مامور تسجيل اقدم",
            "رئيس سواق اطفاء اقدم",
            "مدرب فني اقدم",
            "معلم ومعلم جامعي ثاني",
            "مدقق حسابات اقدم",
            "مشرح اقدم",
            "مفوض 2",
            "مفوض 3",
            "نائب ضابط د1",
            "نائب ضابط د2",
            "نقيب",
            "ربان طائرة",
            "ضابط عمليات جوية اقدم",
            "مهندس طائرات اقدم",
            "مهندس جوي اقدم",
            "بحار خامس",
            "ضابط بحري اقدم",
            "امام وواعظ ثاني",
            "امام وخطيب مدرس",
            "مضيف طائرة اقدم",
        ]
    },
    {
        grade:"السادسة",
        numberOfStages:5,
        jobTitles:[
            "ملحق",
            "مدرس مساعد",
            "رقيب مالي",
            "ملاحظ",
            "مامور مال رابع",
            "ملاحظ فني",
            "امين مخزن",
            "مشرف سياحي",
            "معاون رئيس امناء صناديق",
            "مهندس",
            "مهندس زراعي",
            "مهندس تقني \/اجهزة",
            "مترجم",
            "احصائي",
            "باحث",
            "مبرمج",
            "محلل انظمة",
            "محرر ثان",
            "منظم ارشيف ثان",
            "مصمم",
            "اختصاص نظم ومعلومات",
            "مشرف بيانات اقدم",
            "مشغل حاسبة اقدم",
            "رئيس حرفيين",
            "محاسب",
            "مدقق",
            "مدقق حسابات",
            "معيد طبيب",
            "منقب اثار رابع",
            "مدرس ثالث",
            "معلم ثالث",
            "قانوني",
            "اختصاص الغام",
            "امين مكتبة",
            "رسام هندسي اقدم",
            "رسام اقدم",
            "معاون رئيس كتاب طابعة",
            "رئيس سواق",
            "امين متحف رابع",
            "مشرف فني ثالث",
            "فاحص نقد اول",
            "مساح اقدم",
            "جيولوجي",
            "معاون رئيس مضمدين",
            "مصور",
            "ملاحظ اعلامي",
            "مرشد اجتماعي اول",
            "مامور قسم ثان",
            "رقيب اصلاحية اول",
            "حارس اصلاحية اول",
            "مخمن",
            "معاون قضائي رابع",
            "كاتب عدل رابع",
            "منفذ عدل رابع",
            "محقق عدل رابع",
            "باحث اجتماعي رابع",
            "مبلغ اقدم",
            "طبيب مقيم دوري",
            "طبيب بيطري متدرب",
            "كيمياوي",
            "فيزياوي",
            "بايولوجي",
            "بكتريولوجي",
            "مرشد زراعي اول",
            "تقني طبي",
            "صيدلي متدرب",
            "طبيب اسنان متدرب",
            "معاون رئيس معاون طبي",
            "معاون رئيس معاون مختبر",
            "ممرض وممرضة جامعية",
            "معاون رئيس ممرض او ممرضة ماهرة",
            "معاون رئيس ممرض او ممرضة فنية",
            "معاون رئيس ممرضين او رئيسة ممرضات",
            "معاون رئيس قابلات ماهرات",
            "معاون مهندس معماري",
            "معاون مهندس طب حياتي",
            "رئيس مقييمين دوريين",
            "صيدلي تدرج",
            "معاون رئيس مامور كمارك",
            "امام وخطيب رابع",
            "سادن رابع",
            "امام رابع",
            "واعظ رابع",
            "قارئ ومؤذن اول",
            "خادم ومؤذن اول",
            "مؤذن اول",
            "خادم اول",
            "مفتش خامس",
            "مهندس تقني",
            "رئيس حراس",
            "مراقب مطار اول",
            "مراقب قاعة اول",
            "مرشد اول",
            "ضابط مخابرة",
            "طيار",
            "رئيس عرفاء اطفاء",
            "رئيس عرفاء انقاذ",
            "مراقب نقل جوي اول",
            "مراقب جوي اول",
            "مصحح",
            "متنبئ جوي",
            "راصد جوي",
            "تحري معلومات",
            "طبيب اسنان تدرج",
            "معاون رئيس تقني اجهزة طبية",
            "صيدلي ممارس",
            "معاون رئيس معاون وقائي",
            "منتج اقدم",
            "مدرب رياضي",
            "رئيس مصورين شعاعيين",
            "رئيس معالجين طبيعيين",
            "معاون رئيس صيدلي",
            "مرشد تربوي ثالث",
            "معلم جامعي ثالث",
            "معلم صناعي ثالث",
            "مراقب اول",
            "محقق رابع",
            "مامور تسجيل اول",
            "رئيس سواق اطفاء",
            "مراقب فني اول",
            "مدرب فني",
            "معلم ومعلم جامعي ثالث",
            "مشرح اول",
            "مفوض 4",
            "مفوض 5",
            "مفوض 6",
            "نائب ضابط د3",
            "نائب ضابط د4",
            "نائب ضابط د5",
            "ملازم اول",
            "معاون ربان طائرة اقدم",
            "ضابط عمليات جوية",
            "مهندس طائرات",
            "مهندس جوي",
            "بحار رابع",
            "ضابط بحري اول",
            "امام وواعظ ثالث",
            "امام وخطيب متدرج",
            "مضيف طائرة",
            "طبيب تدرج",
        ]
    },
    {
        grade:"السابعه",
        numberOfStages:5,
        jobTitles:[
            "معاون رقيب مالي",
            "معيد",
            "معاون ملاحظ",
            "مامور مال خامس",
            "معاون ملاحظ فني",
            "معاون امين مخزن",
            "امين صندوق اقدم",
            "معاون مهندس",
            "معاون مهندس زراعي",
            "معاون مترجم",
            "معاون مشرف سياحي",
            "معاون باحث",
            "معاون احصائي",
            "معاون مبرمج",
            "محرر ثالث",
            "منظم ارشيف ثالث",
            "معاون محلل انظمة",
            "معاون اختصاص نظم ومعلومات",
            "مشرف بيانات",
            "مشغل حاسبة",
            "معاون رئيس حرفيين",
            "معاون محاسب",
            "معاون مدقق",
            "معاون مصمم",
            "معاون منقب اثار",
            "مدرس رابع",
            "معلم رابع",
            "معاون قانوني",
            "معاون اختصاص الغام",
            "معاون امين مكتبة",
            "رسام هندسي",
            "رسام",
            "كاتب طابعة اقدم",
            "معاون امين متحف",
            "مشرف فني رابع",
            "مامور بدالة اقدم",
            "فاحص نقد ثان",
            "معاون جيولوجي",
            "ممرض او ممرضة اقدم",
            "معاون مراقب كمرك",
            "مساح",
            "رئيس محصلين",
            "معاون رئيس سواق",
            "معاون مخمن",
            "معاون مصور",
            "معاون ملاحظ اعلامي",
            "مرشد اجتماعي ثان",
            "مامور قسم ثالث",
            "رقيب اصلاحية ثان",
            "معاون قضائي خامس",
            "حارس اصلاحية ثان",
            "كاتب عدل خامس",
            "منفذ عدل خامس",
            "محقق عدلي خامس",
            "باحث اجتماعي خامس",
            "مبلغ اول",
            "معاون كيمياوي",
            "معاون فيزياوي",
            "معاون بايولوجي",
            "معاون بكتريولوجي",
            "مرشد زراعي",
            "تقني طبي متدرب",
            "معاون طبي اقدم",
            "معاون مختبر اقدم",
            "ممرض وممرضة جامعية متدربة",
            "ممرض او ممرضة ماهرة اقدم",
            "مامور كمرك",
            "معاون امام وخطيب",
            "معاون سادن",
            "امام خامس",
            "واعظ خامس",
            "قارئ ومؤذن ثان",
            "خادم ومؤذن ثان",
            "مؤذن ثاني",
            "خادم ثان",
            "معاون مفتش",
            "معاون مهندس تقني",
            "معاون رئيس حراس",
            "مراقب مطار",
            "مراقب قاعة",
            "مرشد",
            "مراقب لاسلكي",
            "مراقب فني",
            "معاون طيار",
            "عريف اطفاء",
            "عريف انقاذ",
            "مراقب نقل جوي",
            "مراقب جوي",
            "معاون مصحح",
            "معاون متنبئ جوي",
            "معاون راصد جوي",
            "معاون تحري معلومات",
            "قابلة ماهرة قدمى",
            "ممرض فني اقدم",
            "معاون تقني اجهزة طبية اقدم",
            "معاون وقائي اقدم",
            "منتج تامين",
            "معاون مدرب رياضي",
            "معاون رئيس مصورين شعاعيين",
            "معاون معالجين طبيعيين",
            "صيدلي",
            "مرشد تربوي رابع",
            "معلم جامعي رابع",
            "معلم صناعي رابع",
            "مراقب",
            "محقق خامس",
            "مامور تسجيل ثاني",
            "معاون رئيس سواق اطفاء",
            "معاون مدرب فني",
            "معلم ومعلم جامعي رابع",
            "معاون مدقق حسابات",
            "مشرح",
            "مفوض 7",
            "مفوض 8",
            "نائب ضابط د6",
            "نائب ضابط د7",
            "نائب ضابط د8",
            "ملازم",
            "معاون ربان طائرة",
            "معاون ضابط عمليات جوية",
            "معاون مهندس طائرات",
            "معاون مهندس جوي",
            "بحار ثالث",
            "معاون ضابط بحري",
            "امام متدرج",
            "امام وخطيب معلم",
            "مضيف اول"
        ]
    },
    {
        grade:"الثامنة",
        numberOfStages:2,
        jobTitles:[
            "معاون رقيب اقدم",
            "مسجل بيانات",
            "مراجع بيانات",
            "حرفي اقدم",
            "كاتب حسابات",
            "كاتب تدقيق",
            "معلم خامس",
            "كاتب",
            "معاون رسام هندسي",
            "معاون رسام",
            "كاتب طابعة اول",
            "مامور بدالة",
            "محصل اقدم",
            "سائق اقدم",
            "معاون مرشد اجتماعي",
            "رقيب اصلاحية ثالث",
            "حارس اصلاحية ثالث",
            "معاون قضائي سادس",
            "مبلغ ثان",
            "مراقب زراعي",
            "معاون طبي \/معاون مختبر طبي",
            "معاون وقائي متدرب",
            "ممرضة فنية",
            "معاون صيدلي",
            "معاون طبي متدرب",
            "فاحص بصر",
            "مثقف صحي",
            "موظف صحي",
            "معاون مخدر",
            "باحثة صحية",
            "تقني اجهزة طبية",
            "نظاراتي",
            "معاون مختبر متدرب",
            "معاون فاحص نقد",
            "معاون محرر",
            "معاون منظم ارشيف",
            "امين صندوق",
            "معاون مساح",
            "مضمد",
            "ممرض او ممرضة ماهرة",
            "ممرض او ممرضة",
            "مراقب كمارك",
            "معاون امام",
            "معاون واعظ",
            "قارئ ومؤذن ثالث",
            "فني",
            "خادم ومؤذن ثالث",
            "مؤذن ثالث",
            "خادم ثالث",
            "حارس اقدم",
            "معاون مرشد",
            "معاون مراقب لاسلكي",
            "نائب عريف اطفاء",
            "نائب عريف انقاذ",
            "معاون مراقب نقل جوي",
            "معاون مراقب جوي",
            "راصد جوي متدرب",
            "قابلة ماهرة",
            "معاون طبي تدرج",
            "معاون منتج تامين",
            "مصور شعاعي",
            "معالج طبيعي",
            "معلم صناعي خامس",
            "مشرف فني خامس",
            "معاون مراقب",
            "مامور تسجيل ثالث",
            "سائق اطفاء اقدم",
            "معاون مراقب فني",
            "معاون مراقب قاعة",
            "معاون مراقب مطار",
            "معاون مامور مال",
            "رئيس عرفاء",
            "مساعد مشرح",
            "بحار ثاني",
            "امام مبتدى",
            "مضيف ثاني",        
        ]
    },
    {
        grade:"التاسعة",
        numberOfStages:5,
        jobTitles:[
            "حرفي اول",
            "موظف خدمات اقدم",
            "سائق اول",
            "كاتب طابعة ثان",
            "معاون مامور بدالة",
            "محصل",
            "مبلغ ثالث",
            "حارس اصلاحية رابع",
            "قارئ ومؤذن رابع",
            "خادم ومؤذن رابع",
            "مؤذن رابع",
            "خادم رابع",
            "معاون امين صندوق",
            "معاون مضمد",
            "حارس اول",
            "جندي اطفاء",
            "جندي انقاذ",
            "مامور تسجيل رابع",
            "طالب كلية الشرطة والمعهد العالي",
            "عريف",
            "نائب عريف",
            "بحار اول",
            "سائق اطفاء اول",
        ]
    },
    {
        grade:"العاشره",
        numberOfStages:5,
        jobTitles:[
            "حرفي",
            "موظف خدمات",
            "كاتب طابعة ثالث",
            "مشغل بدالة اول",
            "سائق ثان",
            "حارس اصلاحية خامس",
            "قارئ ومؤذن خامس",
            "خادم ومؤذن خامس",
            "مؤذن خامس",
            "خادم خامس",
            "حارس ثاني",
            "اطفائي",
            "معاون موظف خدمات",
            "معاون حرفي",
            "مشفل بدالة",
            "سائق ثالث",
            "خادم سادس",
            "مؤذن سادس",
            "حارس ثالث",
            "شرطي اول",
            "شرطي مشاة وطالب اعدادية شرطة",
            "جندي اول",
            "جندي",
            "سائق اطفاء ثان"
        ]
    }

]