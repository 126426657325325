import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ConstantsUtils } from "src/app/classes/constants";
import { EmploymentInfo } from "src/app/models/hr/employee/employmentinfo";
import { EmploymentInfoService } from "src/app/services/employee/employment-info.service";
import { EmploymentUtilService } from "./employment-util-service";


@Component({
    selector: "app-employment-info",
    templateUrl: "./employment-info.component.html",
    styleUrls: ["./employment-info.component.scss"],
})
export class EmploymentInfoComponent implements OnInit {
    EmploymentInfo:EmploymentInfo=new EmploymentInfo();
    employmentTypes:string[]=["دائمي","عقد","أجور يومية"]
   
    yesNo:string[]=ConstantsUtils.getYesNo();
    userId:string;
    constructor(public employmentinfoService:EmploymentInfoService, private Activeroute:ActivatedRoute) {}

    async ngOnInit() {
        this.userId=await this.Activeroute.parent.snapshot.params["userId"];
        this.getEmployeeEmploymentInfo()
    }

    getEmployeeEmploymentInfo(){
        this.employmentinfoService.findOne(this.userId).subscribe((response)=>{
            if (response != null) {
                this.EmploymentInfo = Object.assign(
                    new EmploymentInfo(),
                    response
                );
                this.EmploymentInfo.hireDate = new Date(
                    response.hireDate
                );
            }
        })
    }

    submit(frm:NgForm){
        this.employmentinfoService.createOrUpdate(this.userId,this.EmploymentInfo.toFormData()).subscribe((response)=>{
            this.getEmployeeEmploymentInfo()
        })
    }

    removeFile(fileId:string,field:string){
        this.employmentinfoService.removeFile(this.userId,{fileId,field}).subscribe()
    }


    print(){
        window.print()
    }
}

@Component({
    selector: "app-employment-basic",
    templateUrl: "./templates/employment-basic.html",
})
export class EmploymentBasicComponent implements OnInit {
    employmentTypes:string[]=["دائمي","عقد","أجور يومية"]
    yesNo:string[]=ConstantsUtils.getYesNo();
    constructor(
        public employmentUtileService:EmploymentUtilService,
        private Activeroute:ActivatedRoute
    ){}
    async ngOnInit(){
        this.employmentUtileService.userId=await this.Activeroute.parent.parent.snapshot.params["userId"];
        this.employmentUtileService.getEmployeeEmploymentInfo();
   
    }    
}


@Component({
    selector: "app-employment-status",
    templateUrl: "./templates/employment-status.html",
})
export class EmploymentStatusComponent implements OnInit {
    employmentStatuses:string[]=["مستمر","متقاعد","مستقيل","مفصول","متوفي"]
    constructor(
        public employmentUtileService:EmploymentUtilService,
        private Activeroute:ActivatedRoute
    ){}
    async ngOnInit(){
        this.employmentUtileService.userId=await this.Activeroute.parent.parent.snapshot.params["userId"];
        this.employmentUtileService.getEmployeeEmploymentInfo();
    }    
}


@Component({
    selector: "app-employment-notes",
    templateUrl: "./templates/employment-notes.html",
})
export class EmploymentNotesComponent implements OnInit {
    constructor(
        public employmentUtileService:EmploymentUtilService,
        private Activeroute:ActivatedRoute
    ){}
    async ngOnInit(){
        this.employmentUtileService.userId=await this.Activeroute.parent.parent.snapshot.params["userId"];
        this.employmentUtileService.getEmployeeEmploymentInfo();
   
    }    
}



