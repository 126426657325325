<div class="card">
    <div class="card-header">
        <h4 class="pl-4"> <i class="fa fa-user-minus "> </i> نقل [ {{User.fullname}} ] </h4>

        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
            <button class="btn btn-print" (click)="submit()" title="حفظ ألمعلومات ">
                <i class="fa fa-save fa-lg"></i>
            </button>
            <button class="btn btn-print" title="رجوع ألى ألقائمه"  (click)="viewChange.emit('list')">
                <i class="fa fa-share fa-lg"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <form #frm="ngForm" [ariaDisabled]="true">
            <div class="row">
                <div class="col-md-8">
                    <label class=" d-block"> ألجهة ألمنقول أليها  <span class="font-weight-bold">:</span> </label>
                    <div class="px-2 d-flex  border pt-1  border-radius-25 bg-white" >
                        <div class="icheck-greensea  w-50 ">
                            <input class="box" type="radio" name="minstrytype" [(ngModel)]="workplace.isMoh" (click)="resetWorkplace()"
                                [value]="true" id="university">
                            <label for="university" class="px-2">  داخل ألوزارة</label>
                        </div>
                        <div class="icheck-greensea w-50 ">
                            <input class="box" type="radio" name="ministrytype" [(ngModel)]="workplace.isMoh" (click)="resetWorkplace()"
                                [value]="false" id="internal">
                            <label for="internal" class="px-2">  خارج ألوزارة  </label>
                        </div>
                    </div>
    
                    <div class="d-flex border pt-1 px-2 my-3  border-radius-25 bg-white" *ngIf="workplace.isMoh">
                        <div class="icheck-greensea w-50  ">
                            <input class="box" type="radio" name="universityType" [(ngModel)]="isUniversity"  (click)="resetWorkplace()"
                                [value]="true" id="moh-yes">
                            <label for="moh-yes" class="px-2"> داخل {{University.Name}}</label>
                        </div>
                        <div class="icheck-greensea w-50">
                            <input class="box" type="radio" name="universityType" [(ngModel)]="isUniversity"  (click)="resetWorkplace()"
                                [value]="false" id="moh-no">
                            <label for="moh-no" class="px-2">  خارج  {{University.Name}}  </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 ">
                    <label class=" "> نوع ألتنقل : </label>
                    <div class="d-flex justify-content-between border pt-1 px-2  border-radius-25 bg-white" >
                        <div class="icheck-greensea ">
                            <input class="box" type="radio" name="move" [(ngModel)]="workplace.movementType"
                                value="دائمي" id="move_1">
                            <label for="move_1" class="px-2"> دائمي </label>
                        </div>
                        <div class="icheck-greensea  ">
                            <input class="box" type="radio" name="move" [(ngModel)]="workplace.movementType"
                                value="تنسيب" id="move_2">
                            <label for="move_2" class="px-2"> تنسيب </label>
                        </div>
                    </div>
    
                    <div class="my-3" *ngIf="workplace.isMoh && isUniversity==true">
                        <button type="button" class="btn btn-success btn-block  border-radius-25" (click)="openDialog();">
                            <i class="fa fa-university mx-2"></i> ألجهة ألمنقول أليها
                        </button>
                    </div>
                    <div class="my-3" *ngIf="workplace.isMoh && isUniversity==false">
                        <button type="button" class="btn btn-success btn-block  border-radius-25" (click)="openMinistryDialog();">
                            <i class="fa fa-university mx-2"></i> ألجهة ألمنقول أليها
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-2">
                    <div class="border h-100  border-radius-20">
                        <label class="border-bottom p-2 d-block font-bold"> نقل من : </label>
                        <div class="px-1" *ngIf="MainWorkplace.fullname.length">
                            <app-workplace-tree-view  [treeData]="sourceTreeData"></app-workplace-tree-view>
                        </div>
                    </div>
                </div>
    
                <div class="col-md-6">
                    <div class="border h-100  border-radius-20">
                        <label class="border-bottom p-2 d-block font-bold">  ألى : </label>
                        <div class="px-1"  *ngIf="workplace.isMoh">
                            <app-workplace-tree-view  [treeData]="destTreeData"></app-workplace-tree-view>
                        </div>
                        <div class="px-2" *ngIf="!workplace.isMoh">
                            <app-workplace-entry-view  [Data]="destTreeData" [titles]="titles" (dataChange)="onDataChange()">
                            </app-workplace-entry-view>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row">
                <div class="col-md-4">
                    <app-datepicker [input]="MainWorkplace.toDate" [name]="'endDate'" [label]="'تاريخ ألأنفكاك'"
                        (dateChange)="MainWorkplace.toDate=$event">
                    </app-datepicker>
                </div>

                <div class="col-md-4">
                    <app-datepicker [input]="MainWorkplace.toDocumentDate" [name]="'toDocumentDate'"
                        [label]="'تاريخ ألأمر ألأداري بالأنفكاك '" (dateChange)="MainWorkplace.toDocumentDate=$event">
                    </app-datepicker>
                </div>
                <div class="col-md-4">
                    <label for="toDocumentNumber" class="form-label"> رقم ألامر ألأداري بالأنفكاك:</label>
                    <div class="input-group">
                        <input type="text" id="toDocumentNumber" name="toDocumentNumber" #toDocumentNumber="ngModel"
                            class="form-control text-center" [(ngModel)]="MainWorkplace.toDocumentNumber">
                        <div class="input-group-prepend">
                            <span class="input-group-text font-bold">
                                #
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline"  dir="rtl" class="w-100 py-0">
                        <mat-label class=" px-3">ألملاحضات (للموقع ألحالي) </mat-label>
                        <textarea matInput name="oldnotes" placeholder="" rows="3" class="py-0"
                            [(ngModel)]="MainWorkplace.notes"></textarea>
                        <mat-icon matSuffix class="text-secondary ">comment</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline"  dir="rtl" class="w-100 py-0">
                        <mat-label class=" px-3">ألملاحضات (للموقع الجديد) </mat-label>
                        <textarea matInput name="newNotes" placeholder="" rows="3" class="py-0"
                            [(ngModel)]="workplace.notes"></textarea>
                        <mat-icon matSuffix class="text-secondary ">comment</mat-icon>
                    </mat-form-field>
                </div>
            </div>


            <div class="row">
                <div class="col-md-12">
                    <app-files [Files]="MainWorkplace.Files" [header]="'ألأوامر ألأدارية ألخاصة  بالنقل والانفكاك'">
                    </app-files>
                    <div *ngIf="(!MainWorkplace.Files.hasFiles() || !MainWorkplace.Files.isValid()) && isSubmitted"
                        class="text-danger" style="font-size: 12px !important;">
                        <div> * يجب تحميل ألملفات ألخاصة بالنقل وألانفكاك </div>
                    </div>
                </div>
            </div>

        </form>



    </div>
</div>