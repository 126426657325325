<div [ngClass]="{'d-none':view!='list'}">
    <div class="card mt-6">
        <div class="card-header">
            <h4><i class="fas fa-signal pl-3"></i>جدول علاوات ألدرجة  {{title}}</h4>
            <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
                <button class="btn btn-print" (click)="newOrEdit('new')" title="أضافة مرحله">
                    <i class="fa fa-plus-circle fa-2x"></i>
                </button>
                <button class="btn btn-print"  title="رجوع ألى ألقائمه" (click)="viewChange.emit('list')">
                    <i class="fa fa-share fa-lg"></i>
                </button>
            </div>
        </div>
        <div class="card-block px-0">
    
            <table id="upgradesummary" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger" 
                class="table w-100 table-bordered table-striped border-0 my-0">
            <thead>
                <tr >
                    <th class="d-none d-md-table-cell">#</th>
                    <th class="w-25">ألمرحله </th>
                    <th class="w-25">تاريخ ألاستحقاق</th>
                    <th class="d-none d-md-table-cell"> رقم ألأمر ألأداري </th>
                    <th class="text-center width-10" style="width:15%;"><i class="fa fa-ellipsis-v"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Items;let i=index;">
                    <th class="d-none d-md-table-cell">{{i+1}}</th>
                    <td class="pr-3 d-flex justify-content-between">
                        <span>{{item.stage}}</span>
                        <span *ngIf="item.isComplementry==true">تعويضية</span>
                    </td>
                    <td class="pr-3">{{item.deserveDate|formatDate}}</td>
                    <td class="pr-3 d-none d-md-table-cell">{{item.documentNumber}}</td>
                    <th class="text-center">
                        <div class="dropdown">
                            <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true">
                                <i class="fa fa-ellipsis-v text-secondary"></i>
                            </button>
                            <ul class="dropdown-menu bg-creamy " aria-labelledby="dropdownMenuButton">
                                <li class="dropdown-item " (click)="SelectedItem=item;newOrEdit('edit')"><i
                                    class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                                    تعديل ألمرحله ( {{item.stage}} )
                                </li>
                                <li class="dropdown-item"
                                    (click)="this.SelectedItem=item;this.delete();">
                                    <i class="fa fa-trash text-danger fa-lg px-1 pl-2 "></i>
                                    حذف ألمرحلة  ( {{item.stage}} )
                                </li>
                            </ul>
                        </div>
                    </th>
                </tr>
            </tbody>
        </table>
        </div>
    </div>

</div>

<app-bonus-form *ngIf="view=='new'||view=='edit'"
    [stage]="SelectedItem"  
    [gradeId]="gradeId" 
    (viewChange)="this.findBonuses()">
</app-bonus-form>
