


<div class="wrapper">
    <div class="contents">
        <div class="container">
            <app-page-header-info title2="ألمعلومات ألوظيفية"></app-page-header-info>
            <div class="content-container my-7 mx-md-4">
                <router-outlet></router-outlet>
            </div>

        </div>
    </div>
    <div class="sidebar left-sidebar border-right">
        <ul class="list-unstyled mb-10 ">
            <li class="border-top-0">
                <a routerLink="employmentbasic" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i>  معلومات  ألتعيين    
               </a>
            </li>
            <li>
                <a routerLink="employmentstatus" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i>  حالة ألموظف 
               </a>
            </li>
            <li>
                <a routerLink="employmentnotes" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i>  وثيقة ألملاحظات  
               </a>
            </li>
        </ul>

    </div>
</div>
