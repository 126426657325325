import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { User } from "src/app/models/hr/employee/user";
import { Role, UserRole } from "src/app/models/system/roles";
import {
    SystemRolesService,
    USerRolesService,
} from "src/app/services/system-manager/system-roles.service";
import { UsersService } from "src/app/services/users/users.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatDialog } from "@angular/material/dialog";
import { StructureDialogComponent } from "src/app/shared-components/dialogs/dialogs";
import { WorkplaceUtilService } from "src/app/services/util/workplace.service";
import { StructureService } from "src/app/services/system-manager/structure-http-service";
declare var $: any;
const roleLevels: string[] = ["قراءه", "كتابه", "حذف"];

@Component({
    selector: "app-users-management",
    templateUrl: "./users-management.html",
})
export class UsersManagmentComponent implements AfterViewInit {
    SelectedWorkplace: any = null;

    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    Items: any[] = [];
    view: string = "list";
    SelectedWorkplaceId:string=null;
    SelectedUser:any=null;

    constructor(
        private userService: UsersService,
        private dialog: MatDialog,
        public workplaceUtilService: WorkplaceUtilService,
        private structureService: StructureService
    ) {}
    
    async ngAfterViewInit(){
        this.dt.setDtElement(this.dtElement);
        this.dt.trigger(); 
    }
    

    openDialog() {
        const dialogRef = this.dialog.open(StructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: "جامعة ألموصل",
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.SelectedWorkplaceId=result._id;
                this.findEmployees();
                this.getWorkplace();
            }
        });
    }

    findEmployees() {
        this.userService
            .findWorkPlaceEmployees(this.SelectedWorkplaceId)
            .subscribe((response) => {
                this.view = "list";
                this.Items = response;
                this.dt.reload();
                
            });
    }

    header:string="";
    getWorkplace() {
        this.structureService
            .findOne(this.SelectedWorkplaceId)
            .subscribe((response) => {
                this.SelectedWorkplace = response;
                this.getWorkplaceTree();
                this.header=this.SelectedWorkplace.name;
               
            });
    }
    treeData:any[]=[];
    newOrEdit(view: string) {
        this.view = view;
    }
    getWorkplaceTree() {
        this.treeData=this.workplaceUtilService.convertToTree(
            this.workplaceUtilService.getFullPath(this.SelectedWorkplace).split("-")
        );
    }
}

@Component({
    selector: "app-user-roles",
    templateUrl: "./user-roles.html",
})
export class UserRolesComponent implements OnInit {
    @Input() userId: string = null;
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    User: User = new User();
    userRoles: any[] = [];
    roleLevels = roleLevels;

    groupedRoles: any[] = [];

    constructor(
        private userService: UsersService,
        private roleService: SystemRolesService,
        private userRoleService: USerRolesService,
        public workplaceUtilService: WorkplaceUtilService,
        public dialog: MatDialog,
        private Activeroute: ActivatedRoute
    ) {}

    async ngOnInit() {
        $(document).ready(function () {
            $("#main-container").toggleClass("m-0");
            $("#main-sidebar").toggleClass("d-none");
            $("#main-header").toggleClass("d-none");
        });
        $("#back-to-main").on("click", function () {
            $("#main-container").toggleClass("m-0");
            $("#main-sidebar").toggleClass("d-none");
            $("#main-header").toggleClass("d-none");
        });

        if (!this.userId) {
            this.userId = await this.Activeroute.snapshot.params["userId"];
        }
        this.findUser();
        this.findGroupedRoles();
        this.findUserRoles();
    }

    findUser() {
        this.userService.findOne(this.userId).subscribe((response) => {
            this.User = Object.assign(this.User, response);
        });
    }

    findUserRoles() {
        this.userRoleService.findAll(this.userId).subscribe((response) => {
            this.userRoles = response;
        });
    }

    findGroupedRoles() {
        this.roleService.findAll().subscribe((response) => {
            this.groupedRoles = response;
        });
    }

    removeUserRole(id: string) {
        this.userRoleService
            .remove(id)
            .subscribe((response) => (this.userRoles = response));
    }

    isChecked(role: any, level: string) {
        return role.priviliges.indexOf(level) !== -1;
    }

    openDialog(event: CdkDragDrop<any>): void {
        const dialogRef = this.dialog.open(StructureDialogComponent, {
            width: "61vw",
            enterAnimationDuration: "500ms",
            exitAnimationDuration: "500ms",
            position: {
                top: "65px",
            },
            data: { title: "أختيار ألجهة ألمسؤول عنها" },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                var userRole = new UserRole(
                    event.item.data.roleId,
                    this.User._id,
                    result._id
                );
                this.userRoleService.create(userRole).subscribe((response) => {
                    this.userRoles = response;
                });
            }
        });
    }

    isCategoryExist(category: string) {
        return (
            this.userRoles.findIndex(
                (role) => role.roleId.category == category
            ) != -1
        );
    }

    isRoleExist(roleId: string) {
        return (
            this.userRoles.findIndex((role) => role.roleId._id == roleId) != -1
        );
    }

    updateWorkplace(id: string) {
        const dialogRef = this.dialog.open(StructureDialogComponent, {
            width: "61vw",
            enterAnimationDuration: "500ms",
            exitAnimationDuration: "500ms",

            position: {
                top: "65px",
            },
            data: { title: "أختيار ألجهة ألمسؤول عنها" },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.userRoleService
                    .update(id, { workplaceId: result._id })
                    .subscribe((response) => {
                        this.userRoles = response;
                    });
            }
        });
    }
}
