import { Injectable } from "@angular/core";
import {
    ElementryDegreeForm,
    IntermediateDegreeForm,
    HisghschoolDegreeForm,
    UndergraduateDegreeForm,
    GraduateDegreeForm,
    NoDegree
} from "../../../models/hr/employee/degrees";
import { DgreeService } from "src/app/services/employee/dgrees.service";
@Injectable({
    providedIn: "root",
})
export class PromotionUtilService {
    promotionTypes = [
        { type: 'مدرس مساعد', max: 1},
        { type: 'مدرس', max: 1},
        { type: 'أستاذ مساعد', max: 1 },
        { type:  'أستاذ', max: 1 },  
    ];

    constructor(private degreeHttpSevice:DgreeService) {}



    jsonToPromotionObject(degree:any){
        var degreeObject:any=null;
        degreeObject=Object.assign(new NoDegree(degree.type),degree);
        
        return degreeObject;
    }
   


    removeFile(degreeId:string, fileId:string){
        this.degreeHttpSevice.removeDegreeFile(degreeId,{fileId}).subscribe()
    }

}
