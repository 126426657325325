import { Injectable } from "@angular/core";
import { NgForm } from "@angular/forms";
import { EmploymentInfo } from "src/app/models/hr/employee/employmentinfo";
import { EmploymentInfoService } from "src/app/services/employee/employment-info.service";

@Injectable({
    providedIn: "root",
})
export class EmploymentUtilService {
    EmploymentInfo: EmploymentInfo = new EmploymentInfo();
    userId: string;
    isLoading: Boolean = false;

    constructor(public employmentinfoService: EmploymentInfoService) {}

    getEmployeeEmploymentInfo() {
        this.employmentinfoService
            .findOne(this.userId)
            .subscribe((response) => {
                if (response != null) {
                    this.EmploymentInfo = Object.assign(
                        new EmploymentInfo(),
                        response
                    );
                    this.EmploymentInfo.hireDate = new Date(response.hireDate);
                    this.EmploymentInfo.hireStartDate = new Date(
                        this.EmploymentInfo.hireStartDate
                    );
                    this.EmploymentInfo.movingToMohDate = new Date(
                        this.EmploymentInfo.movingToMohDate
                    );

                    this.EmploymentInfo.rehireDate = new Date(
                        this.EmploymentInfo.rehireDate
                    );
                    this.EmploymentInfo.rehireStartDate = new Date(
                        this.EmploymentInfo.rehireStartDate
                    );

                    this.EmploymentInfo.movingToMohStartDate = new Date(
                        this.EmploymentInfo.movingToMohStartDate
                    );
                    if (this.EmploymentInfo.employeeStatus != "مستمر")
                        this.EmploymentInfo.terminationDate = new Date(
                            response.terminationDate
                        );
                }
            });
    }

    submit(frm: NgForm) {
        console.log(this.EmploymentInfo);
        this.isLoading = true;
        this.employmentinfoService
            .createOrUpdate(this.userId, this.EmploymentInfo.toFormData())
            .subscribe({
                next: () => {
                    this.getEmployeeEmploymentInfo();
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
    }

    removeFile(fileId: string, field: string) {
        this.employmentinfoService
            .removeFile(this.userId, { fileId, field })
            .subscribe();
    }
}
