import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {  MinistryStructureComponent, OrganizationStructuresComponent, UniversityStructureComponent } from "./organization-chart/organization-chart.component";
import { SystemGradesComponent } from "./system-grades/system-grades.component";
import { RolesManagementComponent} from "./system-roles/roles-management/roles-management.component";
import { SystemManagerComponent } from "./system-manager/system-manager.component";
import { FinancialAllowancesComponent } from "./components/financial-allowances/financial-allowances.component";
import { RoleGuards } from "../services/guards/roleGuard";
import { PermanentGradesComponent } from "./system-grades/permanent-grades/permanent-grades";
import { ContractGradesComponent } from "./system-grades/contract-grades/contract-grades.component";
import { DailyGradesComponent } from "./system-grades/daily-grades/daily-grades.component";
import { SystemRolesComponent } from "./system-roles/system-roles";
import { UsersManagmentComponent } from "./system-roles/accounts-managments/accounts-manangement";

const routes: Routes = [

    {
        path:'systemmanager',
        component:SystemManagerComponent,
        canActivate:[RoleGuards],
        data:{
            expectedRole: 'systemmanager'
        },
        children:[
            {
                path: "",
                pathMatch: "full",
                redirectTo: "Organizational-structures",

            },
            {
                path:'Organizational-structures',
                component:OrganizationStructuresComponent,
                children:[
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "university",
                    },
                    {
                        path: "university",
                        component: UniversityStructureComponent,
                    },
                    {
                        path:'minstry',
                        component: MinistryStructureComponent,
                    }
                ]
            },
            {
                path:'grades',
                component:SystemGradesComponent,
                children:[
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "permanent-grades",
                    },
                    {
                        path: "permanent-grades",
                        component: PermanentGradesComponent,
                    },
                    {
                        path: "contract-grades",
                        component: ContractGradesComponent,
                    },
                    {
                        path: "daily-grades",
                        component: DailyGradesComponent,
                    }
                ]
            },
            {
                path:'roles',
                component:SystemRolesComponent,
                children:[
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "roles-management",
                    },
                    {
                        path: "roles-management",
                        component: RolesManagementComponent,
                    },
                    {
                        path: "accounts-management",
                        component: UsersManagmentComponent,
                    }
                ]
            }
            ,
            {
                path:'allowances',
                component:FinancialAllowancesComponent
            }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SystemManagerRoutingModule {}
