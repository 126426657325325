import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { HttpLoadingService } from "../../shared/http-loading.service";

@Injectable({
    providedIn: "root",
})
export class TimeOffService {
    private url = environment.ApiUrl + "/vacations/timeoff/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    findAll(userId: string): Observable<any> {
        return this.http.get(this.url + userId +`?cache_bust=${new Date().getTime()}`);
    }
    create(userId: string, data: any): Observable<any> {
        return this.http.post(this.url + userId, data);
    }

    update(timeOffId: string, data: any): Observable<any> {
        return this.http.patch(this.url + timeOffId, data);
    }

    filter(userId:string,data:any):Observable<any>{
        return this.http.post(this.url+'filter/'+userId +`?cache_bust=${new Date().getTime()}`,data)
    }

    remove(timeOffId: string) {
        return this.http.delete(this.url + timeOffId);
    }
}
