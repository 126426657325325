<div class="wrapper">
    <div  class="contents" >
        <div class="container">
            <app-page-header-info title2="ألألقاب ألعلمية"></app-page-header-info>
            <div class="content-container my-7 mx-md-4">
                <div class="card "  [ngClass]="{'d-none':view!='list'}">
                    <div class="card-header">
                        <h4><i class="fa fa-star pl-3"></i>ألألقاب ألعلمية</h4>
                        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
                            <button class="btn btn-print" (click)="newOrEdit('new')" title="أضافة لقب علمي">
                                <i class="fa fa-plus-circle fa-2x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-block px-0">
                        <table id="degreeslist" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger" class="table table-bordered table-striped border-0 my-0">
                        <thead>
                            <tr >
                                <th class="d-none d-md-table-cell">#</th>
                                <th >أللقب ألعلمي   </th>
                                <th> تاريخ ألحصول عليه </th>
                                <th class="width-40 d-none d-md-table-cell"> ألتخصص ألدقيق  </th>
                                <th class="text-center width-15"><i class="fa fa-ellipsis-v"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Items;let i=index;">
                                <th class="d-none d-md-table-cell">{{i+1}}</th>
                                <th>{{item.type}}</th>
                                <td class="text-center">{{(item.promotionDate| formatDate) | arabicDate}}</td>
                                <td class="pr-3 width-40 d-none d-md-table-cell">{{item.fieldOfSpecialization}}</td>
                                <th class="text-center w-15">
                                    <div class="dropdown">
                                        <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                            aria-haspopup="true">
                                            <i class="fa fa-ellipsis-v text-secondary"></i>
                                        </button>
                                        <ul class="dropdown-menu bg-creamy " aria-labelledby="dropdownMenuButton">
                                            <li class="dropdown-item " (click)="SelectedItem=item;newOrEdit('edit')"><i
                                                    class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                                                تعديل أللقب ألعلمي
                                            </li>
                                            <li class="dropdown-item"
                                                (click)="this.SelectedItem=item;removePromotion();"><i
                                                    class="fa fa-trash text-danger fa-lg px-1 pl-2 "></i> حذف أللقب ألعلمي
                                            </li>
                                        </ul>
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <app-promotion-form *ngIf="view=='edit'" [userId]="this.userId" [Item]="SelectedItem"  (viewChange)="this.getFacultyPromotions();"></app-promotion-form>
                <app-promotion-form *ngIf="view=='new'" [userId]="this.userId" (viewChange)="this.getFacultyPromotions();"></app-promotion-form>
            </div>
        </div>
    </div>
    <div class="drag-sidebar left-sidebar border-right">
        <div class="drag-list" cdkDropList [cdkDropListConnectedTo]="['container']" id="controlsMenue"
            [cdkDropListData]="promotionUtilService.promotionTypes">
            <div class="drag-item-box" *ngFor="let type of promotionUtilService.promotionTypes; let i = index"
                [cdkDragData]="type.type" cdkDrag [cdkDragDisabled]="hasType(type.type) || view!='new'"  >
                <div class="drag-handle" cdkDragHandle>
                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path
                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </div>
                <i class="fas fa-check-circle  checked-item" aria-hidden="true"  *ngIf="hasType(type.type)"></i>
                <span >{{type.type}}</span>
            </div>
        </div>
    </div>
</div>
