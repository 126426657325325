import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { FinancialAllowance } from "src/app/models/system/allowances";
import { FinancialAllowancesService } from "src/app/services/system-manager/financial-allowances.service";

@Component({
    selector: "app-financial-allowances",
    templateUrl: "./financial-allowances.component.html",
})
export class FinancialAllowancesComponent implements OnInit, AfterViewInit {
    allowancesCategories: string[] = [
        "ألشهادة",
        "أللقب ألعلمي",
        "ألمنصب",
        "ألصنف ألوظيفي",
    ];

    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    view: string = "list";
    Items: any[] = [];
    SelectedItem: FinancialAllowance = null;
    category: string = "ألشهادة";

    constructor(
        private allowancesService: FinancialAllowancesService,
        private route: ActivatedRoute,
        private router:Router
    ) {}

    ngOnInit(): void {
    }

    async ngAfterViewInit() {
        this.dt.setDtElement(this.dtElement);
        this.findByCategory()
        this.dt.trigger();
    }

    findAll() {
        this.allowancesService.findAll().subscribe((response) => {
            this.Items = response;
            this.dt.reload();
            this.view = "list";
        });
    }
    findByCategory() {
        this.allowancesService
            .findByCategory(this.category)
            .subscribe((response) => {
                this.Items = response;
                this.dt.reload();
                this.view = "list";
            });
    }

    newOrEdit(view: string) {
        if (view == "new") {
            this.SelectedItem = new FinancialAllowance(this.category);
        }
        this.view = view;
    }

    submit() {
        if (this.SelectedItem._id) {
            this.allowancesService
                .update(this.SelectedItem._id, this.SelectedItem)
                .subscribe((response) => {
                    this.category ? this.findByCategory() : this.findAll();
                });
        } else {
            this.allowancesService
                .create(this.SelectedItem)
                .subscribe((response) => {
                    this.findByCategory();
                });
        }
    }
}
