<div class="files">
    <div class="header  border border-secondary mb-2 border-radius-25" *ngIf="header">
        <div class="d-flex d-inline-flex w-100  pr-1">
            <h4  class="mt-1 w-75 d-flex" style="line-height: 40px !important;" >
                <i class="fa fa-file-alt mt-1 "></i>
                <span class="text-secondary">  {{header + ' :'}}</span>
            </h4>
            <div class="d-md-flex  justify-content-between w-25 px-1">
                <button type="button" class="btn float-left float-md-none " (click)="Files.addFile()">
                    <i class="fa fa-cloud-upload-alt  fa-lg btn-upload-download"></i>
                </button>

                <button type="button" class="btn ml-2 d-none d-md-block" (click)="downloadArchive()" [disabled]="uploadedFiles.length==0">
                    <i class="fa fa-download  fa-lg btn-upload-download"></i>
                </button>
            </div>
        </div> 

    </div>
    <div [id]="id">

        <form  #frm="ngForm" (change)="onChange(frm)">
            <div class="row mx-0 my-2">
                <div class="col-md-12">
                    <ol class="px-0">
                        <li class="px-0" *ngFor="let file of Files.Filelist; let i = index">
                            <div class="row my-0 ml-0">
                                <div class="d-flex justify-content-between w-100">
                                    <div class="form-group w-75">
                                        <input type="file" name="img_{{i}}" class="file" (change)="onFileSelected(i,$event)"
                                            #fileInput />
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1"
                                                    [ngClass]="{'border-danger': !Files.isValidFile(file)}"><i
                                                        class="fas fa-paperclip"></i>
                                                </span>
                                            </div>
                                            <input type="text" class="form-control" disabled
                                                [placeholder]="file.name.length?file.name:'تحميل ملف'"
                                                aria-label="Upload File" aria-describedby="basic-addon1"
                                                [ngClass]="{'is-invalid': !Files.isValidFile(file)}" />
                                            <div class="input-group-append ">
                                                <button class="browse input-group-text btn btn-primary border d-none d-md-block"
                                                    (click)="fileInput.click();"
                                                    [ngClass]="{'border-danger bg-danger': !Files.isValidFile(file)}"
                                                    id="basic-addon2">
                                                    <i class="fas fa-folder-open"></i> أختار ألملف
                                                </button>

                                                <button class="input-group-text btn btn-primary small-upload-btn  border d-block d-md-none"
                                                    (click)="fileInput.click();"
                                                    [ngClass]="{'border-danger bg-danger text-white': !Files.isValidFile(file)}"
                                                    id="basic-addon2">
                                                <i class="fas fa-folder-open"></i>
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="close-container">
                                        <button class="btn btn-close float-left" (click)="Files.removeFile(i);">
                                            <i class="fa fa-times fa-md"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
    
            <div class="download-container">
                <div class="row my-2 py-0 font-size-14 mx-0 border" *ngFor="let item of uploadedFiles;let i=index;">
                    <div class="col-md-5 wrap-text  text-secondary border-0 border-md-bottom-0" >
                        <span class="file-id">{{i+1}}.</span> 
                        <span [title]="item.filename">{{item.filename}}</span>
                    </div>
    
                    <div class="col-md-2 d-none d-md-block ">
                        <span> {{getFileSize(item.size)}} <sub class="text-danger">MB</sub></span>
                    </div>
                    <div class="col-md-5 d-flex d-inline-flex justify-content-between">
                        <button class="btn p-0" (click)="preview(item._id)" title="عرض ألملف في ألمتصفح">
                            <i class="fa fa-file-pdf m-0 " *ngIf="item.mimetype=='application/pdf'"></i>
                            <i class="fa fa-file-image m-0" *ngIf="item.mimetype!='application/pdf'"></i>
                        </button>

                        <button class="btn p-0 close-container " (click)="download(item._id,item.filename)" title="تحميل ألملف ألى ألجهاز">
                            <i class="fa fa-download fa-md m-0"></i>
                        </button>
                        <button class="btn btn-close  text-danger " (click)="this.SelectedFile=item;removeFile();" title="حذف ألملف">
                            <i class="fa fa-trash fa-md"></i>
                        </button>

                    </div>
                </div>
            </div>
    
        </form>

    </div>
</div>
