import { Injectable } from "@angular/core";
import { HttpClient} from "@angular/common/http";
import { environment } from "../../../environments/environment"
import { Observable } from "rxjs";
import { HttpLoadingService } from "../shared/http-loading.service"


@Injectable({
    providedIn: "root",
})
export class StructureService {
    private url = environment.ApiUrl + "/organizationstructure/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}
    create(parentId: string, data: any): Observable<any> {
        return this.http.post(this.url + parentId, data);
    }
    findAll(query:any={}): Observable<any> {
        
        return this.http.get(`${this.url}`,{
            params:{
                query:JSON.stringify(query)
            }
        });
    }

    findPartial(id:string): Observable<any> {
        return this.http.get(`${this.url}partial/${id}?cache_bust=${new Date().getTime()}`);
    }
    findOne(id:string):Observable<any>{
        return this.http.get(`${this.url}${id}?cache_bust=${new Date().getTime()}`);
    }


    update(id:string, data:any):Observable<any>{
        return this.http.patch(`${this.url}${id}`,data)
    }

    updateManager(id:string,data:any):Observable<any>{
        return this.http.patch(`${this.url}manager/${id}`,data)
    }

    getEmployeesCount(id:string):Observable<any>{
        return this.http.get(`${this.url}employeescount/${id}?cache_bust=${new Date().getTime()}`);
    }

    remove(documentId: string): Observable<any> {
        return this.http.delete(this.url + documentId);
    }

    removeAll(): Observable<any> {
        return this.http.delete(this.url);
    }

    findStatistics(): Observable<any> {
        return this.http.get(`${this.url}statistics?cache_bust=${new Date().getTime()}`);
    }
}