<div class="document-container">
    <div class="row">
        <div class="col-md-4  ">
            <app-files-ImageFileUploader [image]="item.faceOne"
                (fileChange)="documentHelper.uploadFile(item,item.faceOne,'ألهوية ألجامعية -وجه','faceone')"
                [dimensions]="item.dimensions">
            </app-files-ImageFileUploader>
        </div>
        <div class="col-md-4 offset-md-4 ">
            <app-files-ImageFileUploader [image]="item.faceTwo"
                (fileChange)="documentHelper.uploadFile(item,item.faceTwo,'الهوية ألجامعية -ظهر','facetwo')"
                [dimensions]="item.dimensions">
            </app-files-ImageFileUploader>
        </div>
    </div>