<div class="card" [ngClass]="{'d-none':view!='list'}">
    <div class="card-header">
        <h4><i class="fa fa-child fa-lg px-2"></i> معلومات ألاطفال</h4>
        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
            <button
                type="submit"
                class="btn btn-print"
                title="أضافة طفل"
                (click)="newOrEdit('new')"
            >
                <i class="fa fa-plus-circle fa-lg"></i>
            </button>
        </div>
    </div>
    <div class="card-body px-0">
        <table
            datatable
            [dtOptions]="this.dt.dtOptions"
            [dtTrigger]="this.dt.dtTrigger"
            class="table table-bordered table-striped border-0 my-0 border-bottom-0"
        >
            <thead>
                <tr>
                    <th class="d-none d-md-table-cell">#</th>
                    <th class="">ألأسم ألكامل</th>
                    <th class="d-none d-md-table-cell">تاريخ ألولادة</th>
                    <th class="">ألجنس</th>
                    <th class="text-center d-none d-md-table-cell">
                        ألحالة ألزوجية
                    </th>
                    <th class="text-center">
                        <i class="fa fa-ellipsis-v"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of children;let i=index">
                    <th class="d-none d-md-table-cell">{{i+1}}</th>
                    <td class="pr-3">{{item.name}}</td>
                    <td class="pr-3 d-none d-md-table-cell">
                        {{item.birthDate?.toString()|formatDate}}
                    </td>
                    <td class="">{{item.gender}}</td>
                    <td class="d-none d-md-table-cell">
                        {{item.maritialStatus}}
                    </td>
                    <th class="text-center">
                        <div class="dropdown">
                            <button
                                class="btn"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                            >
                                <i class="fa fa-ellipsis-v text-secondary"></i>
                            </button>
                            <ul
                                class="dropdown-menu bg-creamy"
                                aria-labelledby="dropdownMenuButton"
                            >
                                <li
                                    class="dropdown-item"
                                    (click)="SelectedItem=item;newOrEdit('edit')"
                                >
                                    <i
                                        class="fa fa-edit fa-lg pl-1 text-secondary"
                                    ></i>
                                    تعديل ألمعلومات
                                </li>
                                <li
                                    class="dropdown-item"
                                    (click)="this.SelectedItem=item;this.removeChild();"
                                >
                                    <i
                                        class="fa fa-trash text-danger fa-lg px-1 pl-2"
                                    ></i>
                                    حذف ألطفل
                                </li>
                            </ul>
                        </div>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-child-form
    *ngIf="view!='list'"
    [Item]="this.SelectedItem"
    [InfoId]="utilService.EmployeeFamilyInfo._id"
    (viewChange)="this.getFamilyInfo();"
>
</app-child-form>
