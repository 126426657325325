<label [for]="id" class="form-label d-block">{{label}}
    <span class="badge badge-secondary badge-pill float-left mt-1" *ngIf="leftLabel">{{leftLabel}}</span>
</label>
<div class="input-group">
    <select #mySelect  [id]="id" name="name"  class="selectpicker form-control" data-live-search="true"  (change)="onChange(mySelect.value)" [value] ="selected"   required>
        <option *ngFor="let item of values;let i=index;" [selected]="item==selected"  [value]="item"  >
            {{item}}
        </option>
    </select>  
    <div class="input-group-prepend" *ngIf="inputGroupText">
        <span class="input-group-text px-1">
            <i [ngClass]="inputGroupText.class">{{inputGroupText.text}}</i>
        </span>

    </div>

</div>
