<form
    #frm="ngForm"
    class="needs-validation"
    (ngSubmit)="employmentUtileService.submit(frm)"
>
    <div class="card">
        <div class="card-header">
            <h4 class="text-secondary">
                <i class="fa fa-file-alt"></i> ألملاحظات ألخاصة بالموظف
            </h4>
            <div
                class="toolbar d-flex d-inline-flex w-30 justify-content-between"
            >
                <button
                    type="submit"
                    class="btn btn-print"
                    title="حفظ ألمعلومات "
                >
                    <i class="fa fa-save fa-lg"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0 m-0">
            <app-editor
                [(input)]="employmentUtileService.EmploymentInfo.notes"
            ></app-editor>
        </div>
    </div>
</form>
<div
    class="spinner text-secondary bg-creamy"
    *ngIf="employmentUtileService.isLoading"
>
    جارٍ الحفظ
</div>
