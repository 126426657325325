import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../environments/environment";
import { HttpLoadingService } from "../shared/http-loading.service";

export type BasicInfo = {
    _id: string;
    aFirstName: string;
    aFatherName: string;
    aGrandFatherName: string;
    aFourthName: string;
    aSureName: string;
    eFirstName: string;
    eFatherName: string;
    eGrandFatherName: string;
    eFourthName: string;
    eSureName: string;
    motherName: string;
    religion: string;
    gender: string;
    mobile: string;
    email: string;
    birthDate: string;
    nationalisim: string;
    address: {
        province: string;
        county: string;
        city: string;
        quarter: string;
        nearestPlace: string;
        street: string;
        subStreet: string;
        unitNumber: string;
    };
    userId: string;
    createdAt: string;
    updatedAt: string;
};

@Injectable({
    providedIn: "root",
})
export class ProfileService {
    private url = environment.ApiUrl + "/basicinfo/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    findOne(id: string) {
        return this.http.get<BasicInfo | null>(this.url + id);
    }
}
