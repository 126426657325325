import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";

import { ModalModule, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrModule } from "ngx-toastr";
import { MaterialModule } from "./material";
import { HrModule } from "./hr/hr.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { AppInterceptorService } from "./services/shared/app-interceptor.service";
import { HomeComponent } from "./home/home.component";
import { TopheaderComponent } from "./home/headers/topheader/topheader.component";
import { FooterComponent } from "./home/headers/footer/footer.component";
import { LoginComponent } from "./home/account/login/login.component";
import { PageNotFoundComponent } from "./home/page-not-found/page-not-found.component";
import { HrAdminComponent } from "./hr/hr-admin/hr-admin/hr-admin.component";
import { EditorModule } from "@tinymce/tinymce-angular";

import { SystemManagerModule } from "./system-manager/system-manager.module";
import { WelcomePageComponent } from "./home/welcome-page/welcome-page.component";
import { ProfileComponent } from "./home/profile/profile.component";
import { SharedComponentsModule } from "./shared-components/shared-components.module";
import { TestComponent } from "./home/test/test.component";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        TopheaderComponent,
        FooterComponent,
        LoginComponent,
        PageNotFoundComponent,
        HrAdminComponent,
        WelcomePageComponent,
        ProfileComponent,
        TestComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialModule,
        ModalModule.forRoot(),

        HrModule,
        SystemManagerModule,
        SharedComponentsModule,
        EditorModule,
        AppRoutingModule,

        ToastrModule.forRoot(),
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInterceptorService,
            multi: true,
        },
        BsModalRef,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}
