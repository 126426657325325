import {
    AfterViewInit,
    Component,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import { UsersService } from "src/app/services/users/users.service";
import { DatatableUtil } from "../../../classes/datatable";
import { DataTableDirective } from "angular-datatables";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { StructureDialogComponent } from "src/app/shared-components/dialogs/dialogs";
import { HrDataService } from "../hr-role-workplace-data.service";
import { StructureService } from "src/app/services/system-manager/structure-http-service";
import { FilesService } from "src/app/services/files/file.service";
import * as saveAs from "file-saver";

declare var $: any;
@Component({
    selector: "app-employees",
    templateUrl: "./employees.component.html",
})
export class EmployeesComponent implements OnInit, AfterViewInit {
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    SelectedUser: any = null;
    view: string = "list";
    treeData: any[] = [];
    header:string="";
    
    constructor(
        private userService: UsersService,
        public router: Router,
        public dialog: MatDialog,
        public hrDataService: HrDataService,
        private structureService: StructureService,
        private fileService: FilesService
    ) {}

    ngOnInit(): void {
        this.hrDataService.setHeaderTitle();
    }
    async ngAfterViewInit() {
        this.dt.setDtElement(this.dtElement);
        this.dt.trigger();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(StructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: this.hrDataService.workplaceService.getMainPath(
                    this.hrDataService.hrRole.workplaceId
                ),
                id: this.hrDataService.hrRole.workplaceId._id,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getWorkplaceEmployees(result._id);
                this.getWorkplace(result._id);
            }
        });
    }

    getWorkplaceEmployees(selectedworkplaceId: string) {
        this.userService
            .findWorkPlaceEmployees(selectedworkplaceId)
            .subscribe((response) => {
                this.hrDataService.users = response;
                this.dt.reload();
                this.view = "list";
            });
    }

    getWorkplace(selectedworkplaceId: string) {
        this.structureService
            .findOne(selectedworkplaceId)
            .subscribe((response) => {
                this.hrDataService.selectedWorkplace = response;
                this.hrDataService.selectedWorkplacePath =
                    this.hrDataService.workplaceService.getFullPath(response);
                this.hrDataService.getWorkplaceTree();
                this.header=this.hrDataService.selectedWorkplace.name;
            });
            
    }



    newOrEdit(view: string) {
        this.view = view;
    }

    downloadEmployeeFiles() {
        try {
            const filename = this.SelectedUser.fullname + ".zip";
            this.fileService
                .downloadAllEmployeeFiles(this.SelectedUser._id)
                .subscribe(
                    (blob: Blob) => {
                        saveAs(blob, filename);
                    },
                    (error: any) => {
                        console.error(error);
                        // Show an error message to the user
                        alert("Error downloading files: " + error.message);
                    }
                );
        } catch (error) {
            console.error(error);
        }
    }
}
