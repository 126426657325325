import {
    AfterViewInit,
    Component,
    OnInit,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
} from "@angular/core";
import { DateUtil } from "src/app/classes/Utils";
import {
    MotherhoodVacation,
    NormalLongVacation,
} from "src/app/models/hr/employee/vacations";

import { DatatableUtil } from "../../../../classes/datatable";
import { DataTableDirective } from "angular-datatables";
import { VacationUtilService } from "../vacation-util.service";
import { ActivatedRoute } from "@angular/router";
import { VacationsService } from "src/app/services/employee/vacations/vacations.service";
import { MotherhoodVacationsService } from "src/app/services/employee/vacations/motherhood.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "src/app/shared-components/dialogs/dialogs";

@Component({
    selector: "vacation-motherhood",
    templateUrl: "./motherhood.vacation.component.html",
})
export class MotherhoodVacationsComponent implements OnInit, AfterViewInit {
    public userId: string;
    type: string = "أمومه";
    view: string = "list";
    title: string = "أجازات ألامومه";
    SelectedItem: MotherhoodVacation=new MotherhoodVacation();
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    items: any[] = [];
    totalDuration: string = "";
    totalDurationDays = 0;
    isFilter = false;
    constructor(
        private Activeroute: ActivatedRoute,
        public vacationUtilService: VacationUtilService,
        private motherhoodVacationService: MotherhoodVacationsService,
        public vacationHttpService: VacationsService,
        private dialog:MatDialog
    ) {}

    ngOnInit(): void {
        this.vacationUtilService.getSubmissionEvent().subscribe(() => {
            this.findAll();
        });
    }

    async ngAfterViewInit() {
        this.userId = await this.Activeroute.parent.parent.snapshot.params[
            "userId"
        ];
        this.dt.setDtElement(this.dtElement);
        this.findAll();
        this.dt.trigger();
    }

    findAll() {
        if (this.isFilter) {
            this.filter();
            return;
        }
        this.motherhoodVacationService
            .findAll(this.userId, this.type)
            .subscribe((response) => {
                this.items = response;
                this.dt.reload();
                this.findTotalDurations();
                this.view = "list";
            });
    }

    onFilterChange(event: boolean) {
        this.isFilter = event;
        this.findAll();
    }

    filter() {
        this.motherhoodVacationService
            .filter(
                this.userId,
                this.type,
                this.vacationUtilService.filterDates
            )
            .subscribe((response) => {
                this.items = response;
                this.dt.reload();
                this.findTotalDurations();
                this.view = "list";
            });
    }

    formatDate(date: string) {
        return DateUtil.formatDate(date);
    }

    findDuration(item: any) {
        return DateUtil.durationYMD(item.fromDate, item.toDate);
    }

    findTotalDurations() {
        this.totalDurationDays = 0;
        this.items.forEach((item: any) => {
            this.totalDurationDays += item.duration;
        });
        this.totalDuration = DateUtil.formatTotalDuration(
            this.totalDurationDays
        );
    }

    newOrEdit(view: string) {
        var stages=Array.from(this.SelectedItem.stages)
        if(view=='edit'){
            this.SelectedItem = Object.assign(new MotherhoodVacation(), this.SelectedItem);
            this.SelectedItem.expectedBirthDate = new Date(this.SelectedItem.expectedBirthDate);
            this.SelectedItem.realBirthDate = new Date(this.SelectedItem.realBirthDate);
            var index=0;
            this.SelectedItem.stages.forEach((stage:NormalLongVacation) => {
                stages[index]=Object.assign(new NormalLongVacation("أمومه"), stage);
                stages[index].fromDate=new Date(stage.fromDate)
                stages[index].toDate=new Date(stage.toDate);
                stages[index].BooknumberDate=new Date(stage.BooknumberDate);
                stages[index].fromBooknumberDate=new Date(stage.fromBooknumberDate);
                stages[index].toBooknumberDate=new Date(stage.toBooknumberDate);
                index++;
            });
            this.SelectedItem.stages=stages;
        }
        this.view = view;
    }

    delet() {

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title:`حذف  أجازة ألأمومه`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات ألأجازة بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage:"هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo:{yes:'نعم',no:'كلا'}
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if(result){
                this.motherhoodVacationService
                .remove(this.SelectedItem._id)
                .subscribe(() => {
                    this.findAll();
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.vacationUtilService.getSubmissionEvent().subscribe();
        this.dt.unsubscribe();
    }
}

@Component({
    selector: "vacation-motherhood-form",
    templateUrl: "./motherhood.vacation.form.html",
})
export class MotherhoodVacationFormComponent implements OnInit {
    @Input() userId: any;
    @Input() Item: MotherhoodVacation = new MotherhoodVacation();
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    SelectedStage: any = null;
    SelectedIndex:number=0;

    stages: string[] = [
        "ما قبل ألوضع (21 يوم)",
        "ما بعد ألوضع (50 يوم)",
        " ستة أشهر ألاولى (راتب كامل)",
        " ستة أشهر ألثانية (راتب أسمي)",
    ];
    Util: DateUtil = new DateUtil();
    totalDuration: string = "";

    stagesDurations:string[]=["","","",""];

    constructor(
        private motherhoodHttpVacationService: MotherhoodVacationsService,
        private vacationHttpService: VacationsService,
        public vacationUtilService: VacationUtilService,
        private cdRef: ChangeDetectorRef,
        private dialog:MatDialog
    ) {}
    ngOnInit(): void {
    }

    findDuration(stage:any) {
        stage.duration=DateUtil.dateDifInDays(stage.fromDate,stage.toDate);
    }

    submit() {
        if (this.Item._id) {
            this.motherhoodHttpVacationService
                .update(this.Item._id, this.Item.toFormData())
                .subscribe((response) => {
                    this.submitStages();
                });
        } else {
            this.motherhoodHttpVacationService
                .create(this.userId, this.Item.toFormData())
                .subscribe((response) => {
                    this.Item._id = response._id;
                    this.submitStages();
                });
        }
    }

    submitStages() {
        if(this.Item.stages.length==0){
            this.viewChange.emit("list");
            return;
        }
        var requests = this.Item.stages.length;
        this.Item.stages.forEach((stage) => {
            if (stage._id) {
                this.vacationHttpService
                    .update(stage._id, stage.toFormData())
                    .subscribe((response) => {
                        requests--;
                        if (requests <= 0) {
                            this.viewChange.emit("list");
                        }
                    });
            } else {
                this.motherhoodHttpVacationService
                    .createStage(this.Item._id, stage.toFormData())
                    .subscribe((response) => {
                        requests--;
                        if (requests <= 0) {
                            this.viewChange.emit();
                        }
                    });
            }
        });
    }

    onDaysChange(stage:any){
        stage.toDate= new Date(stage.fromDate.getTime() + stage.duration * 24 * 60 * 60 * 1000); 

        this.cdRef.detectChanges();      
    }
    

    delet() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title:`حذف مرحلة أجازة ألأمومه`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات ألأجازة بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage:"هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo:{yes:'نعم',no:'كلا'}
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if(this.SelectedStage._id){
                    this.motherhoodHttpVacationService.removeStage(this.Item._id,this.SelectedStage._id).subscribe((response)=>{
                        this.Item.stages.splice(this.SelectedIndex,1);
                    })
                }else{
                    this.Item.stages.splice(this.SelectedIndex,1);
                }
            }
        });
    }

    getDuarationStr(stage:any){
        return DateUtil.durationYMD(stage.fromDate,stage.toDate)
    }

    removeStageFile(stageId: string, fileId: string) {
        this.vacationHttpService.removeFile(stageId,{fileId}).subscribe();
    }
}
