import { Injectable } from "@angular/core";
import {
    NationalId,
    RationCard,
    AddressCard,
    CivilId,
    UniversityId,
    Passport,
    CertificateOfNationality,
} from "../../../models/hr/employee/officialdocuments";
import { OfficalDocumentsService } from "src/app/services/employee/offical-documents.service";
import { ImageFile } from "src/app/classes/Files";
import { FilesService } from "src/app/services/files/file.service";
@Injectable({
    providedIn: "root",
})
export class OfficialDocumentsHelper {


    documentTypes = [
        { type: 'ألبطاقة ألوطنية', max: 1 },
        { type: 'هوية ألأحوال ألمدنية', max: 1 },
        { type: 'بطاقة ألسكن', max: 1 },
        { type: 'ألجنسية ألعراقية',max: 1},
        { type: 'ألبطاقة ألتموينية', max: 1 },
        { type: 'جواز ألسفر', max: 1 },
        { type: 'ألهوية ألجامعية',  max: 1 },
    ];

    constructor(
        private documentService: OfficalDocumentsService,
        public fileService: FilesService
    ) {}

    public jsonToDocumentObject(document: any) {
        var documentObject:any=null;
        switch (document.type) {
            case 'ألبطاقة ألوطنية': {
                documentObject=Object.assign(new NationalId(document.type), document);
                break;
            }
            case 'هوية ألأحوال ألمدنية': {
                documentObject=Object.assign(new CivilId(document.type), document);
                break;
            }

            case 'بطاقة ألسكن': {
                documentObject=Object.assign(new AddressCard(document.type), document);
                break;
            }

            case 'ألجنسية ألعراقية': {
                documentObject=Object.assign(new CertificateOfNationality(document.type),document)
                break;
            }

            case 'ألبطاقة ألتموينية': {
                documentObject=Object.assign(new RationCard(document.type), document);
                break;
            }

            case 'جواز ألسفر': {
                documentObject=Object.assign(new Passport(document.type), document)
                break;
            }

            case 'ألهوية ألجامعية': {
                documentObject=Object.assign(new UniversityId(document.type), document)
                break;
            }
        }
        return documentObject;
    }

    createDocumentObject(type: any) {
        var document:any=null;
        switch (type) {
            case 'ألبطاقة ألوطنية': {
                document=new NationalId(type);
                break;
            }
            case 'هوية ألأحوال ألمدنية': {
                document=new CivilId(type);
                break;
            }
            case 'بطاقة ألسكن': {
                document=new AddressCard(type);
                break;
            }
            case 'ألجنسية ألعراقية': {
                document=new CertificateOfNationality(type);
                break;
            }
            case 'ألبطاقة ألتموينية': {
                document=new RationCard(type);
                break;
            }
            case 'جواز ألسفر': {
                document=new Passport(type);
                break;
            }

            case 'ألهوية ألجامعية': {
                document=new UniversityId(type);
                break;
            }
        }
        return document;
    }




/*
    toDto() {
        var documents = JSON.parse(JSON.stringify(this.Documents.documents));
        documents.forEach((doc: any) => {
            delete doc.faceOne;
            delete doc.faceTwo;
            delete doc.dimensions;
            delete doc.isValid;
            doc.isNew = false;
        });
        return documents;
    }*/

    uploadFile(
        item: any,
        uploadedFile: ImageFile,
        fieldName: string,
        replacedFileField: any
    ) {
      /*  if (item[replacedFileField] != null) {
            this.fileService
                .delete(item[replacedFileField])
                .subscribe((response) => {});
        }
        var data = new FormData();
        var userId:string;
    

        data.append(
            "image",
            uploadedFile.file,
            encodeURIComponent(uploadedFile.file.name)
        );
        data.append("filename", fieldName);
        this.documentService
            .uploadFile(userId, data)
            .subscribe((response) => {
                item[replacedFileField] = response._id;
                console.log(item);
            });*/
    }
    
}
