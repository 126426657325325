<div class="container   py-10">
    <div class="row justify-content-center ">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header bg-creamy text-secondary">
                    <h5>  مرحبا بك {{ authService.getFullname()}}</h5>
                </div>
                <div class="card-body px-0 bg-creamy" style="font-size: 14px;">
                    <div class="row px-3">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block"> ألأسم ألكامل  <span class="float-left font-weight-bold">:</span> </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block"> {{authService.getFullname()}}  </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">  ألجهة ألمنتسب أليها  <span class="float-left font-weight-bold">:</span> </label>
                                </div>
                                <div class="col-md-7">
                                    <ul class="list-unstyled pt-2">
                                        <li  *ngFor="let item of workplaceList;"> <label class="pl-2 d-block"> {{item}}  </label></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block"> ألرقم ألوظيفي <span class="float-left font-weight-bold">:</span> </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block"> 8758732098  </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <app-files-ImageFileUploader  (fileChange)="basicInfoService.uploadPhoto()" [image]="basicInfoService.profileImage" [dimensions]="{width:'400px !important',height:'260px !important'}"></app-files-ImageFileUploader>
                        </div>
                    </div>
                    <div class="steps mt-8">
                        <div class="row header-info mx-0">
                            <span class="title bg-transparent" ><i class="fa fa-user-tag text-secondary pl-2" ></i>ألصلاحيات</span>
                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="authService.getRoles().length>0">
                        <div class="col-md-12">
                            <ol class="px-0 ">
                                <li *ngFor="let role of this.authService.getRoles();let i=index" class=" px-4 py-3 " style="font-size: 14px;"
                                    [ngClass]="{'border-bottom border-width-2':i<this.authService.getRoles().length-1}">
                                    <div class="row mb-2">
                                        <div class="col-md-3">
                                            <label class="control-label d-block"> ألقسم <span class="float-left">:</span></label>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="control-label"> {{role.roleId.category}} </label>
                                        </div>
                                        <div class="col-md-2">
                                            <label class="control-label d-block"> ألصلاحية <span class="float-left">:</span></label>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="control-label"> {{role.roleId.name}} </label>
        
                                        </div>
        
                                        <div class="col-md-1 text-center">
                                            <button class="btn p-0   text-secondary hover-danger"  (click)="navigateTo(role.roleId.link)"
                                                 title="ذهاب ألى ألرابط ">
                                                <i class="fa fa-link fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-3">
                                            <label class="control-label d-block mt-1"> ألجهة ألمسؤول عنها <span
                                                    class="float-left">:</span> </label>
                                        </div>
                                        <div class="col-md-9">
        
                                            <div class="input-group ">
                                                <input type="text" class="form-control px-1 bg-creamy " style="font-size: 14px;"
                                                    [value]="workplaceUtilService.getFullPath(role.workplaceId)" disabled
                                                    name="workplace_{{i}}" id="workplace_{{i}}" placeholder="">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" ><i
                                                            class="fa fa-university fa-lg"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="row mb-1">
                                        <div class="col-md-3">
                                            <label class="control-label d-block"> مستوى ألصلاحية <span
                                                    class="float-left">:</span> </label>
                                        </div>
                                        <div class="col-md-9 pt-1 d-flex ">
        
                                            <div *ngFor="let level of roleLevels;" >
                                                <label *ngIf="isChecked(role.roleId,level)" class="pl-10">
                                                    <i class="fas fa-check-square  checked-item pl-3"></i> {{level}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="row px-4 mt-5"  *ngIf="authService.getRoles().length==0">
                        <div class="col-md-12">
                            <app-no-data-message [message]="noDataMessage"></app-no-data-message>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
