<app-datse-filter [filterDates]="vacationUtilService.filterDates" [header]="'فرز ألاجازات حسب ألفترة'"
    (filterChange)="onFilterChange($event)"></app-datse-filter>

<div class="card mt-6">
    <div class="card-header">
        <h4><i class=" fa fa-calendar-alt"></i> خلاصة ألاجازات</h4>

        <div class="icheck-greensea ml-3 my-2">
            <input class="box" type="checkbox" name="isCurrentDate" [(ngModel)]="vacationUtilService.isCurrentDate" id="isCurrentDate" (change)="this.findAll()">
            <label for="isCurrentDate"> فرز الى تاريخ أليوم ؟</label>
        </div>
    </div>
    <div class="card-body p-0">

        <table id="summary" class="table table-bordered table-striped border-0 my-0">
            <thead>
                <tr>
                    <th class="d-none d-md-table-cell">#</th>
                    <th class="">ألاجازه</th>
                    <th class="d-none d-md-table-cell">ألمده </th>
                    <th class=""> ألفتره ألكليه</th>
                    <th class="text-center">عدد ألاجازات</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of allVacations;let i=index;">
                    <th class="d-none d-md-table-cell">{{(i+1).toString()|translateDigits:'ar'}}</th>
                    <th class="pr-3">{{item.title}}</th>
                    <td class="pr-3 pl-2 pl-md-5 d-none d-md-table-cell ">{{item.duration |translateDigits:'ar'}} <span class="float-left">{{item.unit}}</span></td>
                    <td class="pr-3">{{item.formatedDuration |translateDigits:'ar'}}</td>
                    <th class="pr-3 text-center ">{{(item.count.toString()) |translateDigits:'ar'}}</th>
                </tr>
            </tbody>
        </table>


        <div class="row mt-5 mb-2">
            <div class="col-md-12">
                <div class="component-info mt-3 ">
                    <span class="title text-dark"> خلاصة  ألأجازات</span>

                    <ul class="list-square mt-4 pr-4  text-dark">
                        <li class="mb-1 ml-4 border-bottom pr-2" [ngClass]="{'border-bottom-0':i==VacationSummaryRecords.length-1}"  *ngFor="let item of VacationSummaryRecords;let i=index;">
                            <div class="row my-0 ">
                                <div class="col-md-4 attribute">
                                    <i class="material-icons list-icon-secondary">star</i>
                                    <span class="float-left font-bold pr-2">:</span>
                                    <span class="float-left">{{item.title}}</span> 
                                    
                                </div>
                                <div class="col-md-8" >
                                    <div class="row my-0 pl-3  py-0" *ngFor="let record of item.records">
                                        <div class="col-md-7 py-0  attribute px-1">
                                            <i class="material-icons list-icon-secondary">star</i>
                                            <span class="pr-2">{{record.title}}</span>
                                        </div>
                                        <div class="col-md-5 py-0  attribute px-2">
                                            <i class="fa fa-calendar-alt list-icon-secondar"></i>
                                            <span class="badge badge-pill badge-danger float-left  mt-2  w-75 text-center">{{record.formatedDuration |translateDigits:'ar'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="row mt-1">
            <div class="col-md-12">
                <div class="component-info mt-3 ">
                    <span class="title text-dark"> رصيد  ألأجازات</span>

                    <ul class="list-square mt-4 pr-4  text-dark">
                        <li class="mb-1 ml-4 border-bottom pr-2" [ngClass]="{'border-bottom-0':i==CreditSummaryRecords.length-1}"  *ngFor="let item of CreditSummaryRecords;let i=index;">
                            <div class="row my-0 ">
                                <div class="col-md-4 attribute">
                                    <i class="material-icons list-icon-secondary">star</i>
                                    <span class="float-left font-bold pr-2">:</span>
                                    <span class="float-left">{{item.title}}</span> 
                                </div>
                                <div class="col-md-8" >
                                    <div class="row my-0 pl-3  py-0" *ngFor="let record of item.records">
                                        <div class="col-md-7 py-0  attribute px-1">
                                            <i class="material-icons list-icon-secondary">star</i>
                                            <span class="pr-2">{{record.title}}</span>
                                        </div>
                                        <div class="col-md-5 py-0  attribute px-2">
                                            <i class="fa fa-calendar-alt list-icon-secondar"></i>
                                            <span class="badge badge-pill badge-danger float-left  mt-2  w-75 text-center">{{record.formatedDuration|translateDigits:'ar'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>