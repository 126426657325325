import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ItemNode } from "src/app/models/system/structure";
import { StructureService } from "../../services/system-manager/structure-http-service";
import * as saveAs from "file-saver";


export interface Entity{
    type:string,
    title:string,
    count:number,
    children:Array<{name:string,title:string}>
}

const EntitiesTitles:Array<Entity>=[
    {
        type:'وزارة',
        title:'أسم ألوزارة',
        count:0,
        children:[
            {
                name:'ديوان وزارة',
                title:'ديوان ألوزارة'
            },
            {
                name:'جامعة حكومية',
                title:'أسم ألجامعة'
            },
            {
                name:'جامعة أهلية',
                title:'أسم ألجامعة'
            }
        ]
    },
    {
        type:'ديوان وزارة',
        title:'ديوان ألوزارة',
        count:0,
        children:[
            {
                name:'وكيل وزير',
                title:'وكيل ألوزير'
            },
            {
                name:'جهاز',
                title:'أسم ألجهاز'
            },
            {
                name:'دائرة',
                title:'أسم ألدائرة'
            },
            {
                name:'قسم أداري',
                title:'أسم ألقسم ألأداري'
            }
        ]
    },
    {
        type:'وكيل وزير',
        title:'وكيل ألوزير',
        count:0,
        children:[
            {
                name:'دائرة',
                title:'أسم ألدائرة'
            },
            {
                name:'قسم أداري',
                title:'أسم ألقسم ألأداري'
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'دائرة',
        title:'أسم ألدائرة',
        count:0,
        children:[
            {
                name:'قسم أداري',
                title:'أسم ألقسم ألأداري'
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'جامعة حكومية',
        title:'أسم ألجامعة',
        count:0,
        children:[
            {
                name:'رئاسة ألجامعة',
                title:'رئاسة ألجامعة'
            },
            {
                name:'كلية',
                title:'أسم ألكلية'
            },
            {
                name:'مركز بحثي',
                title:'أسم ألمركز ألبحثي'
            },
            {
                name:'مركز خدمي',
                title:'أسم ألمركز ألخدمي'
            },
        ]
    },

    {
        type:'جامعة أهلية',
        title:'أسم ألجامعة',
        count:0,
        children:[
            {
                name:'كلية أهلية',
                title:'أسم ألكلية'
            },
            {
                name:'قسم علمي',
                title:'أسم ألقسم ألعلمي'
            }
        ]
    },
    {
        type:'رئاسة ألجامعة',
        title:'رئاسة ألجامعة',
        count:0,
        children:[
            {
                name:'مساعد رئيس جامعة',
                title:'مساعد رئيس جامعة'
            },
            {
                name:'قسم أداري',
                title:'أسم ألقسم ألأداري'
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'مساعد رئيس جامعة',
        title:'مساعد رئيس جامعة',
        count:0,
        children:[
            {
                name:'قسم أداري',
                title:'أسم ألقسم ألأداري'
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },

    {
        type:'مساعد رئيس جامعة',
        title:'مساعد رئيس جامعة',
        count:0,
        children:[
            {
                name:'قسم أداري',
                title:'أسم ألقسم ألأداري'
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'كلية',
        title:'أسم ألكلية',
        count:0,
        children:[
            {
                name:'معاون عميد',
                title:'معاون ألعميد'
            },
            {
                name:'قسم علمي',
                title:'أسم ألقسم ألعلمي'
            },
            {
                name:'فرع علمي',
                title:'أسم ألفرع ألعلمي'
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },

    {
        type:'كلية أهلية',
        title:'أسم ألكلية',
        count:0,
        children:[
            {
                name:'معاون عميد',
                title:'معاون ألعميد'
            },
            {
                name:'قسم علمي',
                title:'أسم ألقسم ألعلمي'
            },
            {
                name:'فرع علمي',
                title:'أسم ألفرع ألعلمي'
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'معاون عميد',
        title:'معاون ألعميد',
        count:0,
        children:[
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'معاون مدير',
        title:'معاون مدير',
        count:0,
        children:[
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'مركز بحثي',
        title:'أسم ألمركز ألبحثي',
        count:0,
        children:[
            {
                name:'قسم علمي',
                title:'أسم ألقسم ألعلمي'
            },
            {
                name:'فرع علمي',
                title:'أسم ألفرع ألعلمي'
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'مركز خدمي',
        title:'أسم ألمركز ألخدمي',
        count:0,
        children:[
            {
                name:'معاون مدير',
                title:'معاون مدير',
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'قسم أداري',
        title:'أسم ألقسم',
        count:0,
        children:[
            {
                name:'معاون مدير',
                title:'معاون مدير',
            },
            {
                name:'شعبة',
                title:'أسم ألشعبة'
            },
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'قسم علمي',
        title:'أسم ألقسم ألعلمي',
        count:0,
        children:[
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },

    {
        type:'فرع علمي',
        title:'أسم ألفرع ألعلمي',
        count:0,
        children:[
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'شعبة',
        title:'أسم ألشعبة',
        count:0,
        children:[
            {
                name:'وحدة',
                title:'أسم ألوحدة',
            }
        ]
    },
    {
        type:'وحدة',
        title:'أسم ألوحدة',
        count:0,
        children:[{
            name:'',
            title:''
        }]
    },
]



export const EntitiesList:ItemNode[]=[
    new ItemNode('','وزارة',[
        new ItemNode('','ديوان وزارة'),
    ]),
    new ItemNode('','ديوان وزارة'),
    new ItemNode('','جامعة حكومية'),
    new ItemNode('','جامعة أهلية'),
    new ItemNode('','كلية أهلية'),
    new ItemNode('','وكيل وزير'),
    new ItemNode('','دائرة'),
    new ItemNode('','جهاز'),
    new ItemNode('','رئاسة ألجامعة',[
        new ItemNode('مساعد رئيس ألجامعة للشؤون ألعلميه','مساعد رئيس جامعة',[
            new ItemNode('قسم ألدراسات و ألتخطيط','قسم أداري',[
                new ItemNode('شعبة ألاحصاء','شعبة'),
                new ItemNode('شعبة ألتخطيط','شعبة'),
                new ItemNode('شعبة ألبيانات','شعبة')
            ]),
            new ItemNode('قسم ألبعثات و ألعلاقات ألثقافيه','قسم أداري',[
                new ItemNode('شعبة ألدارسين في ألخارج','شعبة'),
                new ItemNode('شعبة ألعلاقات ألثقافية','شعبة'),
                new ItemNode('وحدة ألركن ألأمريكي','وحدة'),
                new ItemNode('وحدة ألركن ألفرانكفوني','وحدة'),
            ]),
            new ItemNode('قسم ألشؤوون ألعلمية','قسم أداري',[
                new ItemNode('شعبة ألبحوث ألعلمية','شعبة'),
                new ItemNode('شعبة تسويق ألنتاجات ألعلمية','شعبة'),
                new ItemNode('وحدة ألأرشاد ألتربوي','وحدة'),
                new ItemNode('وحدة ألمشاريع ألرياديه','وحدة'),
            ]),
            new ItemNode('قسم شؤوون ألطلبة وألتسجيل','قسم أداري',[
                new ItemNode('معاون مدير قسم شؤون ألطلبة وألتسجيل','شعبة'),
                new ItemNode('شعبة متابعة شؤون ألطلبة','شعبة'),
                new ItemNode('شعبة ألخريجين وألتصديقات','شعبة'),
                new ItemNode('شعبة تدقيق ألوثائق','شعبة'),
                new ItemNode('شعبة ألتسجيل وألقبول','شعبة'),
            ]),
            new ItemNode('قسم شؤون ألدراسات ألعليا','قسم أداري',[
                new ItemNode('شعبة ألقبول','شعبة'),
                new ItemNode('شعبة متابعة شؤون ألطلبة','شعبة'),
                new ItemNode('شعبة ألأوامر ألجامعية','شعبة'),
            ]),
            new ItemNode('شعبة ألسيطره على تناول ألمواد ألكيمياوية وألبيولوجية ','شعبة',[
                new ItemNode('وحدة ألأنشطة ألكيمياوية','وحدة'),
                new ItemNode('وحدة ألمخازن ألكيمياوية','وحدة'),
                new ItemNode('وحدة ألأستيراد','وحدة'),
                new ItemNode('وحدة معالجة ألنفايات','وحدة'),
                new ItemNode('وحدة ألسيطرة وألتفتيش على ألمخازن','وحدة')
            ]),
            new ItemNode('شعبة ألتأهيل وألتوظيف وألمتابعة','شعبة'),
            new ItemNode('وحدة متحف ألجامعة','وحدة'),
            new ItemNode('وحدة ألدراسات ألأشورية','وحدة')
        ]),
        new ItemNode('مساعد رئيس ألجامعة للشئون ألأدارية','مساعد رئيس جامعة',[
            new ItemNode('قسم ألشؤون ألادارية ألمالية','قسم أداري',[
                new ItemNode('معاون مدير قسم ألشؤون ألادارية ألمالية','معاون مدير'),
                new ItemNode('شعبة ألرواتب','شعبة'),
                new ItemNode('شعبة صندوق ألتعليم ألعالي','شعبة'),
                new ItemNode('شعبة ألموازنة وألتخطيط ألمالي','شعبة'),
                new ItemNode('شعبة حسابات ألخطة ألاستثمارية','شعبة'),
                new ItemNode('شعبة ألمخازن وألتجهيزات','شعبة'),
                new ItemNode('شعبة ألحسابات وألصرف','شعبة'),
                new ItemNode('شعبة ألسجلات','شعبة'),
                new ItemNode('شعبة شؤون ألموظفين','شعبة'),
                new ItemNode('شعبة شؤون ألتدريسيين','شعبة'),
                new ItemNode('شعبة ألتوظيف وألملاك','شعبة'),
                new ItemNode('شعبة ألتقاعد','شعبة'),
                new ItemNode('وحدة ألسيطرة ألمخزنية','وحدة'),
                new ItemNode('وحدة ألبطاقة ألذكية','وحدة')
            ]),
            new ItemNode('قسم ألشؤون ألقانونية','قسم أداري',[
                new ItemNode('معاون مدير قسم ألشؤون ألقانونية','معاون مدير'),
                new ItemNode('شعبة ألحقوق وألدعاوى','شعبة'),
                new ItemNode('شعبة ألأملاك وألسكن ألجامعي','شعبة'),
                new ItemNode('شعبة ألأستشارات ألقانونية','شعبة'),
                new ItemNode('شعبة ألعقود وألكفالات','شعبة'),
            ]),
            new ItemNode('قسم شؤون ألديوان','قسم أداري',[
                new ItemNode('معاون مدير قسم شؤون ألديوان','معاون مدير'),
                new ItemNode('شعبة ألأليات وألنقل','شعبة'),
                new ItemNode('شعبة ألصيانة وألخدمات','شعبة',[
                    new ItemNode('وحدة ألكهرباء','وحدة'),
                    new ItemNode('وحدة ألأسالة','وحدة'),
                    new ItemNode('وحدة ألخدمات','وحدة')
                ]),
                new ItemNode('ألشعبة ألزراعية','شعبة'),
                new ItemNode('شعبة ألورش ألفنية','شعبة',[
                    new ItemNode('وحدة ألتبريد وألتكييف','وحدة'),
                    new ItemNode('وحدة ألميكانيك وألحدادة','وحدة'),
                    new ItemNode('وحدة ألنجارة','وحدة'),
                    new ItemNode('وحدة تصليح ألأجهزة ألكهربائية و ألألكترونية','وحدة'),
                    new ItemNode('وحدة تصليح ألأليات وألسيارات و ألمولدات','وحدة')
                ]),
                new ItemNode('شعبة ألأتصالات','شعبة'),
                new ItemNode('وحدة ألمنتدى ألعلمي وألأدبي','وحدة'),
                new ItemNode('وحدة ألهزيات وألبصمة ألألكترونية','وحدة'),
                new ItemNode('وحدة ضيانة خدمات ألمجمع ألثاني','وحدة'),
                new ItemNode('وحدة ألكامرات','وحدة'),

            ]),
            new ItemNode('قسم ألنشاطات ألطلابية','قسم أداري',[
                new ItemNode('شعبة ألنشاطات ألفنية','شعبة'),
                new ItemNode('شعبة النشاطات ألرياضية','شعبة'),
                new ItemNode('شعبة ألنشاطات ألكشفية','شعبة'),
                new ItemNode('وحدة ألمخزن','وحدة'),
                new ItemNode('وحدة ألحاسوب وألانترنت','وحدة'),
                new ItemNode('وحدة ألعلاقات وألاعلام','وحدة'),
                new ItemNode('وحدة ألادارة','وحدة'),
            ]),
            new ItemNode('شعبة ألبريد ألمركزي','شعبة'),
            new ItemNode('وحدة دار أبن ألاثير للطباعة وألنشر','وحدة'),
            new ItemNode('وحدة حضانة الجامعة','وحدة'),
        ]),
        new ItemNode('قسم الرقابة وألتدقيق ألداخلي','قسم أداري',[
            new ItemNode('شعبة ألتدقيق ألأداري','شعبة'),
            new ItemNode('شعبة ألتدقيق ألمالي','شعبة'),
            new ItemNode('شعبة متابعة ألتقارير','شعبة'),
        ]),
        new ItemNode('قسم ضمان ألجودة وألاداء ألجامعي','قسم أداري',[
            new ItemNode('ششعبة ضمان ألجوده','شعبة'),
            new ItemNode('شعبة تقويم ألاداء ألجامعي','شعبة'),
            new ItemNode('شعبة أعتماد ألمختبرات','شعبة'),
            new ItemNode('شعبة ألتدريب وألدعم ألفني','شعبة'),
        ]),
        new ItemNode('قسم شؤون ألأقسام ألداخلية','قسم أداري',[
            new ItemNode('معاون مدير ألاقسام ألداخلية للطلاب','معاون مدير'),
            new ItemNode('معاون مدير ألاقسام ألداخلية للطالبات','معاون مدير'),
            new ItemNode('شعبة أسكان ألطلبة','شعبة'),
            new ItemNode('شعبة ألحسابات وألمخازن والتجهيزات','شعبة'),
            new ItemNode('شعبة ألبحث ألاجتماعي','شعبة'),
            new ItemNode('وحدة ألعلاج ألنفسي للمتعاطين','شعبة'),
            new ItemNode('شعبة ألصيانة وألخدمات','شعبة'),
            new ItemNode('شعبة ألشؤون ألقانونية وألادارية','شعبة'),
            new ItemNode('شعبة ألتدقيق','شعبة'),
            new ItemNode('وحدة ألمتابعة','وحدة'),
            new ItemNode('وحدة ألاعلام','وحدة'),
        ]),
        new ItemNode('مركز ألتعليم ألمستمر','قسم أداري',[
            new ItemNode('شعبة ألتطوير','شعبة'),
            new ItemNode('شعبة ألتدريب','شعبة'),
            new ItemNode('شعبة ألشؤون ألأدارية','شعبة'),
            new ItemNode('شعبة أبن سينا للتعليم','شعبة'),
            new ItemNode('وحدة ألحسابات','وحدة'),

        ]),
        new ItemNode('أمانة مجلس ألجامعة','شعبة'),
        new ItemNode('مكتب رئيس ألجامعة','شعبة'),
        new ItemNode('شعبة شؤوون ألترقيات ألعلمية','شعبة'),


    ]),
    new ItemNode('','كلية',[
        new ItemNode('معاون ألعميد للشؤون ألعلميه','معاون عميد',[
            new ItemNode('شعبة ضمان ألجودة و ألاداء ألجامعي','شعبة',[
                new ItemNode('وحدة ضمان ألجوده','وحدة'),
                new ItemNode('وحدة تقويم ألاداء ألجامعي','وحدة'),
                new ItemNode('وحدة أعتماد ألمختبرات','وحدة'),
                new ItemNode('وحدة ألتدريب وألدعم ألفني','وحدة'),
            ]),
            new ItemNode('شعبة ألدراسات وألتخطيط','شعبة',[
                new ItemNode('وحدة ألاحصاء','وحدة'),
                new ItemNode('وحدة ألتخطيط وألتدريب وألتطوير','وحدة'),
                new ItemNode('وحدة ألبيانات وألمعلوماتيه','وحدة'),
            ]),
            new ItemNode('شعبة شؤون ألطلبة وألتسجيل','شعبة',[
                new ItemNode('وحدة متابعة شؤون ألطلبة','وحدة'),
                new ItemNode('وحدة ألخريجين وألتصديقات','وحدة'),
                new ItemNode('وحدة تدقيق ألوثائق','وحدة'),
                new ItemNode('وحدة ألتسجيل وألقبول','وحدة'),
            ]),
            new ItemNode('شعبة ألبعثات وألعلاقات ألثقافية','شعبة',[
                new ItemNode('وحدة ألدارسين في ألخارج','وحدة'),
                new ItemNode('وحدة ألعلاقات ألثقافية','وحدة'),
            ]),
            new ItemNode('شعبة شؤون ألدراسات ألعليا','شعبة',[
                new ItemNode('وحدة ألقبول','وحدة'),
                new ItemNode('وحدة متابعة شؤون ألطلبة','وحدة'),
                new ItemNode('وحدة ألأوامر ألأدارية','وحدة'),
            ]),
            new ItemNode('شعبة ألشؤون ألعلميه','شعبة',[
                new ItemNode('وحدة ألبحوث ألعلميه','وحدة'),
                new ItemNode('وحدة تسويق ألنتاجات ألعلمية','وحدة'),
                new ItemNode('وحدة ألأرشاد ألتربوي','وحدة'),
                new ItemNode('وحدة ألمشاريع ألريادية','وحدة'),
                new ItemNode('وحدة حاضنات ألتكنلوجيا','وحدة'),
            ]),
            new ItemNode('وحدةألتأهيل وألتوظيف وألمتابعة','وحدة')
        ]),
        new ItemNode('معاون ألعميد للشئون ألأدارية','معاون عميد',[
            new ItemNode('شعبة ألموارد ألبشرية','شعبة',[
                new ItemNode('وحدة شؤون ألموظفين','وحدة'),
                new ItemNode('وحدة شؤون ألتدريسيين','وحدة'),
                new ItemNode('وحدة ألتوظيف وألملاك','وحدة'),
                new ItemNode('وحدة ألتقاعد','وحدة'),
                new ItemNode('وحدةألملفات وألتوثيق ألألكتروني','وحدة'),
            ]),
            new ItemNode('شعبة ألشؤون ألمالية','شعبة',[
                new ItemNode('وحدة صندوق ألتعليم ألعالي','وحدة'),
                new ItemNode('وحدة ألحسابات','وحدة'),
                new ItemNode('وحدة ألتخطيط ألمالي','وحدة'),
                new ItemNode('وحدة ألتجهيزات','وحدة'),
                new ItemNode('وحدة حسابات ألخطة','وحدة'),
                new ItemNode('وحدة ألتوحيد','وحدة'),
            ]),
            new ItemNode('شعبة ألشؤون ألقانونية','شعبة',[
                new ItemNode('وحدة ألحقوق وألدعاوى','وحدة'),
                new ItemNode('وحدة ألأستشارات ألقانونية','وحدة'),
                new ItemNode('وحدة ألعقود وألكفالات','وحدة'),
            ]),
            new ItemNode('شعبة ألنشاطات ألطلابية','شعبة',[
                new ItemNode('وحدة ألنشاطات ألفنية','وحدة'),
                new ItemNode('وحدة ألنشاطات ألرياضية','وحدة'),
                new ItemNode('وحدة أللياقة ألبدنية وألعلاج ألطبيعي','وحدة'),
                new ItemNode('وحدة ألنشاطات ألكشفية','وحدة'),
            ]),
            new ItemNode('شعبة ألنشاطات ألادارية','شعبة'),
            new ItemNode('وحدة ألأوراق','وحدة'),
        ]),
        new ItemNode('شعبة ألرقابة وألتدقيق ألداخلي','شعبة',[
            new ItemNode('وحدة ألتدقيق ألمالي','وحدة'),
            new ItemNode('وحدة ألتدقيق ألأداري','وحدة'),
            new ItemNode('وحدة متابعة ألتقارير','وحدة'),
        ]),
        new ItemNode('مقررية مجلس ألكلية','وحدة'),
        new ItemNode('مكتب ألعميد','وحدة'),
        new ItemNode('وحدة ألترقيات ألعلمية','وحدة'),
        new ItemNode('وحدة ألمكتبة','وحدة'),
    ]),
    new ItemNode('','مساعد رئيس جامعة'),
    new ItemNode('','معاون عميد'),
    new ItemNode('','معاون مدير'),
    new ItemNode('','مركز بحثي'),
    new ItemNode('','مركز خدمي'),
    new ItemNode('','قسم أداري'),
    new ItemNode('','قسم علمي',[
        new ItemNode('سكرتارية ألقسم','وحدة')
    ]),
    new ItemNode('','فرع علمي',[
        new ItemNode('سكرتارية ألفرع','وحدة')
    ]),

    new ItemNode('','شعبة'),
    new ItemNode('','وحدة')

]



@Injectable()
export class NodeDatabaseService {
    universityDataChange = new BehaviorSubject<ItemNode[]>([]);
    nodeUpdate=new BehaviorSubject<ItemNode>(new ItemNode());
    minstryDataChange= new BehaviorSubject<ItemNode[]>([]);
    query:any={};

    constructor(private structureService:StructureService) {}

    initialize(targetData:BehaviorSubject<ItemNode[]>) {
        this.structureService.findAll(this.query).subscribe((response)=>{
            targetData.next(response)
        })
    }

    findAll(parentNode:ItemNode,targetData:BehaviorSubject<ItemNode[]>){
        this.structureService.findAll(this.query).subscribe((response)=>{
            targetData.next(response);
            this.nodeUpdate.next(parentNode)
        })
    }


    /** Add an item to to-do list */
    insertItem(parent: ItemNode, type: string,targetData:BehaviorSubject<ItemNode[]>): ItemNode {
        if (!parent.children) {
            parent.children = [];
        }
        var entity=EntitiesList.find((item)=>item.type==type)
        const newItem = JSON.parse(JSON.stringify(entity));
        parent.children.unshift(newItem)
        targetData.next(targetData.value);
        return newItem;
    }


    getParentFromNodes(node: ItemNode,targetData:BehaviorSubject<ItemNode[]>): ItemNode {

        for (let i = 0; i < targetData.value.length; ++i) {
            const currentRoot = targetData.value[i];
            const parent = this.getParent(currentRoot, node);
            if (parent != null) {
                return parent;
            }
        }
        return null;
    }

    getParent(currentRoot: ItemNode, node: ItemNode): ItemNode {
        if (currentRoot.children && currentRoot.children.length > 0) {
            for (let i = 0; i < currentRoot.children.length; ++i) {
                const child = currentRoot.children[i];
                if (child === node) {
                    return currentRoot;
                } else if (child.children && child.children.length > 0) {
                    const parent = this.getParent(child, node);
                    if (parent != null) {
                        return parent;
                    }
                }
            }
        }
        return null;
    }

    updateItem(node: ItemNode, name: string,parentNode:ItemNode,targetData:BehaviorSubject<ItemNode[]>) {
        node.name = name;
        this.structureService.create(parentNode._id,node).subscribe((response)=>{
            this.findAll(parentNode,targetData)
        })
    }

    deleteItem(node: ItemNode,targetData:BehaviorSubject<ItemNode[]>) {
        this.deleteNode(targetData.value, node);
        targetData.next(targetData.value);
    }

    deleteNode(nodes: ItemNode[], nodeToDelete: ItemNode) {
        const index = nodes.indexOf(nodeToDelete, 0);
        if (index > -1) {
            nodes.splice(index, 1);
            if(nodeToDelete._id){
                this.structureService.remove(nodeToDelete._id).subscribe((response)=>{
                })
            }
        } else {
            nodes.forEach((node) => {
                if (node.children && node.children.length > 0) {
                    this.deleteNode(node.children, nodeToDelete);
                }
            });
        }
    }

    getTitle(type:string):Entity{
        var index=EntitiesTitles.findIndex((item)=>item.type===type);
        console.log({index})
        return EntitiesTitles[index];
    }


}
