<div class="document-container">
    <div class="row">
        <div class="col-md-6 ">
            <div class="row">
                <div class="col-md-5 offset-md-7">
                    <div class="small-info text-center">
                        <label class=" font-bold">بطاقة تموين</label>
                    </div>
                    <div class="small-info text-center">
                        <input type="text " [(ngModel)]="item.province" class="small-textbox text-center" />
                    </div>
                    <div class="small-info text-center ">
                        <input type="text " [(ngModel)]="item.fromYear" class="small-textbox text-center" />
                    </div>
                    <div class="small-info text-center ">
                        <input type="text " [(ngModel)]="item.toYear" class="small-textbox text-center" />
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <div class="small-info">
                    <label class="info-label-150 font-bold">ألرقم </label>
                    <span>:</span>
                    <input type="text " [(ngModel)]="item.cardNumber" class="textbox-inline textbox-200" />
                </div>
                <div class="small-info">
                    <label class="info-label-150 font-bold">أسم رب ألاسرة </label>
                    <span>:</span>
                    <input type="text " [(ngModel)]="item.houseHoldName" class="textbox-inline textbox-200" />
                </div>
            </div>
            <div>
                <div class="small-info">
                    <label class="info-label-150 font-bold"> عدد أفراد الأسرة </label>
                    <span>:</span>
                    <input type="text " disabled="true" [(ngModel)]="item.numberOfFamilyMembers"
                        [value]="item.familyMembers.length" class="textbox-inline text-center " />
                </div>
                <div class="small-info">
                    <label class="info-label-150 font-bold"> رقم ألمحلة </label>
                    <span>:</span>
                    <input type="text " [(ngModel)]="item.address.m" class="textbox-inline text-center " />
                </div>
                <div class="small-info">
                    <label class="info-label-150 font-bold"> رقم ألزقاق </label>
                    <span>:</span>
                    <input type="text " [(ngModel)]="item.address.z" class="textbox-inline text-center " />
                </div>
                <div class="small-info">
                    <label class="info-label-150 font-bold">رقم ألدار </label>
                    <span>:</span>
                    <input type="text " [(ngModel)]="item.address.d" class="textbox-inline text-center" />
                </div>
            </div>
            <div class="mt-2">
                <div class="small-info">
                    <label class="w-50 font-bold">رقم وأسم المركز ألتمويني </label>
                    <span>:</span>
                    <input type="text " [(ngModel)]="item.rationCenterNumber"
                        class="textbox-inline text-center w-auto" />
                </div>
                <div class="small-info">
                    <input type="text " [(ngModel)]="item.rationCenterName" class="textbox-inline text-center w-100 " />
                </div>
                <div class="small-info">
                    <label class="w-50 font-bold">رقم وأسم وكيل ألغذائية </label>
                    <span>:</span>
                    <input type="text " [(ngModel)]="item.foodAgentNumber" class="textbox-inline text-center w-auto" />
                </div>
                <div class="small-info">
                    <input type="text " [(ngModel)]="item.foodAgentName" class="textbox-inline text-center w-100 " />
                </div>

                <div class="small-info">
                    <label class="w-50 font-bold">رقم وأسم وكيل ألطحين </label>
                    <span>:</span>
                    <input type="text " [(ngModel)]="item.wheatAgentNumber" class="textbox-inline text-center w-auto" />
                </div>
                <div class="small-info">
                    <input type="text " [(ngModel)]="item.wheatAgentName" class="textbox-inline text-center w-100 " />
                </div>
            </div>
            <div class=" mt-3">
                <div class="info d-flex justify-content-between border-bottom">
                    <label class="font-bold w-20">ت</label>
                    <label class="font-bold w-50 text-center ">أسماء أفراد ألاسرة</label>
                    <button class="btn add-family-member " (click)="item.addMember()" title="أضافة فرد جديد">
                        <i class="fa fa-plus fa-1x"></i>
                    </button>
                </div>
                <div class="info d-flex justify-content-between"
                    *ngFor="let member of item.familyMembers; let i=index;">
                    <label class="font-bold w-20">{{i+1}}.</label>
                    <input type="text" name="familymember_{{i}}" class="textbox-inline w-50 " [(ngModel)]="member.name" />
                    <button class="btn remove-family-member " (click)="item.removeMember(i)" title="حذف">
                        <i class="fa fa-times fa-1x"></i>
                    </button>
                </div>
            </div>
            <div class="mt-10 text-center mx-auto">
                <input type="text" placeholder="تاريخ ألاصدار" [(ngModel)]="item.issueDate"
                    class="mb-2 border text-center textbox-block font-bold" />
            </div>
        </div>
        <div class="col-md-5 offset-md-1">
            <app-files-ImageFileUploader [image]="item.faceOne"
                (fileChange)="documentHelper.uploadFile(item,item.faceOne,'ألبطاقة ألتموينية','faceone')"
                [dimensions]="item.dimensions">
            </app-files-ImageFileUploader>
        </div>
    </div>
</div>