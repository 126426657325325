import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-bonuses-upgrades",
    templateUrl: "./bonuses-upgrades.component.html",
    styleUrls: ["./bonuses-upgrades.component.scss"],
})
export class BonusesUpgradesComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
