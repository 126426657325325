import { AbstractControl, FormGroup } from "@angular/forms";

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}

export function emailDomain(control) {
    const email = control.value;
    if (email && email.indexOf("@") != -1) {
        const [_, domain] = email.split("@");
        if (domain !== "uomosul.edu.iq") {
            return { emailDomain: true };
        }
    }
    return null;
}
