<div [ngClass]="{'d-none':view!='list'}">
    <div class="card mt-6">
        <div class="card-header">
            <h4><i class="fa fa-book pl-3"></i>كتب ألشكر وألتقدير </h4>
            <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
                <button class="btn btn-print" (click)="newOrEdit('new')" title="أضافة كتاب شكر">
                    <i class="fa fa-plus-circle fa-2x"></i>
                </button>
            </div>
        </div>
        <div class="card-block px-0">
    
            <table id="upgradesummary" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger" class="table table-bordered table-striped border-0 my-0">
            <thead>
                <tr >
                    <th class="width-15">تاريخ ألكتاب </th>
                    <th class="width-15">رقم ألكتاب </th>
                    <th class="w-40"> رتبة ألجهة ألمانحة  </th>
                    <th class="width-20">مدة ألقدم ؟ </th>
                    <th class="text-center" style="width:15%;"><i class="fa fa-ellipsis-v"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items;let i=index;">
                    <td class="text-center">{{item.issueDate|formatDate| arabicDate}}</td>
                    <td>{{item.documentNumber|translateDigits:'ar'}}</td>
                    <td class="pr-3">{{item.issuer}}</td>
                    <td class="d-flex justify-content-between px-3">
                        <span>{{item.affectMonths.toString() |translateDigits:'ar'}} </span>
                        <span>شهر </span>
                    </td>
                    <th class="text-center">
                        <div class="dropdown">
                            <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true">
                                <i class="fa fa-ellipsis-v text-secondary"></i>
                            </button>
                            <ul class="dropdown-menu bg-creamy " aria-labelledby="dropdownMenuButton">
                                <li class="dropdown-item " (click)="SelectedItem=item;newOrEdit('edit')"><i
                                        class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                                    تعديل ألكتاب  </li>
                                <li class="dropdown-item"
                                    (click)="this.SelectedItem=item;remove();"><i
                                        class="fa fa-trash text-danger fa-lg px-1 pl-2 "></i> حذف ألكتاب  </li>
                            </ul>
                        </div>
                    </th>
                </tr>
            </tbody>
        </table>
        </div>
    </div>

</div>

<app-awards-form *ngIf="view=='new'" [userId]="userId" (viewChange)="this.findAll()"></app-awards-form>
<app-awards-form *ngIf="view=='edit'"  [userId]="userId" [Item]="SelectedItem" (viewChange)="this.findAll()"></app-awards-form>


