import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Employee } from "../../../models/hr/employee/employee";
import {ConstantsUtils} from "../../../classes/constants";
import { BasicinfoService } from "src/app/services/employee/basicinfo.service";
import { ActivatedRoute } from "@angular/router";
import { ImageFile } from "src/app/classes/Files";
import { UsersService } from "src/app/services/users/users.service";
import { BasicInfoUtilService } from "./basicinfo-util-service";
declare var $:any;
@Component({
    selector: "app-bacic-info",
    templateUrl: "./bacic-info.component.html",
    styleUrls: ["./bacic-info.component.scss"],
})
export class BacicInfoComponent implements OnInit {
   

    constructor(
        public basicInfoService:BasicInfoUtilService,
        private Activeroute:ActivatedRoute
    ) {}

    async ngOnInit() {
        this.basicInfoService.employee=new Employee();
        this.basicInfoService.userId=await this.Activeroute.parent.snapshot.params["userId"];
    }
}


@Component({
    selector: "app-bacic-nameinfo",
    templateUrl: "./templates/nameinfo.html",
    styleUrls: ["./bacic-info.component.scss"],
})
export class BacicNameInfoComponent implements OnInit {
    religions:string[]=[
        "مسلم",
        "مسيحي",
        "أيزيدي",
        "صابئي"
    ]

    nationalisims:string[]=[
        "عربي",
        "كردي",
        "تركماني",
        "مسيحي",
        "شبكي",
        "يزيدي"

    ]
    constructor(
        public basicInfoService:BasicInfoUtilService,
        private Activeroute:ActivatedRoute
    ){

    }
    async ngOnInit() {
        this.basicInfoService.userId=await this.Activeroute.parent.parent.snapshot.params["userId"];
        this.basicInfoService.getBasicInfo()
    }
}

@Component({
    selector: "app-bacic-contactinfo",
    templateUrl: "./templates/contactinfo.html",
})
export class BacicContactInfoComponent implements OnInit {
    constructor(
        public basicInfoService:BasicInfoUtilService,
        private Activeroute:ActivatedRoute
    ){

    }
    async ngOnInit() {
        this.basicInfoService.userId=await this.Activeroute.parent.parent.snapshot.params["userId"];
        this.basicInfoService.getBasicInfo()  
    }
}

@Component({
    selector: "app-bacic-addressinfo",
    templateUrl: "./templates/addressinfo.html",
})
export class BacicAddressInfoComponent implements OnInit {
    constructor(
        public basicInfoService:BasicInfoUtilService,
        private Activeroute:ActivatedRoute
    ){

    }
    async ngOnInit() {
        this.basicInfoService.userId=await this.Activeroute.parent.parent.snapshot.params["userId"];
        this.basicInfoService.getBasicInfo()
    }
}



@Component({
    selector: "app-bacic-photo",
    templateUrl: "./templates/basicphoto.html",
})
export class BacicPhotoComponent implements OnInit {
    constructor(
        public basicInfoService:BasicInfoUtilService,
        private Activeroute:ActivatedRoute
    ){

    }
    async ngOnInit() {
        this.basicInfoService.userId=await this.Activeroute.parent.parent.snapshot.params["userId"];
        this.basicInfoService.getUserPhoto()
    }
}

