import { Component } from "@angular/core";

@Component({
    selector: "app-employee-activities",
    templateUrl: "./employee-activities.component.html",
    styleUrls: ["./employee-activities.component.scss"],
})
export class EmployeeActivitiesComponent {}

@Component({
    selector: "app-papers-activities",
    templateUrl: "./templates/papers.component.html",
    styleUrls: ["./employee-activities.component.scss"],
})
export class PapersComponent {}

@Component({
    selector: "app-classes-activities",
    templateUrl: "./templates/papers.component.html",
    styleUrls: ["./employee-activities.component.scss"],
})
export class ClassesComponent {}

@Component({
    selector: "app-commites-activities",
    templateUrl: "./templates/papers.component.html",
    styleUrls: ["./employee-activities.component.scss"],
})
export class CommitesComponent {}

@Component({
    selector: "app-workshops-activities",
    templateUrl: "./templates/papers.component.html",
    styleUrls: ["./employee-activities.component.scss"],
})
export class WorkshopsComponent {}
