<div class="document-container">
    <div class="row justify-content-center">
        <div class="col-md-12 ">
            <app-files-ImageFileUploader [image]="item.faceOne"
            (fileChange)="documentHelper.uploadFile(item,item.faceOne,'جواز ألسفر','faceone')"
            [dimensions]="item.dimensions">
        </app-files-ImageFileUploader>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12 ">
            <div class="row">
                <div class="col-md-4">
                    <div class="info text-center">
                        <label for="">رقم ألجواز</label>
                    </div>
                    <div class="info text-center">
                        <input type="text "  [(ngModel)]="item.passportNumber" class="textbox-inline text-center"  />
                    </div>

                </div>
                <div class="col-md-4">
                    <div class="info text-center">
                        <label for="">ألبلد/Country</label>
                    </div>
                    <div class="info text-center">
                        <input type="text "  [(ngModel)]="item.country" class="textbox-inline text-center"  />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="info text-center">
                        <label for="">Type/ألنوع</label>
                    </div>
                    <div class="info text-center">
                        <input type="text "  [(ngModel)]="item.passportType" class="textbox-inline textbox-30 text-center"  />
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-6">
                    <div class="info">
                        <label for="">ألاسم ألثلاثي</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.arabicFullName" class="textbox-inline textbox-200 pr-0"  />
                    </div>
                </div>

                <div class="col-md-6 text-left">
                    <div class="info">
                        <label for="">Full Name</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.englishFullName" class="textbox-inline textbox-200 pl-0"  dir="ltr" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mt-2">
                    <div class="info">
                        <label for="">أللقب</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.arabicSurname" class="textbox-inline  pr-0"  />
                    </div>
                </div>

                <div class="col-md-4 text-center mt-2">
                    <div class="info">
                        <label for="">ألجنسية / Nationality</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.arabicNationality" class="textbox-inline text-left  pr-0"  />
                        <span>/</span>
                        <input type="text "  [(ngModel)]="item.englishNationality" class="textbox-inline  pr-0"  />
                    </div>
                </div>

                <div class="col-md-4 text-left mt-2">
                    <div class="info">
                        <label for="">Surname</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.englishSurname" class="textbox-inline  pl-0"  dir="ltr" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 mt-2">
                    <div class="info">
                        <label for="">محل ألميلاد / Place Of Birth</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.placeOfBirth" class="textbox-inline  pr-0"  />
                        <span></span>
                        <input type="text "  [(ngModel)]="item.placeOfBirthCountry" class="textbox-inline textbox-30  pr-0"  />
                    </div>
                </div>

                <div class="col-md-4 text-center mt-2">
                    <div class="info">
                        <label for="">تاريخ ألميلاد / Date Of Birth</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.dateOfBirth" class="textbox-inline text-center  pr-0"  />
                    </div>
                </div>

                <div class="col-md-4 text-left mt-2">
                    <div class="info">
                        <label for="">ألجنس / sex</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.arabicGender" class="textbox-inline textbox-30 text-left  pr-0"  />
                        <span>/</span>
                        <input type="text "  [(ngModel)]="item.englishGender" class="textbox-inline textbox-30 text-left  pr-0"  />
                    </div>
                </div>
            </div>

            
            <div class="row">
                <div class="col-md-6 mt-2">
                    <div class="info">
                        <label for="">أسم ألام</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.arabicMotherName" class="textbox-inline  pr-0"  />
                    </div>
                </div>
                <div class="col-md-6 text-left mt-2">
                    <div class="info">
                        <label for="">Mother Name</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.englishMotherName" class="textbox-inline  pl-0"  dir="ltr" />
                    </div>
                </div>
            </div>

            
            <div class="row">
                <div class="col-md-4 mt-2">
                    <div class="info">
                        <label for="">محل ألميلاد / Place Of Birth</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.arabicIssuer" class="textbox-inline small-textbox   pr-0"  />
                        <span>/</span>
                        <input type="text "  [(ngModel)]="item.englishIssuer" class="textbox-inline textbox-30  pr-0"  />
                    </div>
                </div>

                <div class="col-md-4 text-center mt-2">
                    <div class="info">
                        <label for="">تاريخ ألاصدار / Date Of Issue</label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.issueDate" class="textbox-inline text-center  pr-0"  />
                    </div>
                </div>

                <div class="col-md-4 text-left mt-2">
                    <div class="info">
                        <label for="">تاريخ ألنفاذ / Date Of Expiry </label>
                    </div>
                    <div class="info">
                        <input type="text "  [(ngModel)]="item.expirationDate" class="textbox-inline text-left  pl-0"  />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
