import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataTablesModule } from "angular-datatables";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

import { SystemManagerRoutingModule } from "./system-manager-routing.module";
import { SystemManagerComponent } from "./system-manager/system-manager.component";

import { MaterialModule } from "../material";
import { SharedComponentsModule } from "../shared-components/shared-components.module";

import { RoleUsersComponent, RolesManagementComponent } from './system-roles/roles-management/roles-management.component';
import { FinancialAllowancesComponent } from './components/financial-allowances/financial-allowances.component';
import { SystemGradesComponent } from "./system-grades/system-grades.component";
import { GradesFormComponent, JobTitlesFormComponent, PermanentGradesComponent, StagesFormComponent } from "./system-grades/permanent-grades/permanent-grades";
import { ContractGradesComponent } from './system-grades/contract-grades/contract-grades.component';
import { DailyGradesComponent } from './system-grades/daily-grades/daily-grades.component';
import { SystemRolesComponent } from "./system-roles/system-roles";
import { UserRolesComponent, UsersManagmentComponent } from "./system-roles/accounts-managments/accounts-manangement";
import { MinistryStructureComponent, OrganizationEntityForm, OrganizationStructuresComponent, OrganizationUsers, UniversityStructureComponent } from "./organization-chart/organization-chart.component";
import { NodeDatabaseService } from "./organization-chart/service";

@NgModule({
    declarations: [
        SystemManagerComponent,
        OrganizationStructuresComponent,
        OrganizationUsers,
        MinistryStructureComponent,
        UniversityStructureComponent,
        OrganizationEntityForm,

        UniversityStructureComponent,
        SystemGradesComponent,
        GradesFormComponent,
        JobTitlesFormComponent,
        StagesFormComponent,
        RolesManagementComponent,
        UserRolesComponent,
        FinancialAllowancesComponent,
        PermanentGradesComponent,
        ContractGradesComponent,
        DailyGradesComponent,
        SystemRolesComponent,
        RoleUsersComponent,
        UsersManagmentComponent
        
    ],
    imports: [
        CommonModule,
        MaterialModule,
        SharedComponentsModule,
        SystemManagerRoutingModule,
        DataTablesModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
    ],
    providers: [NodeDatabaseService]
})
export class SystemManagerModule {}
