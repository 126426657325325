import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DataTablesModule } from "angular-datatables";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ModalModule, BsModalRef } from "ngx-bootstrap/modal";

import { NgxPrintModule } from "ngx-print";
import { HrRoutingModule } from "./hr-routing.module";
import { MaterialModule } from "../material";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { MyinfoComponent } from "./employee/myinfo/myinfo.component";
import {
    BacicInfoComponent,
    BacicNameInfoComponent,
    BacicAddressInfoComponent,
    BacicContactInfoComponent,
    BacicPhotoComponent,
} from "./employee/bacic-info/bacic-info.component";
import {
    DegreeInfoComponent,
    NoDegreeComponent,
    ElementryDegreeComponent,
    IntermediateDegreeComponent,
    HighschoolDegreeComponent,
    UndergraduateDegreeComponent,
    GraduateDegreeComponent,
    DiplomaDegreeComponent,
    DegreeFormComponent
} from "./employee/certificate-info/degrees-components";
import {
    OfficialDocumentsComponent,
    NationalIdComponent,
    CivilIdComponent,
    AddressCardComponent,
    RationCardComponent,
    CertificateOfNationalityComponent,
    PassportComponent,
    UniversityIdComponent,
    OfficialDocumentsFormComponent
} from "./employee/official-documents/official-documents.component";
import {
    EmploymentInfoComponent,
    EmploymentBasicComponent,
    EmploymentNotesComponent,
    EmploymentStatusComponent,
} from "./employee/employment-info/employment-info.component";
import {
    VacationsComponent,
    NormalLongVacationsComponent,
    NormalShortVacationsComponent,
    FiveYearsVacationsComponent,
    UnpaidVacationsComponent,
    StudyVacationsComponent,
    SickLeaveVacationsComponent,
    CompanionshipVacationComponent,
    CareVacationComponent,
    AbsenseVacationComponent,
    DisabilityVacationComponent,
} from "./employee/vacations/vacations.component";

import { EmployeesComponent } from "./hr-admin/employees/employees.component";
import { FamilyInfoComponent,MaritialInfoComponent,ChildrenInfoComponent, SpouseFormComponent, ChildFormComponent } from "./employee/family-info/family-info.component";
import { LocalEditorModule } from "../editor/editor.module";
import { LocationInfoComponent } from "./employee/certificate-info/shared/location-info/location-info.component";
import {
    TimeOffComponent,
    TimeOffForm,
} from "./employee/vacations/time-off/time-off.component";
import {
    VacationForm,
    VacationListComponent,
} from "./employee/vacations/vacation-forms/vacation-forms.component";
import { VacationSumarryComponent } from "./employee/vacations/vacation-sumarry/vacation-sumarry.component";
import {
    MotherhoodVacationFormComponent,
    MotherhoodVacationsComponent,
} from "./employee/vacations/motherhood-vacation/motherhoode-vacation";
import { BonusesUpgradesComponent } from "./employee/bonuses-upgrades/bonuses-upgrades.component";
import {
    UpgradesTableComponent,
    UpgradeFormComponent,
    BonusListComponent,
    BonusFormComponent,
} from "./employee/bonuses-upgrades/upgrades/upgrades.component";
import { UpgradeSummaryComponent } from "./employee/bonuses-upgrades/upgrade-summary/upgrade-summary.component";
import { AwardsPunishmentsComponent } from "./employee/awards-punishments/awards-punishments.component";
import {
    AwardsComponent,
    AwardsFormComponent,
} from "./employee/awards-punishments/awards/awards.component";
import {
    PunishmentsComponent,
    PunishmentsFormComponent,
} from "./employee/awards-punishments/punishments/punishments.component";
import { CurrentWorkplacesComponent, EmployeeWorkplacesComponent,MohWorkplacesComponent,OtherWorkplacesComponent,TemporaryWorkplacesComponent,UniversityWorkplacesComponent, WorkplaceFormComponent, WorkplaceListComponent } from './employee/employee-workplaces/employee-workplaces.component';
import { AdministrativePositionsComponent, MohPositionComponent, OtherPositionComponent, PositionFormComponent, PositionListComponent, UniversityPositionComponent } from './employee/administrative-positions/administrative-positions.component';
import { FacultyPromotionsComponent, PromotionFormComponent } from './employee/faculty-promotions/faculty-promotions.component';
import { ClassesComponent, CommitesComponent, EmployeeActivitiesComponent, PapersComponent, WorkshopsComponent } from './employee/employee-activities/employee-activities.component';
import { WorkplaceEmployeesComponent } from './data/workplace-employees/workplace-employees.component';
@NgModule({
    declarations: [
        MyinfoComponent,
        BacicInfoComponent,
        BacicAddressInfoComponent,
        BacicNameInfoComponent,
        BacicContactInfoComponent,
        BacicPhotoComponent,
        /***** Components of Official Degrees **************/
        DegreeInfoComponent,
        NoDegreeComponent,
        ElementryDegreeComponent,
        IntermediateDegreeComponent,
        HighschoolDegreeComponent,
        UndergraduateDegreeComponent,
        GraduateDegreeComponent,
        DiplomaDegreeComponent,
        DegreeFormComponent,

        /***** Components of Official Documents **************/
        OfficialDocumentsComponent,
        NationalIdComponent,
        CivilIdComponent,
        AddressCardComponent,
        RationCardComponent,
        CertificateOfNationalityComponent,
        PassportComponent,
        UniversityIdComponent,
        OfficialDocumentsFormComponent,

        /******* Employment Info components *********** */
        EmploymentInfoComponent,
        EmploymentBasicComponent,
        EmploymentNotesComponent,
        EmploymentStatusComponent,

        /**** Vacations Components      *******/
        VacationsComponent,

        NormalLongVacationsComponent,
        NormalShortVacationsComponent,
        StudyVacationsComponent,

        FiveYearsVacationsComponent,
        UnpaidVacationsComponent,
        SickLeaveVacationsComponent,
        CompanionshipVacationComponent,
        CareVacationComponent,

        MotherhoodVacationsComponent,
        MotherhoodVacationFormComponent,
        AbsenseVacationComponent,
        DisabilityVacationComponent,

        /************ Hr Admin components *****************/
        EmployeesComponent,
        FamilyInfoComponent,MaritialInfoComponent,ChildrenInfoComponent,SpouseFormComponent,ChildFormComponent,
        LocationInfoComponent,
        TimeOffComponent,
        TimeOffForm,
        VacationListComponent,
        VacationForm,
        VacationSumarryComponent,
        BonusesUpgradesComponent,
        UpgradesTableComponent,
        UpgradeFormComponent,
        UpgradeSummaryComponent,
        BonusListComponent,
        BonusFormComponent,
        AwardsPunishmentsComponent,
        AwardsComponent,
        PunishmentsComponent,
        AwardsFormComponent,
        PunishmentsFormComponent,

        /************ Workplaces *****************/
        EmployeeWorkplacesComponent,UniversityWorkplacesComponent,OtherWorkplacesComponent,
        CurrentWorkplacesComponent,WorkplaceListComponent,WorkplaceFormComponent,TemporaryWorkplacesComponent,MohWorkplacesComponent, 
        AdministrativePositionsComponent,PositionListComponent,PositionFormComponent,UniversityPositionComponent,MohPositionComponent,OtherPositionComponent, 
        FacultyPromotionsComponent,PromotionFormComponent, 
        EmployeeActivitiesComponent,PapersComponent,CommitesComponent,ClassesComponent,WorkshopsComponent, WorkplaceEmployeesComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        MaterialModule,
        DataTablesModule,
        NgxPrintModule,
        HrRoutingModule,
        ModalModule.forRoot(),
        SharedComponentsModule,
        LocalEditorModule,
    ],
    providers: [BsModalRef],
    exports: [MyinfoComponent, BacicInfoComponent],
})
export class HrModule {}
