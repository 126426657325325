<div class="card" [ngClass]="{ 'd-none': view != 'list' }">
    <div class="card-header">
        <h4> <i class="fa fa-users"></i> أستيراد ألبيانات :  {{node.name}}</h4>
        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
            <button class="btn btn-print"  title="حذف ألبيانات" (click)="deleteAll();">
                <i class="fa fa-trash fa-lg"></i>
            </button>
            <button class="btn btn-print" title="رجوع ألى ألقائمه" (click)="viewChange.emit('list')">
                <i class="fa fa-share fa-lg"></i>
            </button>
        </div>
    </div>
    <div class="card-block px-0">
        <div class="row px-3">
            <div class="col-md-8">
                <div class="form-group">
                    <input type="file" name="imported-file" class="file" (change)="onFileSelected($event)"
                        #fileInput />
                    <div class="input-group mb-3 ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"
                                [ngClass]="{'border-danger': !importer.isValidFile()}"><i
                                    class="fas fa-paperclip"></i></span>
                        </div>
                        <input type="text" class="form-control" disabled
                            [placeholder]="importer.file.name.length?importer.file.name:'تحميل ملف'"
                            aria-label="Upload File" aria-describedby="basic-addon1"
                            [ngClass]="{'is-invalid': !importer.isValidFile()}" />
                        <div class="input-group-append">
                            <button class="browse input-group-text btn btn-primary border"
                                (click)="fileInput.click();"
                                [ngClass]="{'border-danger bg-danger': !importer.isValidFile()}"
                                id="basic-addon2">
                                <i class="fas fa-file-alt"></i> أختار ملف
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <button class="btn btn-success btn-block border-radius-25 " [disabled]="!importer.isValidFile()" (click)="submit();">
                    <i class="fa fa-upload mx-2"></i> رفع ألبيانات
                </button>
            </div>
        </div>
        <table id="timeoff" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger"
            class="table table-bordered table-striped border-0">
            <thead>
                <tr>
                    <th class="w-10">#</th>
                    <th class="width-40">ألاسم</th>
                    <th class="width-40">ألكلية أو ألمركز</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of users;let i=index;">
                    <th>{{i+1}}</th>
                    <td>{{item.fullname}}</td>
                    <td>{{item.workplace.name}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>