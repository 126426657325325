import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpLoadingService } from "../shared/http-loading.service";

@Injectable({
    providedIn: "root",
})
export class DgreeService {
    private url = environment.ApiUrl + "/employeedegrees/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    findAll(id: string): Observable<any> {
        return this.http.get(this.url + id+ `?cache_bust=${new Date().getTime()}`);
    }
    createOrUpdate(userId: string, data: any): Observable<any> {
        return this.http.post(this.url + userId, data);
    }

    remove(degreeId:string){
        return this.http.delete(this.url+degreeId)

    }

    removeDegreeFile(degreeId: string, data: any) {
        return this.http.patch(this.url + "removefile/" + degreeId, data);
    }

}
