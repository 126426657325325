import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-test",
    templateUrl: "./test.component.html",
    styleUrls: ["./test.component.scss"],
})
export class TestComponent implements OnInit {
    opened = false;
    ngOnInit(): void {
        $("#menu-toggle").click(function (e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });
    }
    public throwTestError(): void {
        throw new Error("Sentry Test Error");
    }
}
