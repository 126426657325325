import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { HttpLoadingService } from "../../shared/http-loading.service";
import { param } from "jquery";

@Injectable({
    providedIn: "root",
})
export class MotherhoodVacationsService {
    private url = environment.ApiUrl + "/motherhood-vacation/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    findAll(userId: string, type: string): Observable<any> {
        return this.http.get(
            this.url + userId + `?cache_bust=${new Date().getTime()}`
        );
    }
    create(userId: string, data: any): Observable<any> {
        return this.http.post(this.url + userId, data);
    }

    createStage(motherhoodId: string, data: any) {
        return this.http.post(this.url + "stage/" + motherhoodId, data);
    }

    update(vacationId: string, data: any): Observable<any> {
        return this.http.patch(this.url + vacationId, data);
    }

    filter(userId: string, type: string, data: any): Observable<any> {
        return this.http.post(
            this.url +
                "filter/" +
                userId +
                `?cache_bust=${new Date().getTime()}`,
            data
        );
    }

    remove(vacationId: string) {
        return this.http.delete(this.url + vacationId);
    }

    removeStage(motherhoodId:string, stageId: string) {
        return this.http.delete(this.url+'stage/' + motherhoodId, {
            params: {
                stageId,
            }
        });
    }
}
