<div class="wrapper">
    <div  class="contents" >
        <div class="container">
            <app-page-header-info title2="ألشهادات"></app-page-header-info>
            <div class="content-container my-7 mx-md-4">
                
                <div class="card   "  [ngClass]="{'d-none':view!='list'}">
                    <div class="card-header">
                        <h4><i class="fa fa-certificate pl-3"></i>ألشهادات    </h4>
                        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
                            <button class="btn btn-print" (click)="newOrEdit('new')" title="أضافة  شهادة">
                                <i class="fa fa-plus-circle fa-2x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-block px-0">
                        <table id="degreeslist" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger" class="table w-100 table-bordered table-striped border-0 my-0">
                        <thead>
                            <tr >
                                <th class="d-none d-sm-table-cell" >#</th>
                                <th class="">ألشهادة   </th>
                                <th class="width-20"> 
                                    <span class="d-none d-md-block">تاريخ ألحصول عليها </span>
                                    <span class="d-block d-sm-none">  تاريخها </span>
                                </th>
                                <th class="d-none d-sm-table-cell" > ألمتعين عليها؟</th>
                                <th class="d-none d-sm-table-cell" >  أعادة ألتعيين</th>
                                <th class="">  ألحاليه؟    </th>
                                <td class="text-center d-print-none"><i class="fa fa-ellipsis-v"></i></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Items;let i=index;">
                                <th class="d-none d-sm-table-cell">{{i+1}}</th>
                                <th>{{item.type}}</th>
                                <td class="text-center">{{(item.degreeDate| formatDate) | arabicDate}}</td>
                                <td class="pr-3 text-center d-none d-sm-table-cell">
                                    <i *ngIf="item.isEmploymentDegree==true" class="fa fa-check fa-lg text-success"></i>
                                    <i *ngIf="item.isEmploymentDegree==false" class="fa fa-times fa-lg text-danger"></i>
                                </td>
                                <td class="pr-3 text-center d-none d-sm-table-cell">
                                    <i *ngIf="item.isRehireDegree==true" class="fa fa-check fa-lg text-success"></i>
                                    <i *ngIf="item.isRehireDegree==false" class="fa fa-times fa-lg text-danger"></i>
                                </td>
                                <td class="pr-3 text-center">
                                    <i *ngIf="item.isCurrentDegree==true" class="fa fa-check fa-lg text-success"></i>
                                    <i *ngIf="item.isCurrentDegree==false" class="fa fa-times fa-lg text-danger"></i>
                                </td>
                                <td class="text-center d-print-none">
                                    <div class="dropdown">
                                        <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                            aria-haspopup="true">
                                            <i class="fa fa-ellipsis-v text-secondary"></i>
                                        </button>
                                        <ul class="dropdown-menu bg-creamy " aria-labelledby="dropdownMenuButton">
                                            <li class="dropdown-item " (click)="SelectedDegree=item;newOrEdit('edit')"><i
                                                    class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                                                تعديل ألشهادة
                                            </li>
                                            <li class="dropdown-item"
                                                (click)="this.SelectedDegree=item;removeDegree();"><i
                                                    class="fa fa-trash text-danger fa-lg px-1 pl-2 "></i> حذف ألشهادة
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>

                <app-degree-form *ngIf="view=='edit'" 
                    [userId]="this.userId" [Item]="SelectedDegree"  
                    (viewChange)="this.getEmployeeDegrees();">
                </app-degree-form>
                <app-degree-form *ngIf="view=='new'" 
                    [userId]="this.userId"
                    (viewChange)="this.getEmployeeDegrees();">
                </app-degree-form>
            </div>
        </div>
        


    </div>
    <div class="drag-sidebar left-sidebar border-right">
        <div class="drag-list" cdkDropList [cdkDropListConnectedTo]="['container']" id="controlsMenue"
            [cdkDropListData]="degreeUtilService.degreeTypes">
            <div class="drag-item-box" *ngFor="let type of degreeUtilService.degreeTypes; let i = index"
                [cdkDragData]="type.type" cdkDrag [cdkDragDisabled]="isDegreeMaxReached(type) || view!='new'"  >
                <div class="drag-handle" cdkDragHandle>
                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                        <path
                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                        </path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                </div>
                <i class="fas fa-check-circle  checked-item" aria-hidden="true"  *ngIf="hasType(type.type)"></i>
                <span   *ngIf="type.title!='بدون شهاده'">{{'شهادة '+type.title}}</span>
                <span   *ngIf="type.title=='بدون شهاده'">{{type.title}}</span>
            </div>
        </div>
    </div>
</div>
