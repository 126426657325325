import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class RoutingInfoService {
    private routeSubject: BehaviorSubject<string>;
    constructor(private router: Router) {
        this.routeSubject = new BehaviorSubject<string>(this.getRoute());

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.routeSubject.next(this.getRoute());
            });
    }

    getRoute(): string {
        const url = this.router.url;
        const segments = url.split("/");
        const firstSegment = segments[1];
        return firstSegment;
    }

    getRouteObservable(): Observable<string> {
        return this.routeSubject.asObservable();
    }
}
