import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpLoadingService } from "../shared/http-loading.service";

@Injectable({
    providedIn: "root",
})
export class FacultyPromotionService {
    private url = environment.ApiUrl + "/faculty-promotions/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    findAll(id: string): Observable<any> {
        return this.http.get(
            this.url + id + `?cache_bust=${new Date().getTime()}`
        );
    }
    create(userId: string, data: any): Observable<any> {
        return this.http.post(this.url + userId, data);
    }

    update(promotionId: string, data: any): Observable<any> {
        return this.http.patch(this.url + promotionId, data);
    }

    remove(promotionId: string) {
        return this.http.delete(this.url + promotionId);
    }

    removeFile(promotionId: string, data: any) {
        return this.http.patch(this.url + "removefile/" + promotionId, data);
    }
}
