import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor/editor.component';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
    declarations: [EditorComponent],
    imports: [CommonModule, BrowserModule, FormsModule,ReactiveFormsModule, EditorModule],
    exports:[EditorComponent],
})
export class LocalEditorModule {}
