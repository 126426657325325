import { Component, OnInit } from "@angular/core";
import { HttpLoadingService } from "src/app/services/shared/http-loading.service";

@Component({
    selector: "app-system-manager",
    templateUrl: "./system-manager.component.html",
    styleUrls: ["./system-manager.component.scss"],
})
export class SystemManagerComponent implements OnInit {
    

    constructor(private loadingService:HttpLoadingService) {}

    ngOnInit(): void {
        this.loadingService.Title="مدير ألنظام"
    }

}
