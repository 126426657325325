import { Files } from "../../../classes/Files";

export class EmploymentInfo {
    employmentNumber: string = "";
    hireDate: Date = new Date();
    hireBooknumber: string = "";
    hireStartDate: Date = new Date();

    isEmployeedPreviously: boolean = false;
    movingToMohDate: Date = new Date();
    movingToMohStartDate: Date = new Date();
    movingToMohBooknumber: string = "";

    isRehired: boolean = false;
    rehireDate: Date = new Date();
    rehireStartDate: Date = new Date();
    rehireDocumentNumber: string = "";

    employmentType: string = "دائمي";

    employeeStatus: string = "مستمر";
    terminationDate: Date = new Date();
    terminationBooknumber: string = "";
    notes: string = "";

    EmploymentFiles: Files = new Files();
    TerminationFiles: Files = new Files();
    employmentfiles: any[] = [];
    terminationfiles: any = [];

    toFormData(): FormData {
        var data = new FormData();
        data.append("employmentNumber", this.employmentNumber);
        data.append("hireDate", this.hireDate.toISOString());
        data.append("hireStartDate", this.hireStartDate?.toString());
        data.append("hireBooknumber", this.hireBooknumber);
        data.append(
            "isEmployeedPreviously",
            this.isEmployeedPreviously.toString()
        );
        if (this.isEmployeedPreviously) {
            data.append("movingToMohDate", this.movingToMohDate?.toString());
            data.append(
                "movingToMohStartDate",
                this.movingToMohStartDate?.toString()
            );
            data.append("movingToMohBooknumber", this.movingToMohBooknumber);
        }

        data.append("isRehired", this.isRehired.toString());
        if (this.isRehired) {
            data.append("rehireDate", this.rehireDate?.toString());
            data.append("rehireStartDate", this.rehireStartDate?.toString());
            data.append("rehireDocumentNumber", this.rehireDocumentNumber);
        }
        data.append("employmentType", this.employmentType);
        data.append("employeeStatus", this.employeeStatus);
        if (this.employeeStatus != "مستمر") {
            data.append("terminationDate", this.terminationDate?.toString());
            data.append("terminationBooknumber", this.terminationBooknumber);
        }

        data.append("notes", this.notes);

        this.EmploymentFiles.Filelist.forEach((file) => {
            data.append("employmentfiles", file, encodeURIComponent(file.name));
        });

        this.TerminationFiles.Filelist.forEach((file) => {
            data.append(
                "terminationfiles",
                file,
                encodeURIComponent(file.name)
            );
        });
        return data;
    }
}
