import {
    Component,
    OnDestroy,
    OnInit,

} from "@angular/core";
import {
    AbsenseVacation,
    CareVacation,
    CompanionshipVacation,
    DisabilityVacation,
    FiveYearsVacation,
    NormalLongVacation,
    SickLeaveVacation,
    StudyVacation,
    TimeOff,
    UnpaidVacation,
    Vacation,
} from "src/app/models/hr/employee/vacations";

import { VacationUtilService } from "./vacation-util.service";

@Component({
    selector: "app-vacations",
    templateUrl: "./vacations.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class VacationsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

@Component({
    selector: "vacation-sick-leave",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class SickLeaveVacationsComponent implements OnInit {
    type: string = "مرضيه";
    view: string = "list";
    title: string = "ألأجازات ألمرضيه";

    vacation: SickLeaveVacation = new SickLeaveVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new SickLeaveVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

@Component({
    selector: "vacation-unpaid",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class UnpaidVacationsComponent implements OnInit {
    type: string = "بدون راتب";
    view: string = "list";
    title: string = "ألأجازات بدون راتب";

    vacation: UnpaidVacation = new UnpaidVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new UnpaidVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

@Component({
    selector: "vacation-five-years",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class FiveYearsVacationsComponent implements OnInit {
    type: string = "خمس سنوات";
    view: string = "list";
    title: string = "أجازة خمس سنوات";

    vacation: FiveYearsVacation = new FiveYearsVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new FiveYearsVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

@Component({
    selector: "vacation-normal-short",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class NormalShortVacationsComponent implements OnInit, OnDestroy {
    type: string = "أعتياديه قصيره";
    view: string = "list";
    title: string = "ألاجازات الاعتيادية ألقصيره";

    vacation: Vacation = new Vacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new Vacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

@Component({
    selector: "vacation-normal-long",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class NormalLongVacationsComponent implements OnInit {
    type: string = "أعتياديه طويله";
    view: string = "list";
    title = "ألاجازات ألاعتيادية ألطويله";

    vacation: NormalLongVacation = new NormalLongVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        
        this.vacation = new NormalLongVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                new NormalLongVacation(this.type),
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

@Component({
    selector: "vacation-normal-long",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class StudyVacationsComponent implements OnInit {
    type: string = "دراسيه";
    view: string = "list";
    title: string = "ألأجازات ألدراسيه";

    vacation: StudyVacation = new StudyVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new StudyVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

@Component({
    selector: "companionship-vacation-long",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class CompanionshipVacationComponent implements OnInit {
    type: string = "مصاحبه زوجيه";
    view: string = "list";
    title: string = "أجازات ألمصاحبه ألزوجيه";

    vacation: CompanionshipVacation = new CompanionshipVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new CompanionshipVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

@Component({
    selector: "care-vacation-long",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class CareVacationComponent implements OnInit {
    type: string = "أعاله";
    view: string = "list";
    title: string = "أجازات ألأعاله";

    vacation: CareVacation = new CareVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new CareVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}



@Component({
    selector: "disabiliry-vacation-long",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class DisabilityVacationComponent implements OnInit {
    type: string = "عجز صحي";
    view: string = "list";
    title: string = "أجازات  ألعجز ألصحي";

    vacation: DisabilityVacation = new DisabilityVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new DisabilityVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

@Component({
    selector: "absense-vacation-long",
    templateUrl: "./templates/vacation.view.component.html",
    styleUrls: ["./vacations.component.scss"],
})
export class AbsenseVacationComponent implements OnInit {
    type: string = "غياب";
    view: string = "list";
    title: string = " ألغيابات";

    vacation: AbsenseVacation = new AbsenseVacation(this.type);
    constructor(private vacationUtilService: VacationUtilService) {}

    ngOnInit(): void {
        this.vacationUtilService.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.vacation = new AbsenseVacation(this.type);
        if (view == "edit") {
            this.vacation = Object.assign(
                this.vacation,
                this.vacationUtilService.SelectedVacation
            );
        }
        this.view = view;
    }
    ngOnDestroy(): void {
        this.vacationUtilService.getViewEvent().subscribe();
    }
}

