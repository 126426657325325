import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DateUtil } from "src/app/classes/Utils";
import { BasicInfoUtilService } from "src/app/hr/employee/bacic-info/basicinfo-util-service";
import { User } from "src/app/models/hr/employee/user";
import { EmployeeSummariesService } from "src/app/services/employee/employee-summaries.service";

import { UsersService } from "src/app/services/users/users.service";
import { WorkplaceUtilService } from "src/app/services/util/workplace.service";

@Component({
    selector: "app-service-summary",
    templateUrl: "service.summary.html",
})
export class EmployeeServiceSummaryComponent implements OnInit {
    @Input() userId: string;
    currentDate: string = new Date().toString();
    public info: any = null;
    awards: any = null;
    punishments: any = null;
    absenseVacations: any = null;
    fiveYearsVacations: any = null;
    unpaidVacations: any = null;
    longVacations: any = null;
    inMohServiceDuration: any = DateUtil.findYearMonthsDaysObject(0);
    outOfMohServiceDuration: any = DateUtil.findYearMonthsDaysObject(0);
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private employeeSummariesService: EmployeeSummariesService,
        private workplaceUtilSevice: WorkplaceUtilService
    ) {}
    ngOnInit() {
        this.getSummary();
    }

    getSummary() {
        this.employeeSummariesService
            .findGeneralSummary(this.userId)
            .subscribe((response) => {
                this.info = response[0];
                console.log(this.info);
                if(this.info.degree)
                    this.info.degree.issuer =
                        this.workplaceUtilSevice.convertToTree(
                            this.info.degree.issuer
                        );
                if(this.info.workplace){
                    var workplaces = this.workplaceUtilSevice.getBranchesList(
                        this.info.workplace
                    );
                    this.info.workplace =
                    this.workplaceUtilSevice.convertToTree(workplaces);
                }



                this.awards = this.getAwardsPunishments("شكر");
                this.punishments = this.getAwardsPunishments("عقوبه");
                this.absenseVacations = this.getVacationByType("غياب");
                this.unpaidVacations = this.getVacationByType("بدون راتب");
                this.fiveYearsVacations = this.getVacationByType("خمس سنوات");
                this.longVacations = this.getVacationByType("أعتياديه طويله");
                this.inMohServiceDuration =
                    this.findInOutMohServiceDuration(true);
                this.outOfMohServiceDuration =
                    this.findInOutMohServiceDuration(false);

                this.getContractService();

                if (this.info.firstPlace) {
                    this.info.firstPlace =
                        this.workplaceUtilSevice.convertToTree(
                            this.info.firstPlace.fullname.split("-")
                        );
                }
            });
    }
    getAwardsPunishments(type: string) {
        return this.info.awardspunishments.find((item) => item._id == type);
    }

    findInOutMohServiceDuration(type: boolean) {
        var minstryDuration = this.info.employeeWorkplaces.ministryGroup.find(
            (item) => item._id == type
        );
        if (minstryDuration) {
            return DateUtil.findYearMonthsDaysObject(minstryDuration.duration);
        }
        return DateUtil.findYearMonthsDaysObject(0);
    }

    getVacationByType(type: string) {
        var vacation = this.info.vacations.typeGroup.find(
            (item) => item._id == type
        );
        if (vacation) {
            vacation.durationObject = DateUtil.findYearMonthsDaysObject(
                vacation.duration
            );
        }
        return vacation;
    }
    contractServiceDuration: any = DateUtil.findYearMonthsDaysObject(0);
    getContractService() {
        var contractDuration = this.info.employeeWorkplaces.employmentType.find(
            (item) => item._id == "عقد"
        );
        if (contractDuration) {
            this.contractServiceDuration = DateUtil.findYearMonthsDaysObject(
                contractDuration.duration
            );
        }
    }

    print() {
        window.print();
    }
    savePdf() {}
}
