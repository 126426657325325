<div class="card">
    <div class="card-header">
        <h4 *ngIf="Item._id"><i class="fa fa-school  px-2"></i> تعديل مكان ألعمل</h4>
        <h4 *ngIf="!Item._id"><i class="fa fa-school  px-2"></i> أضافة مكان ألعمل</h4>
        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
            <button class="btn btn-print" title="أختر جهة ألعمل ألمنتسب أليها سابقا"
                 *ngIf="Item.isMoh &&University.Name!=type" (click)="openMinstryDialog();">
                <i class="fa fa-university mx-2"></i> 
            </button>
            <button class="btn btn-print "  title="أختر جهة ألعمل ألمنتسب أليها سابقا"
                *ngIf="Item.isMoh &&University.Name==type" (click)="openUniversityDialog();">
                <i class="fa fa-university mx-2"></i> 
            </button>
            <button (click)="submit();" class="btn btn-print" title="حفظ ألمعلومات ">
                <i class="fa fa-save fa-lg"></i>
            </button>
            <button class="btn btn-print" title="رجوع ألى ألقائمه"
                (click)="this.viewHandler.changeViewEvent('list')">
                <i class="fa fa-share fa-lg"></i>
            </button>

        </div>
    </div>
    <div class="card-body">

            <div class="row">
                <div class="col-md-8 py-0" >
                    <label class=" ">ملاك ألموظف : </label>
                    <div class="d-flex justify-content-between border px-3 border-radius-25 bg-white" >
                        <div class="icheck-greensea pt-1 " *ngFor="let type of employmentTypes;let i=index">
                            <input class="box" type="radio" name="employmentTypes"
                                [(ngModel)]="Item.employmentType" [value]="type"
                                id="employmentTypes_{{i}}">
                            <label for="employmentTypes_{{i}}"> {{type}} </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class=" "> نوع ألأنتساب : </label>
                    <div class="d-flex justify-content-between border px-2 border-radius-25 bg-white" >
                        <div class="icheck-greensea  my-2 ">
                            <input class="box" type="radio" name="move" [(ngModel)]="Item.movementType"
                                value="دائمي" id="move_1">
                            <label for="move_1" class="px-2"> دائمي </label>
                        </div>
                        <div class="icheck-greensea  my-2 ">
                            <input class="box" type="radio" name="move" [(ngModel)]="Item.movementType"
                                value="تنسيب" id="move_2">
                            <label for="move_2" class="px-2"> تنسيب </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="Item.isMoh &&University.Name==type">
                <div class="col-md-8"  >
                    <div class="border px-3 border-radius-25 bg-white" *ngIf="Item.fullname.length">
                        <app-workplace-tree-view  [treeData]="treeData"></app-workplace-tree-view>
                    </div>
                    
                </div>

            </div>
            <div class="row" *ngIf="Item.isMoh &&University.Name!=type">
                <div class="col-md-8"  >
                    <div class="border px-3 border-radius-25 bg-white" *ngIf="Item.fullname.length">
                        <app-workplace-tree-view  [treeData]="treeData"></app-workplace-tree-view>
                    </div>  
                </div>
            </div>
            <div class="row" *ngIf="!Item.isMoh">
                <div class="col-md-8"  >
                    <app-workplace-entry-view  [Data]="treeData" [titles]="titles" (dataChange)="onDataChange()"></app-workplace-entry-view> 
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <app-datepicker [input]="Item.fromDate" [name]="'startDate'"
                        [label]="'تاريخ ألمباشره'" (dateChange)="Item.fromDate=$event">
                    </app-datepicker>
                </div>
                <div class="col-md-4">
                    <app-datepicker [input]="Item.fromDocumentDate" [name]="'fromDocumentDate'"
                        [label]="'تاريخ ألأمر ألأداري بالمباشره '" (dateChange)="Item.fromDocumentDate=$event">
                    </app-datepicker>
                </div>
                <div class="col-md-4">
                    <label for="fromDocumentNumber" class="form-label">  رقم ألامر ألأداري باالمباشره:</label>
                    <div class="input-group">
                        <input type="text" id="fromDocumentNumber" name="fromDocumentNumber" #fromDocumentNumber="ngModel" class="form-control text-center"
                            [(ngModel)]="Item.fromDocumentNumber">
                            <div class="input-group-prepend">
                                <span class="input-group-text font-bold">
                                   #
                                </span>
                            </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <app-datepicker [input]="Item.toDate" [name]="'endDate'"
                        [label]="'تاريخ ألأنفكاك'" (dateChange)="Item.toDate=$event">
                    </app-datepicker>
                </div>

                <div class="col-md-4">
                    <app-datepicker [input]="Item.toDocumentDate" [name]="'toDocumentDate'"
                        [label]="'تاريخ ألأمر ألأداري بالأنفكاك '" (dateChange)="Item.toDocumentDate=$event">
                    </app-datepicker>
                </div>
                <div class="col-md-4">
                    <label for="toDocumentNumber" class="form-label">  رقم ألامر ألأداري بالأنفكاك:</label>
                    <div class="input-group">
                        <input type="text" id="toDocumentNumber" name="toDocumentNumber" #toDocumentNumber="ngModel" class="form-control text-center"
                            [(ngModel)]="Item.toDocumentNumber">
                            <div class="input-group-prepend">
                                <span class="input-group-text font-bold">
                                   #
                                </span>
                            </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
                        <mat-label class=" px-3">ألملاحضات </mat-label>
                        <textarea matInput placeholder="" rows="3" class="py-0" [(ngModel)]="Item.notes"></textarea>
                        <mat-icon matSuffix class="text-secondary ">comment</mat-icon>
                    </mat-form-field>
                </div>
            </div>


            
            <div class="row">
                <div class="col-md-12">
                    <app-files [Files]="Item.Files" [uploadedFiles]="Item.files"
                        (fileDelete)="removeFile(Item._id,$event)" [header]="'ألأوامر ألأدارية ألخاصة بالمباشره وألأنفكاك'">
                    </app-files>
                </div>
            </div>
    </div>
</div>