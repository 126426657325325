import { FlatTreeControl } from "@angular/cdk/tree";
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
    TemplateRef,
} from "@angular/core";
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
} from "@angular/material/tree";
import { NodeDatabaseService } from "./service";
import { StructureService } from "../../services/system-manager/structure-http-service";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { UsersService } from "src/app/services/users/users.service";
import { User } from "src/app/models/hr/employee/user";

import { ItemFlatNode, ItemNode } from "src/app/models/system/structure";
import { MatDialog } from "@angular/material/dialog";
import {
    ConfirmationDialogComponent,
    StructureDialogComponent,
} from "src/app/shared-components/dialogs/dialogs";
import { Router } from "@angular/router";
import { UniversityService } from "src/app/services/data-persistence/university-info";
import { BehaviorSubject } from "rxjs";
import { DataPersistanceService } from "src/app/services/data-persistence/data-persistance";

@Component({
    selector: "organization-structure",
    templateUrl: "organization-structure.html",
    styleUrls: ["organization-chart.component.scss"],
    providers: [NodeDatabaseService],
})
export class OrganizationStructuresComponent implements OnInit {
    constructor(public university: UniversityService) {}
    ngOnInit(): void {}
}

@Component({
    selector: "organization-chart",
    templateUrl: "organization-chart.component.html",
    styleUrls: ["organization-chart.component.scss"],
})
export class MinistryStructureComponent implements OnInit {
    isExpanded: boolean = false;
    flatNodeMap = new Map<ItemFlatNode, ItemNode>();
    nestedNodeMap = new Map<ItemNode, ItemFlatNode>();
    selectedParent: ItemFlatNode | null = null;
    newItemName = "";

    treeControl: FlatTreeControl<ItemFlatNode>;
    treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;
    dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;
    SelectedItem: any = null;
    targetData: BehaviorSubject<ItemNode[]>;
    title: string = "";

    constructor(
        private structureService: StructureService,
        public _database: NodeDatabaseService,
        public router: Router,
        public dialog: MatDialog,
        public university: UniversityService,
        public dataPersistant: DataPersistanceService
    ) {
        this.initializeTree();
        this.setTargetData();
        this.dataChangeSubscriber();
    }

    setTargetData() {
        this._database.query = {
            parent: null,
        };
        this.targetData = this._database.minstryDataChange;
    }

    dataChangeSubscriber() {
        this._database.initialize(this.targetData);
        this.targetData.subscribe((data) => {
            this.dataSource.data = data;
            var parentNode = this.treeControl.dataNodes[0];
            this.treeControl.expand(parentNode);
        });
        this._database.nodeUpdate.subscribe((node) => {
            this.treeControl.collapseAll();
            if (node) {
                const flatNode = this.nestedNodeMap.get(node);
                this.expandParents(flatNode);
            }
        });
    }

    async ngOnInit() {
        this.title = "وزارة ألتعليم ألعالي وألبحث ألعلمي";
    }

    toggleTree() {
        this.isExpanded == true
            ? this.treeControl.collapseAll()
            : this.treeControl.expandAll();
        this.isExpanded = !this.isExpanded;
    }

    initializeTree() {
        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );

        this.treeControl = new FlatTreeControl<ItemFlatNode>(
            this.getLevel,
            this.isExpandable
        );
        this.dataSource = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );
    }

    expandParents(flatNode: ItemFlatNode) {
        if (flatNode) {
            var parentFlatNode = this.getParentNode(flatNode);
            this.expandParents(parentFlatNode);
        }
        this.treeControl.expand(flatNode);
    }

    getLevel = (node: ItemFlatNode) => node.level;
    isExpandable = (node: ItemFlatNode) => node.expandable;
    getChildren = (node: ItemNode): ItemNode[] => node.children;
    hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;
    hasNoContent = (_: number, _nodeData: ItemFlatNode) =>
        _nodeData.name === "";

    transformer = (node: ItemNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.name === node.name
                ? existingNode
                : new ItemFlatNode();
        flatNode.name = node.name;
        flatNode.type = node.type;
        flatNode.level = level;
        flatNode._id = node._id;
        flatNode.expandable = !!node.children?.length;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    getParentNode(node: ItemFlatNode): ItemFlatNode | null {
        const currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.findIndex(
            (n) => n._id == node._id
        );
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];
            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    addNewItem(node: ItemFlatNode, type: { name: string; title: string }) {
        const parentNode = this.flatNodeMap.get(node);
        this._database.insertItem(parentNode!, type.name, this.targetData);
        this.treeControl.expand(node);
    }

    saveNode(node: ItemFlatNode, itemValue: string) {
        var parentFlatNode = this.getParentNode(node);
        const parentNode = this.flatNodeMap.get(parentFlatNode);
        const nestedNode = this.flatNodeMap.get(node);
        this._database.updateItem(
            nestedNode!,
            itemValue,
            parentNode,
            this.targetData
        );
    }

    deleteNode(node: ItemFlatNode) {
        this.SelectedItem = node;

        this.structureService
            .getEmployeesCount(node._id)
            .subscribe((response) => {
                if (response == 0) {
                    this.removeWorkplace();
                } else {
                    this.deleteDeniedDialog();
                }
            });
    }

    confirmDelete() {
        const nestedNode = this.flatNodeMap.get(this.SelectedItem);
        this._database.deleteItem(nestedNode, this.targetData);
    }

    isUnit(node: ItemFlatNode) {
        return node.type == "وحدة";
    }

    deleteAll() {
        this.structureService.removeAll().subscribe((response) => {});
    }

    view: string = "tree";
    selectedNode: ItemFlatNode;

    changeView(view: string, node: ItemFlatNode): void {
        this.view = view;
        this.selectedNode = node;
    }

    updateTree() {
        this.view = "tree";
    }

    deleteDeniedDialog() {
        this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title: ` طلب مرفوض`,
                warningMessage: `<p class="text-justify">
                لا يمكن حذف ${this.SelectedItem.name} لأنها تضم  مجموعة
                من ألموظفين . لغرض ألحذف بشكل نهائي يجب تحويل جميع ألموظفين
                ألموجودين ألى جهة أخرى .
                </p>`,
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });
    }

    removeWorkplace() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title: ` حذف ${this.SelectedItem.name} `,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات ${this.SelectedItem.name}  بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage: "هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.confirmDelete();
            }
        });
    }
}

@Component({
    selector: "university-chart",
    templateUrl: "organization-chart.component.html",
    styleUrls: ["organization-chart.component.scss"],
})
export class UniversityStructureComponent
    extends MinistryStructureComponent
    implements OnInit
{
    override async ngOnInit() {
        this.title = this.university.Name;
    }
    override setTargetData() {
        this._database.query = {
            name: this.university.Name,
        };
        this.targetData = this._database.universityDataChange;
    }
}

@Component({
    selector: "organization-entity-form",
    templateUrl: "organization-entity-form.html",
})
export class OrganizationEntityForm implements OnInit {
    @Input() node: ItemFlatNode;
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    view: string = "list";
    manager: User = new User();
    changeableEntities: string[] = ["شعبه", "وحده"];
    mainEntities: string[] = [
        "رئاسة ألجامعة",
        "كلية",
        "مركز بحثي",
        "مركز خدمي",
    ];

    Item: ItemNode = new ItemNode();

    SelectedUser: any = null;

    constructor(
        private structureService: StructureService,
        private userService: UsersService
    ) {}
    ngOnInit() {
        this.findOne();
    }

    findOne() {
        this.structureService.findOne(this.node._id).subscribe((response) => {
            this.Item = Object.assign(this.Item, response);
            console.log(response.managerId);
            if (response.managerId) {
                this.findManager(response.managerId);
            }
        });
    }

    findManager(managerId: string) {
        this.userService.findOne(managerId).subscribe((response) => {
            this.manager = Object.assign(new User(), response);
        });
    }

    submit() {
        this.structureService
            .update(this.node._id, this.Item)
            .subscribe((response) => {
                this.viewChange.emit("list");
            });
    }

    isUnitOrDivision() {
        return this.Item.type == "وحده" || this.Item.type == "شعبه";
    }

    isCollege() {
        return this.mainEntities.indexOf(this.Item.type) > -1;
    }
}

@Component({
    selector: "organization-users",
    templateUrl: "organization-users.html",
})
export class OrganizationUsers implements AfterViewInit {
    @Input() node: ItemFlatNode;
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();

    users: any[] = [];
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    view: string = "list";
    Item: ItemNode = new ItemNode();
    SelectedUser: any = null;

    constructor(
        private structureService: StructureService,
        private userService: UsersService,
        private dialog: MatDialog,
        public router: Router
    ) {}

    async ngAfterViewInit() {
        this.dt.setDtElement(this.dtElement);
        this.findEmployees();
        this.dt.trigger();
    }

    findEmployees() {
        this.userService
            .findWorkPlaceEmployees(this.node._id)
            .subscribe((response) => {
                this.users = response;
                this.view = "list";
                this.dt.reload();
            });
    }

    newOrEdit(view: string) {
        this.view = view;
    }

    changeManager() {
        const dialogRef = this.dialog.open(StructureDialogComponent, {
            width: "61vw",
            enterAnimationDuration: "500ms",
            exitAnimationDuration: "500ms",

            position: {
                top: "65px",
            },
            data: {
                title: `تعيين ${this.SelectedUser.fullname} كمسؤول اداري  لــ`,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.structureService
                .updateManager(result._id, { managerId: this.SelectedUser._id })
                .subscribe((response) => {});
        });
    }
}
