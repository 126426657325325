<div class="wrapper">
    <div class="contents">
        <div class="container">
            <app-page-header-info id="structure-headers" title2="ألهياكل ألتنظيمية"></app-page-header-info>
            <div class="content-container my-7 mx-md-4">
                <router-outlet></router-outlet>
            </div>

        </div>
    </div>
    <div class="sidebar left-sidebar border-right" id="structure-routes">
        <ul class="list-unstyled mb-10 ">
            <li class="border-top-0">
                <a routerLink="university" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i>  {{university.Name}}
               </a>
            </li>
            <li>
                <a routerLink="minstry" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> وزارة ألتعليم ألعالي
               </a>
            </li>
        </ul>
    </div>
</div>
