import { AfterViewInit, Component, Input, OnInit,ChangeDetectorRef } from "@angular/core";
import { HttpLoadingService } from "src/app/services/shared/http-loading.service";
declare var $:any;
@Component({
    selector: "app-page-header-info",
    templateUrl: "./page-header-info.component.html",
})
export class PageHeaderInfoComponent implements OnInit , AfterViewInit{
    
    @Input() title2: string = "ألاجازات";

    @Input() icon:string="fa-user-tie"
    
    constructor(public loadingService:HttpLoadingService,private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        $(document).ready(function () {
            $("#right-sidebar-collapse").on("click", function () {
                $(".right-sidebar").toggleClass("active");
                $(".contents").toggleClass("blur");
                $("#right-sidebar-collapse i").toggleClass("fa-flip-horizontal");
            });
            $("#left-sidebar-collapse").on("click", function () {
                $(".left-sidebar").toggleClass("active");
                $("#left-sidebar-collapse i").toggleClass("fa-flip-horizontal");
            });

            $(".close-sidebar-button").on("click", function () {
                $(".right-sidebar").removeClass("active");
                $(".left-sidebar").removeClass("active");
                $(".contents").removeClass("blur");
            })
        })
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.cdr.detectChanges(); // Trigger a change detection cycle
        });  
    }
}
