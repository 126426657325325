import { formatDate } from "@angular/common";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DateUtil } from "src/app/classes/Utils";
import { GradeService } from "src/app/services/employee/grades-bonuses.service";
import { createPdf } from "pdfmake/build/pdfmake";
import { pdfMake } from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import { EmployeeSummariesService } from "src/app/services/employee/employee-summaries.service";
import { WorkplaceUtilService } from "src/app/services/util/workplace.service";

@Component({
    selector: "app-upgrade-summary",
    templateUrl: "./upgrade-summary.component.html",
})
export class UpgradeSummaryComponent implements OnInit {
    lastGrade: any = null;
    lastBonus: any = null;
    currentDate: string = new Date().toString();
    @ViewChild("content", { static: false }) content!: ElementRef;

    public info: any = null;
    public userId: string;
    awards:any=null;
    punishments:any=null;
    absenseVacations:any=null;
    fiveYearsVacations:any=null;
    unpaidVacations:any=null;
    longVacations:any=null;
    constructor(
        private Activeroute: ActivatedRoute,
        private employeeSummariesService: EmployeeSummariesService,
        private workplaceUtilSevice: WorkplaceUtilService
    ) {}

    async ngOnInit() {
        this.userId = await this.Activeroute.parent.parent.snapshot.params[
            "userId"
        ];
        this.getSummary();
    }
    getSummary() {
        this.employeeSummariesService
            .findGeneralSummary(this.userId)
            .subscribe((response) => {
                this.info = response[0];
                this.info.degree.issuer=this.workplaceUtilSevice.convertToTree(this.info.degree.issuer)
                var workplaces = this.workplaceUtilSevice.getBranchesList(
                    this.info.workplace
                );
                this.info.workplace=this.workplaceUtilSevice.convertToTree(workplaces);
                this.awards= this.getAwardsPunishments('شكر');
                this.punishments= this.getAwardsPunishments('عقوبه');
                this.absenseVacations=this.getVacationByType('غياب');
                this.unpaidVacations=this.getVacationByType('بدون راتب');
                this.fiveYearsVacations=this.getVacationByType('خمس سنوات');
                this.longVacations=this.getVacationByType('أعتياديه طويله');

            });
    }
    getAwardsPunishments(type:string){
        return this.info.awardspunishments.find(item=>item._id==type)
    }

    getVacationByType(type:string){
        var vacation= this.info.vacations.typeGroup.find(item=>item._id==type);
        if(vacation){
            vacation.durationObject=DateUtil.findYearMonthsDaysObject(vacation.duration)
        }
        return vacation;
    }



    print() {
        window.print();
    }
    savePdf() {}

    noDataMessage =
        "لم يتم أضافة أي علاوات أو درجات وظيفية خاصة بهذا ألموظف . لغرض أضافة درجة جديده أذهب ألى جدول ألدرجات ألوظيفية في ألقائمة ألموجوده على أليسار وقم بأضافة ألدرجات ألوظيفية وألعلاوات";
}
