import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { HttpLoadingService } from "../../shared/http-loading.service";

@Injectable({
    providedIn: "root",
})
export class VacationsService {
    private url = environment.ApiUrl + "/vacations/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    findAll(userId: string,type:string,isCurrentDate:boolean): Observable<any> {
        return this.http.get(
            this.url + userId + `?cache_bust=${new Date().getTime()}`,
            {
                params:{type:type,isCurrentDate}
            }
        );
    }
    create(userId: string, data: any): Observable<any> {
        return this.http.post(this.url + userId, data);
    }

    update(timeOffId: string, data: any): Observable<any> {
        return this.http.patch(this.url + timeOffId, data);
    }

    filter(userId: string, type:string, data: any): Observable<any> {
        
        return this.http.post(
            this.url +
                "filter/" +
                userId +
                `?cache_bust=${new Date().getTime()}`,
            data,
            {
                params:{type:type}
            }
        );
    }

    remove(timeOffId: string) {
        return this.http.delete(this.url + timeOffId);
    }
    removeFile(Id: string, data: any) {
        return this.http.patch(this.url + "removefile/" + Id, data);
    }
}
