import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { User } from "src/app/models/hr/employee/user";
import { UsersService } from "src/app/services/users/users.service";
import { emailDomain, MustMatch } from "src/app/_helper/must-match.validator";
import { EmployeeWorkplacesService } from "src/app/services/employee/employee-workplaces";
import { StructureService } from "src/app/services/system-manager/structure-http-service";
import { MinstryWorkplace } from "src/app/models/hr/employee/workplaces";
import { WorkplaceUtilService } from "src/app/services/util/workplace.service";
@Component({
    selector: "app-user-form",
    templateUrl: "User-form.html",
})
export class UserEntityForm implements OnInit {
    @Input() entityId: string;
    @Input() userId: string;
    @Input() action: string = "new";

    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    User: User = new User();

    isSubmmited: boolean = false;

    passwordForm: UntypedFormGroup;
    userForm: UntypedFormGroup;
    MainWorkplace: MinstryWorkplace = new MinstryWorkplace();

    constructor(
        private userService: UsersService,
        private employeeWorkplaceService:EmployeeWorkplacesService,
        private workplaceUtilService:WorkplaceUtilService,
        private structureService: StructureService,
        private formBuilder: FormBuilder
    ) {}
    ngOnInit() {
        if (this.userId) {
            this.findEmployee();
        }
        this.getWorkplace();
        this.userForm = this.formBuilder.group({
            fullname: ["", [Validators.required, Validators.minLength(6)]],
            email: ["", [Validators.required, Validators.email, emailDomain]],
        });
        this.passwordForm = this.formBuilder.group(
            {
                password: ["", [Validators.required, Validators.minLength(6)]],
                confirmPassword: ["", Validators.required],
            },
            {
                validator: MustMatch("password", "confirmPassword"),
            }
        );
    }

    getWorkplace() {
        this.structureService
            .findOne(this.entityId)
            .subscribe((response) => {
                this.MainWorkplace.fullname =
                    this.workplaceUtilService.getFullPath(response);
                this.MainWorkplace.workplaceId = this.entityId;
                console.log(this.MainWorkplace)
                this.MainWorkplace.mainWorkplace=true;
            });
    }

    get f() {
        return this.passwordForm.controls;
    }
    get u() {
        return this.userForm.controls;
    }
    findEmployee() {
        this.userService.findOne(this.userId).subscribe((response) => {
            this.User = Object.assign(this.User, response);
        });
    }

    submit() {
        this.isSubmmited = true;

        this.User.workplace = this.entityId;
        if (this.userId == null) {
            if (
                this.passwordForm.valid == false ||
                this.userForm.valid == false
            ) {
                return;
            }
            this.User.password = this.passwordForm.get("password").value;
            this.User.fullname = this.userForm.get("fullname").value;
            this.User.email = this.userForm.get("email").value;
            this.userService.create(this.User).subscribe((response) => {
                this.createWorkplace(response.user._id)
            });
        } else {
            if (this.action == "password") {
                if (this.passwordForm.valid == false) {
                    return;
                }
                this.User.password = this.passwordForm.get("password").value;
            } else {
                if (this.userForm.valid == false) {
                    return;
                }

                delete this.User.password;
            }
            this.userService
                .update(this.userId, this.User)
                .subscribe((response) => {
                    this.viewChange.emit(true);
                });
        }
    }

    createWorkplace(userId:string){
        console.log(this.MainWorkplace)
        this.employeeWorkplaceService.create(userId,this.MainWorkplace.toFormData()).subscribe((response)=>{
            this.viewChange.emit(true);
        })

    }
}
