import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild,
    Output,
    EventEmitter,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { DegreeUtilService } from "./degree-util-service";
import {
    ElementryDegreeForm,
    IntermediateDegreeForm,
    HisghschoolDegreeForm,
    UndergraduateDegreeForm,
    GraduateDegreeForm,
    NoDegree,
} from "../../../models/hr/employee/degrees";
import { ConstantsUtils, Branch } from "../../../classes/constants";
import { DgreeService } from "src/app/services/employee/dgrees.service";
import { ActivatedRoute } from "@angular/router";
import {
    ConfirmationDialogComponent,
    PreloadedStructureDialogComponent,
} from "src/app/shared-components/dialogs/dialogs";
import { MatDialog } from "@angular/material/dialog";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "../../../classes/datatable";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { VacationsService } from "src/app/services/employee/vacations/vacations.service";
import { StudyVacation } from "src/app/models/hr/employee/vacations";
import { DataPersistanceService } from "src/app/services/data-persistence/data-persistance";
import { StructureService } from "src/app/services/system-manager/structure-http-service";
declare var $: any;
@Component({
    selector: "app-degree-info",
    templateUrl: "./degree-info.component.html",
})
export class DegreeInfoComponent implements AfterViewInit {
    userId: string = null;
    SelectedDegree: any = null;
    SelectedIndex: number = null;

    view: string = "list";
    degrees: any[] = [];

    Items: any[] = [];

    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();

    constructor(
        public degreeUtilService: DegreeUtilService,
        private degreeHttpService: DgreeService,
        private Activeroute: ActivatedRoute,
        private dialog: MatDialog
    ) {}

    async ngAfterViewInit() {
        this.userId = await this.Activeroute.parent.snapshot.params["userId"];
        this.dt.setDtElement(this.dtElement);
        this.getEmployeeDegrees();
        this.dt.trigger();
    }

    getEmployeeDegrees() {
        this.degreeHttpService.findAll(this.userId).subscribe((response) => {
            this.Items = response;
            this.dt.reload();
            this.view = "list";
        });
    }

    newOrEdit(view: string) {
        if (view == "edit") {
            this.SelectedDegree = this.degreeUtilService.jsonToDegreeObject(
                this.SelectedDegree
            );

            this.SelectedDegree.degreeDate = new Date(
                this.SelectedDegree.degreeDate
            );
        }
        this.view = view;
    }

    hasType(type: string) {
        return this.Items.findIndex((item) => item.type == type) > -1;
    }
    isDegreeMaxReached(type: any) {
        if (type.title == "بدون شهاده") {
            return (
                this.Items.filter(
                    (item) => item.type == "أمي" || item.type == "يقرأ ويكتب"
                ).length >= type.max
            );
        }
        var length = this.Items.filter((item) => item.type == type.type).length;
        return length >= type.max;
    }

    removeDegree() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title: `حذف شهادة  ${this.SelectedDegree.type}`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات ألشهادة بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage: "هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.degreeHttpService
                    .remove(this.SelectedDegree._id)
                    .subscribe(() => {
                        this.getEmployeeDegrees();
                    });
            }
        });
    }
}

@Component({
    selector: "app-degree-form",
    templateUrl: "./degree.form.component.html",
})
export class DegreeFormComponent implements OnInit {
    @Input() userId: string = null;
    @Input() Item: any = null;
    @Output() viewChange: EventEmitter<string> = new EventEmitter<string>();
    studyVacations: any[] = [];
    isLoading: Boolean = false;

    constructor(
        public degreeUtilService: DegreeUtilService,
        private degreeHttpService: DgreeService,
        public vacationService: VacationsService
    ) {}
    ngOnInit(): void {
        if (this.Item != null) {
            this.getEmployeeStudyVavcations();
        }
    }
    dropDegree(event: CdkDragDrop<string>) {
        this.Item = this.degreeUtilService.createDegreeObject(event.item.data);
        this.getEmployeeStudyVavcations();
    }
    getEmployeeStudyVavcations() {
        this.studyVacations = [];
        this.vacationService
            .findAll(this.userId, "دراسيه", false)
            .subscribe((response) => {
                var vacations = Array.from(response).filter(
                    (v: StudyVacation) => v.degreeType == this.Item.type
                );
                vacations.forEach((v: StudyVacation) => {
                    var vacationTitle =
                        v.degreeType +
                        "-" +
                        v.studyCountry +
                        "-" +
                        new Date(v.fromDate).getFullYear();
                    this.studyVacations.push({
                        id: v._id,
                        title: vacationTitle,
                    });
                });
            });
    }

    submit() {
        this.isLoading = true;
        this.degreeUtilService.isDegreeFormSubmitted = true;
        this.degreeHttpService
            .createOrUpdate(this.userId, this.Item.toFormData())
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.viewChange.emit("true");
                },
                error: () => {
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
    }
}

@Component({
    selector: "app-degree-elementry",
    templateUrl: "./templates/elementry.html",
})
export class ElementryDegreeComponent implements OnInit {
    @Input() item: ElementryDegreeForm;

    SchoolStudyingTypes = ConstantsUtils.getSchoolStudyingTypes();
    EducationTypes = ConstantsUtils.getEducationTypes();

    constructor(public degreeUtilService: DegreeUtilService) {}
    ngOnInit() {}
    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-degree-nodegree",
    templateUrl: "./templates/nodegree.html",
})
export class NoDegreeComponent implements OnInit {
    @Input() item: NoDegree;

    constructor(public degreeUtilService: DegreeUtilService) {}
    ngOnInit() {}
}

@Component({
    selector: "app-degree-intermediate",
    templateUrl: "./templates/elementry.html",
})
export class IntermediateDegreeComponent implements OnInit {
    @Input() item: IntermediateDegreeForm;

    SchoolStudyingTypes = ConstantsUtils.getSchoolStudyingTypes();
    EducationTypes = ConstantsUtils.getEducationTypes();
    constructor(public degreeUtilService: DegreeUtilService) {}
    ngOnInit() {}
    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-degree-highschool",
    templateUrl: "./templates/highschool.html",
})
export class HighschoolDegreeComponent implements OnInit {
    @Input() item: HisghschoolDegreeForm;
    SchoolStudyingTypes = ConstantsUtils.getSchoolStudyingTypes();
    EducationTypes = ConstantsUtils.getEducationTypes();
    HighSchoolBranches: Array<Branch> = ConstantsUtils.getHighSchoolBranches();
    selectedBranch: Branch;
    subBranches: string[] = [];
    constructor(public degreeUtilService: DegreeUtilService) {}
    ngOnInit() {
        this.getBranch();
    }
    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
    getBranch() {
        var index = this.HighSchoolBranches.findIndex(
            (b) => b.branch == this.item.mainBranch
        );
        if (index == -1) {
            this.selectedBranch = this.HighSchoolBranches[0];
            this.item.mainBranch = this.selectedBranch.branch;
            this.item.branch = this.selectedBranch.subBranches[0].branch;
            this.item.subBranch =
                this.selectedBranch.subBranches[0].subBranches[0];
            this.subBranches = this.selectedBranch.subBranches[0].subBranches;
        } else {
            this.selectedBranch = this.HighSchoolBranches[index];
            if (this.selectedBranch.subBranches.length) {
                var branchIndex = this.selectedBranch.subBranches.findIndex(
                    (b) => b.branch == this.item.branch
                );
                this.subBranches =
                    this.selectedBranch.subBranches[branchIndex].subBranches;
            }
        }
    }

    onMainBranchChange() {
        this.item.branch = "";
        this.item.subBranch = "";
        this.subBranches = [];
        var index = this.HighSchoolBranches.findIndex(
            (b) => b.branch == this.item.mainBranch
        );
        this.selectedBranch = this.HighSchoolBranches[index];
        if (
            this.selectedBranch.subBranches &&
            this.selectedBranch.subBranches.length
        ) {
            this.item.branch = this.selectedBranch.subBranches[0].branch;
            this.subBranches = this.selectedBranch.subBranches[0].subBranches;
            if (this.subBranches.length)
                this.item.subBranch = this.subBranches[0];
        }
    }

    onBranchChange() {
        this.item.subBranch = "";
        this.subBranches = [];
        var index = this.selectedBranch.subBranches.findIndex(
            (b) => b.branch == this.item.branch
        );
        var branch = this.selectedBranch.subBranches[index];
        if (branch.subBranches && branch.subBranches.length != 0) {
            this.subBranches = branch.subBranches;
            this.item.subBranch = this.subBranches[0];
        }
    }
}

@Component({
    selector: "app-degree-diploma",
    templateUrl: "./templates/diploma.html",
})
export class DiplomaDegreeComponent implements OnInit {
    @Input() item: UndergraduateDegreeForm;
    HighEducationTypes: string[] = ConstantsUtils.getEducationTypes();
    HighStudyingTypes: string[] = ConstantsUtils.getHighStudyingTypes();
    ScolarshipsTypes: string[] = ConstantsUtils.getScholarshipTypes();
    constructor(public degreeUtilService: DegreeUtilService) {}
    ngOnInit() {}
    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-degree-undergraduate",
    templateUrl: "./templates/undergraduate.html",
})
export class UndergraduateDegreeComponent implements OnInit {
    @Input() item: UndergraduateDegreeForm;
    HighEducationTypes: string[] = ConstantsUtils.getEducationTypes();
    HighStudyingTypes: string[] = ConstantsUtils.getHighStudyingTypes();
    ScolarshipsTypes: string[] = ConstantsUtils.getScholarshipTypes();
    vacations: any[] = [];
    constructor(public degreeUtilService: DegreeUtilService) {}
    ngOnInit() {}
    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-degree-graduate",
    templateUrl: "./templates/graduate.html",
})
export class GraduateDegreeComponent implements OnInit {
    @Input() item: GraduateDegreeForm;
    @Input() studyVacations: any[] = [];
    HighEducationTypes: string[] = ConstantsUtils.getEducationTypes();
    HighStudyingTypes: string[] = ConstantsUtils.getHighStudyingTypes();
    ScolarshipsTypes: string[] = ConstantsUtils.getScholarshipTypes();
    constructor(
        public degreeUtilService: DegreeUtilService,
        private dialog: MatDialog,
        private DataPersistant: DataPersistanceService,
        private structureService: StructureService
    ) {}
    ngOnInit() {}
    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }

    async openUniversityDialog() {
        const dialogRef = this.dialog.open(PreloadedStructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: "ألجامعات ألعراقية",
                treeData: await this.DataPersistant.getIraqiUniversities(),
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                this.getWorkplace(result._id);
            }
        });
    }

    getWorkplace(selectedworkplaceId: string) {
        this.structureService
            .findOne(selectedworkplaceId)
            .subscribe((response) => {
                this.clearUniversityData();
                var ancestors = response.ancestors;
                this.item.university =
                    ancestors.length >= 2 ? ancestors[1].name : "";
                this.item.college =
                    ancestors.length >= 3 ? ancestors[2].name : "";
                this.item.department =
                    ancestors.length >= 3 ? response.name : "";
            });
    }

    clearUniversityData() {
        this.item.university = "";
        this.item.college = "";
        this.item.department = "";
    }
}
