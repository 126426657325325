import { Files } from "src/app/classes/Files";

export class PositionModel {
    _id: string = null;
    isMoh: boolean = true;
    isCurrentPosition: boolean = false;
    assignmentType: string = "أصل";
    positionTitle: string = "مسؤول وحدة";
    office = "";
    OfficeFullname: string = "";
    workplaceId: string = "";
    fromDate: Date = new Date();
    fromDocumentDate: Date = new Date();
    fromDocumentNumber: string = "";
    privateGrade: string = "";

    toDate: Date = new Date();
    toDocumentDate: Date = new Date();
    toDocumentNumber: string = "";
    Files: Files = new Files();
    files: Array<any> = [];
    notes: string = "";
    constructor(isMoh: boolean = true) {
        this.isMoh = isMoh;
    }

    toFormData(): FormData {
        var data = new FormData();
        data.append("isMoh", this.isMoh.toString());
        data.append("office", this.office);
        data.append("OfficeFullname", this.OfficeFullname);
        data.append("fromDate", this.fromDate?.toString());
        data.append("fromDocumentDate", this.fromDocumentDate?.toString());
        data.append("fromDocumentNumber", this.fromDocumentNumber.toString());
        data.append("assignmentType", this.assignmentType);
        data.append("positionTitle", this.positionTitle);

        if (this.isCurrentPosition == false) {
            data.append("toDate", this.toDate?.toString());
            data.append("toDocumentDate", this.toDocumentDate?.toString());
            data.append("toDocumentNumber", this.toDocumentNumber.toString());
        }
        data.append("isCurrentPosition", this.isCurrentPosition.toString());
        data.append("notes", this.notes);
        data.append("privateGrade", this.privateGrade);
        data.append(
            "workplaceId",
            this.workplaceId.length
                ? this.workplaceId
                : "645ca823725629319e3069ac"
        );
        this.Files.appendFilesToForm(data);
        return data;
    }
}
