import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MyinfoComponent } from "./employee/myinfo/myinfo.component";
import {
    BacicAddressInfoComponent,
    BacicContactInfoComponent,
    BacicInfoComponent,
    BacicNameInfoComponent,
    BacicPhotoComponent,
} from "./employee/bacic-info/bacic-info.component";
import { DegreeInfoComponent } from "./employee/certificate-info/degrees-components";
import { OfficialDocumentsComponent } from "./employee/official-documents/official-documents.component";
import {
    EmploymentInfoComponent,
    EmploymentBasicComponent,
    EmploymentNotesComponent,
    EmploymentStatusComponent,
} from "./employee/employment-info/employment-info.component";
import {
    AbsenseVacationComponent,
    CareVacationComponent,
    CompanionshipVacationComponent,
    DisabilityVacationComponent,
    FiveYearsVacationsComponent,
    NormalLongVacationsComponent,
    NormalShortVacationsComponent,
    SickLeaveVacationsComponent,
    StudyVacationsComponent,
    UnpaidVacationsComponent,
    VacationsComponent,
} from "./employee/vacations/vacations.component";
import { HrAdminComponent } from "./hr-admin/hr-admin/hr-admin.component";
import { EmployeesComponent } from "./hr-admin/employees/employees.component";
import { FamilyInfoComponent,MaritialInfoComponent,ChildrenInfoComponent } from "./employee/family-info/family-info.component";
import { TimeOffComponent } from "./employee/vacations/time-off/time-off.component";
import { VacationSumarryComponent } from "./employee/vacations/vacation-sumarry/vacation-sumarry.component";
import { MotherhoodVacationsComponent } from "./employee/vacations/motherhood-vacation/motherhoode-vacation";
import { BonusesUpgradesComponent } from "./employee/bonuses-upgrades/bonuses-upgrades.component";
import { UpgradeSummaryComponent } from "./employee/bonuses-upgrades/upgrade-summary/upgrade-summary.component";
import { UpgradesTableComponent } from "./employee/bonuses-upgrades/upgrades/upgrades.component";
import { AwardsPunishmentsComponent } from "./employee/awards-punishments/awards-punishments.component";
import { AwardsComponent } from "./employee/awards-punishments/awards/awards.component";
import { PunishmentsComponent } from "./employee/awards-punishments/punishments/punishments.component";
import { CurrentWorkplacesComponent, EmployeeWorkplacesComponent,  MohWorkplacesComponent,  OtherWorkplacesComponent, TemporaryWorkplacesComponent, UniversityWorkplacesComponent } from "./employee/employee-workplaces/employee-workplaces.component";
import { RoleGuards } from "../services/guards/roleGuard";
import { AdministrativePositionsComponent, MohPositionComponent, OtherPositionComponent, UniversityPositionComponent } from "./employee/administrative-positions/administrative-positions.component";
import { FacultyPromotionsComponent } from "./employee/faculty-promotions/faculty-promotions.component";
import { ClassesComponent, CommitesComponent, EmployeeActivitiesComponent, PapersComponent, WorkshopsComponent } from "./employee/employee-activities/employee-activities.component";
import { WorkplaceEmployeesComponent } from "./data/workplace-employees/workplace-employees.component";

const routes: Routes = [
    {
        path: "hr-admin",
        component: HrAdminComponent,
        canActivate:[RoleGuards],
        data:{
            expectedRole: 'hr-admin'
        },
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "employees",
            },
            {
                path: "employees",
                component: EmployeesComponent,
            },
        ],
    },
    {
        path: "employee/:userId",
        component: MyinfoComponent,

        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "basicinfo",
            },
            {
                path: "basicinfo",
                component: BacicInfoComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "nameinfo",
                    },
                    {
                        path: "nameinfo",
                        component: BacicNameInfoComponent,
                    },
                    {
                        path: "contactinfo",
                        component: BacicContactInfoComponent,
                    },
                    {
                        path: "addressinfo",
                        component: BacicAddressInfoComponent,
                    },
                    {
                        path: "photo",
                        component: BacicPhotoComponent,
                    },
                ],
            },
            {
                path: "familyinfo",
                component: FamilyInfoComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "maritialinfo",
                    },
                    {
                        path:"maritialinfo",
                        component:MaritialInfoComponent
                    },
                    {
                        path:"childreninfo",
                        component:ChildrenInfoComponent
                    }
                ]
            },
            {
                path: "activities",
                component: EmployeeActivitiesComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "papers",
                    },
                    {
                        path:"papers",
                        component:PapersComponent
                    },
                    {
                        path:"classes",
                        component:ClassesComponent
                    },
                    {
                        path:"commites",
                        component:CommitesComponent
                    },
                    {
                        path:"workshops",
                        component:WorkshopsComponent
                    }
                ]
            },
            {
                path: "degrees",
                component: DegreeInfoComponent,
            },
            {
                path: "workplaces",
                component: EmployeeWorkplacesComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "currentworkplace",
                    },
                    {
                        path:"currentworkplace",
                        component:CurrentWorkplacesComponent
                    },
                    {
                        path:"temporaryworkplace",
                        component:TemporaryWorkplacesComponent

                    },
                    {
                        path:"university",
                        component:UniversityWorkplacesComponent
                    },
                    {
                        path:"moh",
                        component:MohWorkplacesComponent
                    },
                    {
                        path:"other",
                        component:OtherWorkplacesComponent
                    }
                ]
            },

            {
                path: "adminstrative-positions",
                component: AdministrativePositionsComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "university",
                    },
                    {
                        path:"university",
                        component:UniversityPositionComponent
                    },
                    {
                        path:"moh",
                        component:MohPositionComponent
                    },
                    {
                        path:"other",
                        component:OtherPositionComponent
                    }
                ]
            },
            {
                path: "officialdocuments",
                component: OfficialDocumentsComponent,
            },
            {
                path: "employmentinfo",
                component: EmploymentInfoComponent,
                children:[
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "employmentbasic",
                    },
                    {
                        path:"employmentbasic",
                        component:EmploymentBasicComponent
                    },
                    {
                        path:"employmentstatus",
                        component:EmploymentStatusComponent
                    },
                    {
                        path:"employmentnotes",
                        component:EmploymentNotesComponent
                    },
                ]
            },
            {
                path: "bonusesupgrades",
                component: BonusesUpgradesComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "upgradestable",
                    },
                    {
                        path: "upgradesummary",
                        component: UpgradeSummaryComponent,
                    },
                    {
                        path: "upgradestable",
                        component: UpgradesTableComponent,
                    },
                ],
            },
            {
                path: "awardspunisments",
                component: AwardsPunishmentsComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "awards",
                    },
                    {
                        path: "awards",
                        component: AwardsComponent,
                    },
                    {
                        path: "punishments",
                        component: PunishmentsComponent,
                    },
                ],
            },
            
            {
                path: "facultypromotions",
                component: FacultyPromotionsComponent,

            },
            {
                path: "vacations",
                component: VacationsComponent,
                children: [
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "summary",
                    },
                    {
                        path: "summary",
                        component: VacationSumarryComponent,
                    },

                    {
                        path: "timeoff",
                        component: TimeOffComponent,
                    },
                    {
                        path: "normal-short",
                        component: NormalShortVacationsComponent,
                    },
                    {
                        path: "normal-long",
                        component: NormalLongVacationsComponent,
                    },
                    {
                        path: "unpaid",
                        component: UnpaidVacationsComponent,
                    },
                    {
                        path: "study",
                        component: StudyVacationsComponent,
                    },
                    {
                        path: "five-years",
                        component: FiveYearsVacationsComponent,
                    },
                    {
                        path: "sick-leave",
                        component: SickLeaveVacationsComponent,
                    },
                    {
                        path: "companionship",
                        component: CompanionshipVacationComponent,
                    },
                    {
                        path: "care",
                        component: CareVacationComponent,
                    },
                    {
                        path: "motherhood",
                        component: MotherhoodVacationsComponent,
                    },
                    {
                        path: "absense",
                        component: AbsenseVacationComponent,
                    },
                    {
                        path: "disability",
                        component: DisabilityVacationComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'data/:workplaceId',
        component: WorkplaceEmployeesComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HrRoutingModule {}
