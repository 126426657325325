<div class="container   my-12">
    <div class="row justify-content-center ">
        <div class="col-md-6">
            <article class="card-body p-10" style="text-align: center;" *ngIf="isLoading">
                <div class="fa-4x">
                    <i class="fas fa-spinner fa-pulse text-success"></i>
                </div>
            </article>

            <div class="alert alert-warning alert-dismissible fade show" role="alert" *ngIf="error">
                <p><strong>!خطأ</strong> {{error}} </p>
            </div>


            <div *ngIf="!isLoading" class="card font-size ">
                <header class="card-header bg-transparent">
                    <h4 class="card-title mt-2"><i class="fa fa-fw fa-user"></i> تسجيل الدخول</h4>
                </header>
                <article class="card-body">
                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label" for="email">أسم المستخدم<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input type="text" formControlName="username" class="form-control" id="username" dir="ltr"
                                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-envelope"></i></span>
                                    </div>
                                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                        <div *ngIf="f.username.errors.required">البريد الالكتروني مطلوب</div>
                                        <div *ngIf="f.email.errors.email">البريد الالكتروني غير صالح</div>
                                    </div>
                                </div>

                            </div> <!-- form-group end.// -->
                            <div class="col-md-6 form-group">
                                <label class="form-label">كلمة المرور<span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input type="password" formControlName="password" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-lock"></i></span>
                                    </div>
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div  *ngIf="f.password.errors.required">كلمة المرور مطلوبه</div>
                                        <div *ngIf="f.password.errors.minlength">كلمة المرور يجب ان تكون من ستة رموز على
                                            الاقل</div>
                                    </div>
                                </div>
                            </div> <!-- form-group end.// -->
                        </div> <!-- form-row end.// -->
                        <div class="row mt-5 mb-3">
                            <div class="col-md-12">                             
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success w-100"><i class="fas  fa-sign-in-alt fa-rotate-180 pr-2 fa-1x font-size"></i> تسجيل الدخول </button>
                                </div> <!-- form-group// -->
                            </div>
                        </div>
                    </form>
                </article> <!-- card-body end .// -->
            </div> <!-- card.// -->
        </div> <!-- col.//-->

    </div> <!-- row.//-->
</div>
