<div class="container py-10">
    <div class="row justify-content-center">
        <div class="col-md-9">
            <div class="card">
                <div class="card-header bg-creamy text-secondary center">
                    <h5>الملف الشخصي</h5>
                </div>
                <div class="card-body px-0 bg-creamy" style="font-size: 14px">
                    <div class="row px-3">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        ألأسم ألكامل
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{
                                            basicInfo
                                                ? [
                                                      basicInfo.aFirstName,
                                                      basicInfo.aFatherName,
                                                      basicInfo.aGrandFatherName,
                                                      basicInfo.aFourthName,
                                                      basicInfo.aSureName
                                                  ].join(" ")
                                                : ""
                                        }}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        اسم الام
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{
                                            basicInfo
                                                ? basicInfo.motherName
                                                : ""
                                        }}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        ألجهة ألمنتسب أليها
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <ul class="list-unstyled pt-2">
                                        <li *ngFor="let item of workplaceList">
                                            <label class="pl-2 d-block">
                                                {{ item }}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        الديانة
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{
                                            basicInfo ? basicInfo.religion : ""
                                        }}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        الجنس
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{ basicInfo ? basicInfo.gender : "" }}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        رقم الهاتف
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{ basicInfo ? basicInfo.mobile : "" }}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        البريد الالكتروني
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{ basicInfo ? basicInfo.email : "" }}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        تاريخ الميلاد
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{
                                            basicInfo
                                                ? (basicInfo.birthDate | date)
                                                : ""
                                        }}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        القومية
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{
                                            basicInfo
                                                ? basicInfo.nationalisim
                                                : ""
                                        }}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-5">
                                    <label class="py-2 pl-2 d-block">
                                        العنوان
                                        <span
                                            class="float-left font-weight-bold"
                                            >:</span
                                        >
                                    </label>
                                </div>
                                <div class="col-md-7">
                                    <label class="py-2 pl-2 d-block">
                                        {{
                                            basicInfo?.address
                                                ? [
                                                      basicInfo.address
                                                          .province,
                                                      basicInfo.address.city,
                                                      basicInfo.address.quarter,
                                                      basicInfo.address.street,
                                                      basicInfo.address
                                                          .subStreet,
                                                      basicInfo.address
                                                          .unitNumber
                                                  ].join(" / ")
                                                : ""
                                        }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            ​<picture>
                                <source
                                    srcset="assets/img/0.png"
                                    type="image/png"
                                />
                                <img
                                    class="profile-image"
                                    [src]="basicInfoService.profileImage.url"
                                    height="260"
                                    alt="الصورة الشخصية"
                                />
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
