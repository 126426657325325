<div class="wrapper">
    <div class="sidebar right-sidebar border-left">
        <button class="btn close-sidebar-button">
            <i class="fa fa-times hover-danger text-dark"></i>
        </button>
        <ul class="list-unstyled mb-0">
            <li>
                <a routerLink="basicinfo" routerLinkActive="active">
                    <i class="fa fa-user fa-lg px-4"></i>ألمعلومات ألشخصية
                </a>
            </li>
            <li>
                <a routerLink="employmentinfo" routerLinkActive="active">
                    <i class="fas fa-user-tie fa-lg px-4"></i>ألمعلومات ألوظيفية
                </a>
            </li>
            <li routerLink="bonusesupgrades" routerLinkActive="active">
                <a> <i class="fas fa-signal px-4"></i>ألعلاوات وألترفيعات </a>
            </li>

            <li routerLink="degrees" routerLinkActive="active">
                <a> <i class="fa fa-certificate px-4"></i>ألشهادات </a>
            </li>
            <li routerLink="facultypromotions" routerLinkActive="active">
                <a> <i class="fa fa-star px-4"></i>ألألقاب ألعلمية </a>
            </li>
            <li routerLink="vacations" routerLinkActive="active">
                <a> <i class="fa fa-calendar px-4"></i>ألاجازات </a>
            </li>
            <li>
                <a routerLink="familyinfo" routerLinkActive="active">
                    <i class="fa fa-child fa-lg px-4"></i>ألمعلومات ألعائلية
                </a>
            </li>
            <li routerLink="officialdocuments" routerLinkActive="active">
                <a> <i class="fa fa-file-archive px-4"></i>ألوثائق ألرسمية </a>
            </li>
            <li routerLink="awardspunisments" routerLinkActive="active">
                <a> <i class="fa fa-book px-4"></i>كتب ألشكر وألعقوبات </a>
            </li>
            <!-- <li routerLink="adminstrative-positions" routerLinkActive="active">
                <a>
                   <i class="fa fa-user-tag pr-3 pl-4"></i>ألمناصب ألأدارية
               </a>
            </li>
            <li routerLink="workplaces" routerLinkActive="active">
                <a>
                   <i class="fa fa-home px-4"></i>أماكن العمل
               </a>
            </li>

            <li routerLink="activities" routerLinkActive="active">
                <a>
                   <i class="fa fa-newspaper px-4"></i>ألنشاطات و ألفعاليات
               </a>
            </li>

            <li class="border-bottom-0">
                <a>
                   <i class="fa fa-thumbs-up px-4"></i> ألتقييم ألسنوي
               </a>
            </li> -->
        </ul>
    </div>
    <div class="contents">
        <div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
