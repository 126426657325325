import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
} from "@angular/core";
import { OfficialDocumentsHelper } from "./documnts-helper";
import {
    NationalId,
    RationCard,
    AddressCard,
    CivilId,
    UniversityId,
    Passport,
    CertificateOfNationality,
    Documents,
} from "../../../models/hr/employee/officialdocuments";
import { NgForm } from "@angular/forms";
import { OfficalDocumentsService } from "src/app/services/employee/offical-documents.service";
import { ActivatedRoute } from "@angular/router";
import { FilesService } from "src/app/services/files/file.service";
import { ConfirmationDialogComponent } from "src/app/shared-components/dialogs/dialogs";
import { MatDialog } from "@angular/material/dialog";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { DateUtil } from "src/app/classes/Utils";
import { data } from "jquery";
@Component({
    selector: "app-official-documents",
    templateUrl: "./official-documents.component.html",
})
export class OfficialDocumentsComponent implements OnInit, AfterViewInit {
    Items: any[] = [];
    userId: string = null;
    SelectedDocument: any = null;
    selectedId: string = null;
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();
    view: string = "list";
    constructor(
        public documentHelper: OfficialDocumentsHelper,
        private documentService: OfficalDocumentsService,
        private Activeroute: ActivatedRoute,
        public fileService: FilesService,
        private dialog: MatDialog
    ) {}

    async ngOnInit() {
        this.userId = await this.Activeroute.parent.snapshot.params["userId"];
        this.getEmployeeOfficalDocuments();
    }

    async ngAfterViewInit() {
        this.userId = await this.Activeroute.parent.snapshot.params["userId"];
        this.dt.setDtElement(this.dtElement);
        this.getEmployeeOfficalDocuments();
        this.dt.trigger();
    }

    getEmployeeOfficalDocuments() {
        this.documentService.findAll(this.userId).subscribe((response) => {
            if (response) {
                this.Items = response;
                this.dt.reload();
                this.view = "list";
            }
        });
    }

    preview(id: string) {
        window.open(this.fileService.getFileUrl(id), "_blank");
    }

    newOrEdit(view: string) {
        if (view == "edit") {
            this.selectedId = this.SelectedDocument._id;
            this.SelectedDocument = this.documentHelper.jsonToDocumentObject(
                this.SelectedDocument.document
            );
        }
        this.view = view;
    }

    removeDocument() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title: `حذف وثيقة ${this.SelectedDocument.type}`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات ألوثيقة بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage: "هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.documentService
                    .remove(this.SelectedDocument._id)
                    .subscribe((response) => {
                        this.getEmployeeOfficalDocuments();
                    });
            }
        });
    }

    isDocumentMaxReached(type: any) {
        var length = this.Items.filter((item) => item.type == type.type).length;
        return length >= type.max;
    }
}

@Component({
    selector: "app-official-documents-form",
    templateUrl: "./officialdocuments.form.component.html",
})
export class OfficialDocumentsFormComponent implements OnInit {
    @Input() userId: string = null;
    @Input() Item: any = null;
    @Input() SelectedId: string = null;
    @Output() viewChange: EventEmitter<string> = new EventEmitter<string>();

    isLoading: Boolean = false;

    constructor(
        public documentHelper: OfficialDocumentsHelper,
        private documentService: OfficalDocumentsService
    ) {}
    ngOnInit(): void {}

    dropDocument(event: CdkDragDrop<string>) {
        this.Item = this.documentHelper.createDocumentObject(event.item.data);
    }

    submit() {
        var data: FormData = this.Item.toFormData();
        data.append("userId", this.userId);
        this.isLoading = true;
        if (this.SelectedId) {
            this.documentService.update(this.SelectedId, data).subscribe({
                next: () => {
                    this.viewChange.emit("true");
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
        } else {
            this.documentService.create(this.userId, data).subscribe({
                next: () => {
                    this.viewChange.emit("true");
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
        }
    }
}

@Component({
    selector: "app-document-national-id",
    templateUrl: "./templates/national-id.html",
})
export class NationalIdComponent implements OnInit {
    @Input() item: NationalId;
    constructor(public documentHelper: OfficialDocumentsHelper) {}
    ngOnInit(): void {
        if (this.item.faceone != null) {
            this.item.faceOne.url = this.documentHelper.fileService.getFileUrl(
                this.item.faceone
            );
        }
        if (this.item.facetwo != null) {
            this.item.faceTwo.url = this.documentHelper.fileService.getFileUrl(
                this.item.facetwo
            );
        }
    }

    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-document-civil-id",
    templateUrl: "./templates/civil-id.html",
})
export class CivilIdComponent implements OnInit {
    @Input() item: CivilId;
    constructor(public documentHelper: OfficialDocumentsHelper) {}
    ngOnInit(): void {
        if (this.item.faceone != null) {
            this.item.faceOne.url = this.documentHelper.fileService.getFileUrl(
                this.item.faceone
            );
        }
        if (this.item.facetwo != null) {
            this.item.faceTwo.url = this.documentHelper.fileService.getFileUrl(
                this.item.facetwo
            );
        }
    }

    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-document-ration-card",
    templateUrl: "./templates/ration-card.html",
})
export class RationCardComponent implements OnInit {
    @Input() item: RationCard;
    constructor(public documentHelper: OfficialDocumentsHelper) {}
    ngOnInit(): void {
        if (this.item.faceone != null) {
            this.item.faceOne.url = this.documentHelper.fileService.getFileUrl(
                this.item.faceone
            );
        }
    }

    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-document-address-card",
    templateUrl: "./templates/address-card.html",
})
export class AddressCardComponent implements OnInit {
    @Input() item: AddressCard;
    constructor(public documentHelper: OfficialDocumentsHelper) {}
    ngOnInit(): void {
        if (this.item.faceone != null) {
            this.item.faceOne.url = this.documentHelper.fileService.getFileUrl(
                this.item.faceone
            );
        }
        if (this.item.facetwo != null) {
            this.item.faceTwo.url = this.documentHelper.fileService.getFileUrl(
                this.item.facetwo
            );
        }
    }

    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-document-certificate-of-nationality",
    templateUrl: "./templates/certificate-of-nationality.html",
})
export class CertificateOfNationalityComponent implements OnInit {
    @Input() item: CertificateOfNationality;
    constructor(public documentHelper: OfficialDocumentsHelper) {}
    ngOnInit(): void {
        if (this.item.faceone != null) {
            this.item.faceOne.url = this.documentHelper.fileService.getFileUrl(
                this.item.faceone
            );
        }
    }

    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-document-passport",
    templateUrl: "./templates/passport.html",
})
export class PassportComponent implements OnInit {
    @Input() item: Passport;
    constructor(public documentHelper: OfficialDocumentsHelper) {}
    ngOnInit(): void {
        if (this.item.faceone != null) {
            this.item.faceOne.url = this.documentHelper.fileService.getFileUrl(
                this.item.faceone
            );
        }
    }

    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}

@Component({
    selector: "app-document-university-id",
    templateUrl: "./templates/university-id.html",
})
export class UniversityIdComponent implements OnInit {
    @Input() item: UniversityId;
    constructor(public documentHelper: OfficialDocumentsHelper) {}
    ngOnInit(): void {
        if (this.item.faceone != null) {
            this.item.faceOne.url = this.documentHelper.fileService.getFileUrl(
                this.item.faceone
            );
        }
        if (this.item.facetwo != null) {
            this.item.faceTwo.url = this.documentHelper.fileService.getFileUrl(
                this.item.facetwo
            );
        }
    }

    isValid(form: NgForm) {
        this.item.isValid = form.valid;
    }
}
