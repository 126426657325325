import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { User } from "src/app/models/hr/employee/user";
import {
    MinstryWorkplace,
    WorkplaceModel
} from "src/app/models/hr/employee/workplaces";
import { StructureService } from "src/app/services/system-manager/structure-http-service";
import { UsersService } from "src/app/services/users/users.service";
import { WorkplaceUtilService } from "src/app/services/util/workplace.service";

import { PreloadedStructureDialogComponent } from "../dialogs/dialogs";
import { EmployeeWorkplacesService } from "src/app/services/employee/employee-workplaces";
import { UniversityService } from "src/app/services/data-persistence/university-info";
import { NgForm } from "@angular/forms";
import { DataPersistanceService } from "src/app/services/data-persistence/data-persistance";
@Component({
    selector: "app-move-user",
    templateUrl: "move.user.html",
})
export class ChangeUserWorkplaceForm implements OnInit {
    //@Input() entityId: string;
    @Input() userId: string;
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    User: User = new User();
    workplace: MinstryWorkplace = new MinstryWorkplace();
    MainWorkplace: MinstryWorkplace = new MinstryWorkplace();
    SecondaryWorkplace: MinstryWorkplace = null;
    isUniversity: boolean = true;
    isSubmitted = false;
    sourceTreeData: any[] = [];
    destTreeData: any[] = [];

    titles: string[] = ['ألوزارة أو ألرئاسة', 'ألمديرية أو ألدائره', 'ألقسم', 'ألشعبه', 'ألوحده'];
    constructor(
        private userService: UsersService,
        public workplaceUtilService: WorkplaceUtilService,
        private employeeworkplaceService: EmployeeWorkplacesService,
        private dialog: MatDialog,
        private structureService: StructureService,
        public University: UniversityService,
        public DataPersistant: DataPersistanceService
    ) { }
    ngOnInit(): void {
        this.findEmployee();
        this.employeeworkplaceService
            .findMainWorkplace(this.userId)
            .subscribe((response) => {
                if (response) {
                    this.MainWorkplace = Object.assign(this.MainWorkplace, response);
                    this.getMainWorkplaceTree();
                }
            });

        this.employeeworkplaceService
            .findSecondaryWorkplace(this.userId)
            .subscribe((response) => {
                if (response) {
                    this.SecondaryWorkplace = Object.assign(new MinstryWorkplace(), response);
                    this.SecondaryWorkplace.fromDate = new Date(this.SecondaryWorkplace.fromDate)
                }

            });
    }

    findEmployee() {
        this.userService.findOne(this.userId).subscribe((response) => {
            this.User = Object.assign(this.User, response);
        });
    }

    getMainWorkplaceTree() {
        var list = this.MainWorkplace.fullname.split("-");
        this.sourceTreeData = this.workplaceUtilService.convertToTree(
            list.slice(1, list.length)
        );
    }

    async openDialog() {
        const dialogRef = this.dialog.open(PreloadedStructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: this.University.Name,
                treeData: await this.DataPersistant.getUniversityData()
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getWorkplace(result._id);
            }
        });
    }

    async openMinistryDialog() {
        const dialogRef = this.dialog.open(PreloadedStructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: "وزارة ألتعليم العالي وألبحث ألعلمي و ألدوائر ألتابعة لها",
                treeData: await this.DataPersistant.getMinistryData()
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getWorkplace(result._id);
            }
        });
    }
    resetWorkplace() {
        this.workplace.fullname = "";
        this.destTreeData = [];
        this.workplace.office = "";
        this.isSubmitted = false;
    }

    getWorkplace(selectedworkplaceId: string) {
        this.structureService
            .findOne(selectedworkplaceId)
            .subscribe((response) => {
                this.workplace.fullname =
                    this.workplaceUtilService.getFullPath(response);
                this.workplace.workplaceId = selectedworkplaceId;
                this.workplace.office = response.ancestors[0].name;
                this.getWorkplaceTree();

            });
    }

    getWorkplaceTree() {
        if (this.workplace.isMoh) {
            this.destTreeData = this.workplaceUtilService.convertToTree(
                this.workplace.fullname.split("-")
            );
        } else {
            if (this.workplace.fullname.length) {
                this.destTreeData = this.workplace.fullname.split("-").map((name: string) => ({ name }));
            }
        }
    }

    onDataChange() {
        const stringArray = this.destTreeData.map((obj: { name: string }) => obj.name);
        if (stringArray.length) this.workplace.office = stringArray[0];
        this.workplace.fullname = stringArray.join("-");
    }

    submit() {
        this.isSubmitted = true;
        if (
            // !this.MainWorkplace.Files.hasFiles() ||
            // !this.MainWorkplace.Files.isValid() ||
            this.workplace.fullname == ""
        )
            return;

        if (this.workplace.movementType == "دائمي") {
            this.MainWorkplace.mainWorkplace = false;
            this.workplace.mainWorkplace = true;
            if (this.workplace.isMoh) {
                this.userService.updateWorkplace(this.userId, { workplace: this.workplace.workplaceId }).subscribe();
            }
            // this.updateMainWorkplace();
            this.viewChange.emit('list');

        } else {
            this.workplace.secondaryWorkplace = true;
            this.createWorkplace();
        }
    }

    updateMainWorkplace() {

        this.employeeworkplaceService
            .update(this.MainWorkplace._id, this.MainWorkplace.toFormData())
            .subscribe((response) => {
                this.createWorkplace();
            });
    }
    createWorkplace() {
        this.employeeworkplaceService.create(this.userId, this.workplace.toFormData()).subscribe((response) => {
            this.viewChange.emit('list');
        })
    }
}
