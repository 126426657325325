import { NormalLongVacationsComponent } from "src/app/hr/employee/vacations/vacations.component";
import { Files } from "../../../classes/Files";
import { DatePeriod, DateUtil } from "../../../classes/Utils";

export class TimeOff {
    _id: string = null;
    date: Date = new Date();
    from: string = "08:30";
    to: string = "09:00";
    duration: number = 0;
}

export class Vacation {
    _id: string = null;
    type: string;
    fromDate: Date = new Date();
    toDate: Date = new Date();
    duration: number = 0;
    paymentType: string = "راتب تام";
    upgradeCountless: boolean = false;
    retirementCountless: boolean = false;
    notes: string = "";

    constructor(type: string) {
        this.type = type;
    }

    toFormData(): FormData {
        var data = new FormData();
        data.append("type", this.type);
        data.append("fromDate", this.fromDate?.toString());
        data.append("toDate", this.toDate?.toString());
        data.append("duration", this.calculateTotalDuration().toString());
        data.append("upgradeCountless", this.upgradeCountless.toString());
        data.append("retirementCountless", this.retirementCountless.toString());
        data.append("paymentType", this.paymentType);
        data.append("notes", this.notes);
        return data;
    }

    calculateTotalDuration(): number {
        return DateUtil.dateDifInDays(this.fromDate, this.toDate);
    }
}

export class NormalLongVacation extends Vacation {
    Files: Files = new Files();
    files: any[] = [];
    Booknumber: string = "";
    BooknumberDate: Date = new Date();
    fromBooknumber: string = "";
    fromBooknumberDate: Date = new Date();
    toBooknumber: string = "";
    toBooknumberDate: Date = new Date();
    constructor(type: string) {
        super(type);
    }
    override toFormData(): FormData {
        var data = super.toFormData();
        data.append("Booknumber", this.Booknumber);
        data.append("fromBooknumber", this.fromBooknumber);
        data.append("toBooknumber", this.toBooknumber);
        data.append("BooknumberDate", this.BooknumberDate?.toString());
        data.append("fromBooknumberDate", this.fromBooknumberDate?.toString());
        data.append("toBooknumberDate", this.toBooknumberDate?.toString());
        this.Files.appendFilesToForm(data);
        return data;
    }
}

export class AbsenseVacation extends NormalLongVacation {
    constructor(type: string) {
        super(type);
        this.upgradeCountless = false;
        this.retirementCountless = false;
        this.paymentType = "بدون راتب";
    }
}

export class DisabilityVacation extends NormalLongVacation {
    constructor(type: string) {
        super(type);
        this.upgradeCountless = true;
        this.retirementCountless = true;
        this.paymentType = "راتب تام";
    }
}

export class UnpaidVacation extends NormalLongVacation {
    constructor(type: string) {
        super(type);
        this.upgradeCountless = true;
        this.retirementCountless = true;
        this.paymentType = "بدون راتب";
    }
}

export class CareVacation extends NormalLongVacation {}

export class CompanionshipVacation extends NormalLongVacation {
    constructor(type: string) {
        super(type);
        this.paymentType = "راتب أسمي";
    }
}

export class SickLeaveVacation extends NormalLongVacation {}

export class FiveYearsVacation extends NormalLongVacation {
    constructor(type: string) {
        super(type);
        this.paymentType = "راتب أسمي";
    }
}

const motherHoodPaymentTypes: string[] = [
    "راتب كامل",
    "راتب كامل",
    "راتب كامل",
    "راتب أسمي",
];

export class MotherhoodVacation {
    _id: string = null;
    expectedBirthDate: Date = new Date();
    realBirthDate: Date = new Date();
    fromDate: Date = new Date();
    toDate: Date = new Date();
    duration: number = 0;
    stages: Array<NormalLongVacation> = [];
    notes: string = "";

    addStage() {
        var stage = new NormalLongVacation("أمومه");
        stage.paymentType = motherHoodPaymentTypes[this.stages.length];
        this.stages.push(stage);
    }

    toFormData(): FormData {
        var data = new FormData();
        data.append("expectedBirthDate", this.expectedBirthDate?.toString());
        data.append("realBirthDate", this.realBirthDate?.toString());
        if (this.stages.length) {
            data.append("fromDate", this.stages[0].fromDate?.toString());
            data.append(
                "toDate",
                this.stages[this.stages.length - 1].toDate?.toString()
            );
        }
        data.append("duration", this.calculateTotalDuration().toString());
        return data;
    }

    calculateTotalDuration(): number {
        var duration = 0;
        this.stages.forEach((stage) => {
            duration += stage.calculateTotalDuration();
        });
        return duration;
    }
}

export class StudyVacation extends NormalLongVacation {
    degreeType = "دكتوراه";
    studyCountry: string = "ألعراق";
    studyType = "";
    override toFormData(): FormData {
        var data = super.toFormData();
        data.append("degreeType", this.degreeType);
        data.append("studyCountry", this.studyCountry);
        data.append("studyType", this.studyType);
        return data;
    }
}
