<div>
    <h1 mat-dialog-title class="cairo text-secondary border-bottom my-0  pb-3" style="font-size: 18px;" dir="rtl">
        <i class="fa fa-exclamation-triangle fa-lg pl-3 text-danger"></i>
        {{ data.title }}
        <button mat-icon-button class="float-left mt-2 hover-danger" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>

    </h1>

    <div mat-dialog-content class=" pt-3">
        <p class="text-center" [innerHTML]="data.warningMessage" dir="rtl"> </p>
        <p class="text-center" *ngIf="data.confirmationMessage"> {{data.confirmationMessage}}</p>
    </div>

    <div mat-dialog-actions class="row dialog-confirmation" *ngIf="data.confirmationMessage">
        <div class="col-md-4 offset-md-4">
            <button class="btn btn-success btn-block" mat-dialog-close>{{data.yesNo.no}}</button>
        </div>
        <div class="col-md-4 ">
            <button class="btn btn-danger btn-block" [mat-dialog-close]="true"
                cdkFocusInitial>{{data.yesNo.yes}}
            </button>
        </div>


    </div>
</div>