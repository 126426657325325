<footer class="border-top border-width-2 bg-light border-dark">
    <div class="bg-gray-14 py-2">
        <div class="container">
            <div class="text-center">
                <div class="mb-3 mb-md-0">© <a   class="font-weight-bold text-gray-90 cairo-small">
                   جامعة ألموصل - مركز ألحاسبة ألالكترونية
                </a></div>
            </div>
        </div>
    </div>
</footer>
