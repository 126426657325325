import { Files } from "src/app/classes/Files";

export class FamilyInfo {
    _id: string = null;
    maritialStatus: string = "أعزب";
    spouses: Array<Spouse> = [];
    children: Array<Child> = [];

    toFormData(): FormData {
        var data = new FormData();
        data.append("maritialStatus", this.maritialStatus);
        return data;
    }
}

export class Spouse {
    _id: string = null;
    marriageDate: Date = new Date();
    spouseName: string = "";
    isSpouseEmployeed: boolean = false;
    marriageStatus: string = "مستمر";
    separationDate: Date = new Date();
    Files: Files = new Files();
    files: Array<any> = [];

    toFormData(): FormData {
        var data = new FormData();
        if (this._id != null) {
            data.append("_id", this._id.toString());
        }
        data.append("marriageDate", this.marriageDate?.toString());
        data.append("maritialStatus", this.marriageStatus);
        data.append("spouseName", this.spouseName);
        data.append("isSpouseEmployeed", this.isSpouseEmployeed.toString());
        this.Files.appendFilesToForm(data);
        return data;
    }
}

export class Child {
    _id: string = null;
    name: string = "";
    birthDate: Date = new Date();
    gender: string = "ذكر";
    maritialStatus: string = "أعزب";
    Files: Files = new Files();
    files: Array<any> = [];
    toFormData(): FormData {
        var data = new FormData();
        if (this._id != null) {
            data.append("_id", this._id);
        }
        data.append("name", this.name);
        data.append("maritialStatus", this.maritialStatus);
        data.append("birthDate", this.birthDate?.toString());
        data.append("gender", this.gender);
        this.Files.appendFilesToForm(data);
        return data;
    }
}
