import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpLoadingService } from "../shared/http-loading.service";


@Injectable({
    providedIn: "root",
})
export class EmployeeWorkplacesService {
    private url = environment.ApiUrl + "/employeeworkplaces/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}



    findAll(userId:string,query:any):Observable<any>{
        
        const params = new HttpParams({ fromObject: query });
        return this.http.get(this.url + userId,{
            params:{
                query:JSON.stringify(query)
            }
        })
    }

    findMainWorkplace(userId:string){
        return this.http.get(this.url +"mainworkplace/"+userId)
    }

    findSecondaryWorkplace(userId:string){
        return this.http.get(this.url +"secondaryworkplace/"+userId)
    }

    create(userId:string,data:any){
        return this.http.post(
            this.url + userId, data
        );
    }

    update(workplaceId:string,data:any){
        return this.http.patch(
            this.url + workplaceId, data
        );
    }

    removeWorkplace(workplaceId:string):Observable<any>{
        return this.http.delete(`${this.url}${workplaceId}`)
    }

    removeWorkplaceFile(workplaceId:string,data:any){
        return this.http.patch(this.url +"removefile/"+workplaceId,data)
    }

    
}