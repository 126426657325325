import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { catchError, combineLatest, map, of, switchMap, tap } from "rxjs";
import { StructureService } from "src/app/services/system-manager/structure-http-service";
import { UsersService } from "src/app/services/users/users.service";
import { HrDataService } from "../../hr-admin/hr-role-workplace-data.service";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-workplace-employees",
    templateUrl: "./workplace-employees.component.html",
    styleUrls: ["./workplace-employees.component.scss"],
})
export class WorkplaceEmployeesComponent {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly structureService: StructureService,
        private readonly userService: UsersService,
        private readonly hrDataService: HrDataService,
        private readonly toastr: ToastrService
    ) {}

    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();

    workplaceId$ = this.route.paramMap.pipe(
        map((params) => params.get("workplaceId"))
    );

    workplace$ = this.workplaceId$.pipe(
        switchMap((workplaceId) =>
            this.structureService
                .findOne(workplaceId)
                .pipe(catchError((err) => of(null)))
        )
    );
    employees$ = this.workplace$.pipe(
        switchMap((workplace) =>
            workplace
                ? this.userService.findWorkPlaceEmployees(workplace._id)
                : of([])
        )
    );

    vm$ = combineLatest({
        workplace: this.workplace$,
        employees: this.employees$,
    })
        // DEBUG
        .pipe(tap((vm) => console.log({ vm })));

    updateUserEmail(userId: string, email: string) {
        this.userService
            .update(userId, { email })
            .pipe(catchError((err) => of(null)))
            .subscribe((response) => {
                if (response) {
                    this.toastr.success(
                        "تم تحديث البريد الالكتروني بنجاح!",
                        "تم التحديث بنجاح!"
                    );
                } else {
                    this.toastr.error(
                        "فشل تحديث البريد الالكتروني، يرجى المحاولة مرة اخرى",
                        "فشل التحديث!"
                    );
                }
            });
    }
}
