<div class="card">
    <div class="card-header">
        <h4 *ngIf="Item._id"><i class="fa fa-school  px-2"></i> تعديل  ألمنصب ألأداري</h4>
        <h4 *ngIf="!Item._id"><i class="fa fa-user-tie  px-2"></i> أضافة منصب جديد</h4>
        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
            <button class="btn btn-print btn-block " *ngIf="Item.isMoh &&University.Name!=type"
                (click)="openMinstryDialog();">
                <i class="fa fa-university mx-2"></i> 
            </button>
            <button class="btn btn-print btn-block "  *ngIf="Item.isMoh &&University.Name==type"
                (click)="openUniversityDialog();">
                <i class="fa fa-university mx-2"></i> 
            </button>
            <button (click)="submit();" class="btn btn-print" title="حفظ ألمعلومات ">
                <i class="fa fa-save fa-lg"></i>
            </button>
            <button class="btn btn-print" title="رجوع ألى ألقائمه"
                (click)="this.viewHandler.changeViewEvent('list')">
                <i class="fa fa-share fa-lg"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <form #frm="ngForm"></form>
            <div class="row" >
                <div class="col-md-8"   >
                    <div class="border px-3 border-radius-25 bg-white" *ngIf="Item.OfficeFullname.length && Item.isMoh">
                        <app-workplace-tree-view  [treeData]="treeData"></app-workplace-tree-view>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!Item.isMoh">
                <div class="col-md-8"  >
                    <app-workplace-entry-view  [Data]="treeData" [titles]="titles" (dataChange)="onDataChange()"></app-workplace-entry-view> 
                </div>
            </div>
            <div class="row" *ngIf="Item.OfficeFullname.length">
                <div class="col-md-8">
                    <app-selectpicker 
                        [values]="positions" 
                        [selected]="Item.positionTitle"
                        [label]="'ألمنصب ألأداري'"
                        [leftLabel]="Item.privateGrade.length?Item.privateGrade:''"
                        [inputGroupText]="{class:'fa fa-user-tie px-2',text:''}"
                        (valueChange)="Item.positionTitle=$event;checkPrivateGrades($event)">
                    </app-selectpicker>
                </div>
                <div class="col-md-4">
                    <label class=" "> نوع ألتكليف : </label>
                    <div class="d-flex  border px-3 border-radius-25 bg-white" >
                        <div class="icheck-greensea  my-2 w-50 ">
                            <input class="box" type="radio" name="assignment" [(ngModel)]="Item.assignmentType"
                                value="أصل" id="assignment_1">
                            <label for="assignment_1" class="px-2"> أصل </label>
                        </div>
                        <div class="icheck-greensea  my-2 w-50 ">
                            <input class="box" type="radio" name="assignment" [(ngModel)]="Item.assignmentType"
                                value="وكالة" id="assignment_2">
                            <label for="assignment_2" class="px-2"> وكالة </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <app-datepicker [input]="Item.fromDate" [name]="'startDate'"
                        [label]="'تاريخ ألمباشرة بالمنصب'" (dateChange)="Item.fromDate=$event">
                    </app-datepicker>
                </div>
                <div class="col-md-4">
                    <app-datepicker [input]="Item.fromDocumentDate" [name]="'fromDocumentDate'"
                        [label]="'تاريخ ألأمر ألأداري بالتكليف '" (dateChange)="Item.fromDocumentDate=$event">
                    </app-datepicker>
                </div>
                <div class="col-md-4">
                    <label for="fromDocumentNumber" class="form-label">  رقم ألامر ألأداري بالتكليف:</label>
                    <div class="input-group">
                        <input type="text" id="fromDocumentNumber" name="fromDocumentNumber" #fromDocumentNumber="ngModel" class="form-control text-center"
                            [(ngModel)]="Item.fromDocumentNumber">
                            <div class="input-group-prepend">
                                <span class="input-group-text font-bold">
                                   #
                                </span>
                            </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <app-datepicker [input]="Item.toDate" [name]="'endDate'"
                        [label]="'تاريخ ألأعفاء من ألمنصب'" (dateChange)="Item.toDate=$event">
                    </app-datepicker>
                </div>

                <div class="col-md-4">
                    <app-datepicker [input]="Item.toDocumentDate" [name]="'toDocumentDate'"
                        [label]="'تاريخ ألأمر ألأداري بالأعفاء '" (dateChange)="Item.toDocumentDate=$event">
                    </app-datepicker>
                </div>
                <div class="col-md-4">
                    <label for="toDocumentNumber" class="form-label">  رقم ألامر ألأداري بالأعفاء:</label>
                    <div class="input-group">
                        <input type="text" id="toDocumentNumber" name="toDocumentNumber" #toDocumentNumber="ngModel" class="form-control text-center"
                            [(ngModel)]="Item.toDocumentNumber">
                            <div class="input-group-prepend">
                                <span class="input-group-text font-bold">
                                   #
                                </span>
                            </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
                        <mat-label class=" px-3">ألملاحضات </mat-label>
                        <textarea matInput placeholder="" rows="3" class="py-0" [(ngModel)]="Item.notes"></textarea>
                        <mat-icon matSuffix class="text-secondary ">comment</mat-icon>
                    </mat-form-field>
                </div>
            </div>


            
            <div class="row">
                <div class="col-md-12">
                    <app-files [Files]="Item.Files" [uploadedFiles]="Item.files"
                        (fileDelete)="removeFile(Item._id,$event)" [header]="'ألأوامر ألأدارية ألخاصة بالتكليف و ألأعفاء'">
                    </app-files>
                </div>
            </div>
    </div>
</div>