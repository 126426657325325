import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpLoadingService } from "../shared/http-loading.service";

@Injectable({
    providedIn: "root",
})
export class FamilyInfoService {
    private url = environment.ApiUrl + "/familyinfo/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    findOne(id: string): Observable<any> {
        return this.http.get(this.url + id+`?cache_bust=${new Date().getTime()}`);
    }
    create(userId: string, data: any): Observable<any> {
        console.log(userId)
        return this.http.post(
            this.url + userId, data
        );
    }

    update(infoId: string, data: any): Observable<any> {
        return this.http.patch(
            this.url + infoId, data
        );
    }
}



@Injectable({
    providedIn: "root",
})
export class SpouceInfoService {
    private url = environment.ApiUrl + "/spouses/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    create(userId:string,data:any):Observable<any>{
        return this.http.post(
            this.url + userId, data
        );
    }

    update(infoId:string,data:any):Observable<any>{
        return this.http.patch(
            this.url + infoId, data
        );
    }

    remove(infoId:string,data:any){
        return this.http.patch(this.url +"removeSpouse/"+infoId,data)
    }

    removeFile(id:string,data:any){
        return this.http.patch(this.url +"removefile/"+id,data)
    }   
}


@Injectable({
    providedIn: "root",
})
export class ChildInfoService {
    private url = environment.ApiUrl + "/child/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    create(infoId:string,data:any):Observable<any>{
        return this.http.post(
            this.url + infoId, data
        );
    }

    update(infoId:string,data:any):Observable<any>{
        return this.http.patch(
            this.url + infoId, data
        );
    }

    removeChild(infoId:string,data:any){
        return this.http.patch(this.url +"removeChild/"+infoId,data)
    }

    removeFile(id:string,data:any){
        return this.http.patch(this.url +"removefile/"+id,data)
    }  
}

