<div class="wrapper">
    <div class="contents">
        <div class="container">
            <app-page-header-info title2=" ألمناصب ألأدارية"></app-page-header-info>
            <div class="content-container my-7 mx-md-4">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class="sidebar left-sidebar border-right" style="font-size: 12px !important;">
        <button class="btn close-sidebar-button border border-radius-25 btn-light">
            <i class="fa fa-times hover-danger text-dark"></i>
        </button>
        <ul class="list-unstyled mb-10 ">
            <li class="border-top-0">
                <a routerLink="currentworkplace" routerLinkActive="active" >
                   <i class="fa fa-user-tie  px-3"></i> ألمنصب ألأداري ألحالي  
               </a>
            </li>

            <li>
                <a routerLink="university" routerLinkActive="active" >
                   <i class="fa fa-list  px-3"></i> جامعة ألموصل
               </a>
            </li>

            <li>
                <a routerLink="moh" routerLinkActive="active" >
                    <i class="fa fa-list  px-3"></i> وزاراة ألتعليم ألعالي
               </a>
            </li>

            <li>
                <a routerLink="other" routerLinkActive="active" >
                    <i class="fa fa-list  px-3"></i> وزارات ودوائر أخرى
               </a>
            </li>
        </ul>

    </div>
</div>

