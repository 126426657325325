export class Role{
    _id:string=null;
    name:string="";
    link:string="";
    category:string="";
    description:string="";
    priviliges:string[]=[];
    constructor(category:string){
        this.category=category;
    }
}

export class UserRole{
    roleId:string=null;
    userId:string=null;
    workplaceId:string=null;
    constructor(roleId:string=null,userId:string=null,workplaceId:string=null){
        this.roleId=roleId;
        this.userId=userId;
        this.workplaceId=workplaceId;
    }
}