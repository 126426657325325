import {
    Component,
    OnInit,
    EventEmitter,
    Output,
    Input,
    AfterViewInit,
    ViewChild,
    ChangeDetectorRef,
    AfterViewChecked,
    OnChanges,
    SimpleChanges,
    ElementRef
} from "@angular/core";
import { defineLocale } from "ngx-bootstrap/chronos";
import {  BsDatepickerDirective, BsLocaleService} from "ngx-bootstrap/datepicker";
import { } from "ngx-bootstrap/datepicker";
import { arLocale } from "ngx-bootstrap/locale";
defineLocale("ar", arLocale);

declare const $: any;

class util {
    static setElementId(name: string): string {
        var id = name + Math.round(Math.random() * 10000);
        return id;
    }
    static setElementLabel(label: string): string {
        return label + " : ";
    }
}
@Component({
    selector: "app-datepicker",
    templateUrl: "./templates/datepicker.html",
    styleUrls: ["./controls.scss"],
})
export class DatepickerComponent implements OnInit {
    @ViewChild("datepicker", { static: true }) datepicker:BsDatepickerDirective;
    @Input() input: Date=new Date();
    @Input() name: string;
    @Input() label: string;
    @Input() max: Date;
    @Output() dateChange = new EventEmitter<Date>();
    id: string = "";
    constructor(private bsLocaleService: BsLocaleService,public cdRef:ChangeDetectorRef) {
        this.bsLocaleService.use("ar");
        
    }

    ngOnInit(): void {
        this.id = util.setElementId(this.name);
        this.label = util.setElementLabel(this.label);
        
    }
    onDateChange(event: any): void {

        this.dateChange.emit(event);
    } 

    ngOnChanges() {
        this.cdRef.detectChanges(); // trigger change detection manually
    }
}

@Component({
    selector: "app-selectpicker",
    templateUrl: "./templates/selectpicker.html",
    styleUrls: ["./controls.scss"],
})
export class SelectpickerComponent implements OnInit, AfterViewInit,OnChanges {
    @ViewChild("mySelect", { static: true }) myselect:ElementRef<HTMLSelectElement>;
    @Input() values: string[];
    @Input() selected: string;
    @Input() name: string;
    @Input() label: string;
    @Input() leftLabel:string;
    @Input() inputGroupText:any;
    @Output() valueChange = new EventEmitter<string>();
    id: string = "";
    constructor() {}

    ngOnInit(): void {
        this.id = util.setElementId(this.name);
        this.label = util.setElementLabel(this.label);
        $(".selectpicker").selectpicker("refresh");
    }
    ngAfterViewInit() {
        $(".selectpicker").selectpicker("refresh");
        
    }
    onChange(value: string) {
        this.valueChange.emit(value);
    }
    ngOnChanges(changes: SimpleChanges): void {
        
        
        if(changes['selected'].currentValue){
            this.myselect.nativeElement.value=changes['selected'].currentValue;
        }
        $(".selectpicker").selectpicker("refresh");
    }
}
