import {
    Component,
    OnInit,
    AfterViewInit,
    OnDestroy,
    ViewChild,
    Input,
    Output,
    EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MinstryWorkplace } from "src/app/models/hr/employee/workplaces";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
    ConfirmationDialogComponent,
    PreloadedStructureDialogComponent,
    StructureDialogComponent,
} from "src/app/shared-components/dialogs/dialogs";
import { WorkplaceUtilService } from "src/app/services/util/workplace.service";
import { StructureService } from "src/app/services/system-manager/structure-http-service";
import { EmployeeWorkplacesService } from "src/app/services/employee/employee-workplaces";
import { UsersService } from "src/app/services/users/users.service";
import { User } from "src/app/models/hr/employee/user";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { WorkplaceViewsHandler } from "./employee-workplace-utils-service";
import { UniversityService } from "src/app/services/data-persistence/university-info";
import { DataPersistanceService } from "src/app/services/data-persistence/data-persistance";
@Component({
    selector: "app-employee-workplaces",
    templateUrl: "./employee-workplaces.component.html",
    styleUrls: ["./employee-workplaces.component.scss"],
})
export class EmployeeWorkplacesComponent implements OnInit {
    userId: string = null;
    constructor(
        private employeeworkplaceService: EmployeeWorkplacesService,
        public viewHandler: WorkplaceViewsHandler,
        private Activeroute: ActivatedRoute) { }
    async ngOnInit() {
        this.userId = await this.Activeroute.parent.snapshot.params[
            "userId"
        ];
        this.employeeworkplaceService.findSecondaryWorkplace(this.userId)
            .subscribe((response) => {
                if (response) this.viewHandler.hasTemporaryWorkkplace = true;
                else this.viewHandler.hasTemporaryWorkkplace = false;

            })
    }
}

@Component({
    selector: "app-workplace-list",
    templateUrl: "./templates/workplace-list.html",
    styleUrls: ["./employee-workplaces.component.scss"],
})
export class WorkplaceListComponent
    implements OnInit, AfterViewInit, OnDestroy {
    @Input() title: string = "";
    @Input() query: any = {};
    items: any[] = [];
    SelectedItem: any = null;

    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dt: DatatableUtil = new DatatableUtil();



    constructor(
        private employeeworkplaceService: EmployeeWorkplacesService,
        private dialog: MatDialog,
        public workplaceUtilService: WorkplaceUtilService,
        public viewHandler: WorkplaceViewsHandler,
        private Activeroute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.viewHandler.getSubmissionEvent().subscribe(() => {
            this.findAll();
        });
    }
    async ngAfterViewInit() {
        this.viewHandler.userId = await this.Activeroute.parent.parent.snapshot
            .params["userId"];
        this.dt.setDtElement(this.dtElement);
        this.findAll();
        this.dt.trigger();
    }

    findAll() {
        this.employeeworkplaceService
            .findAll(this.viewHandler.userId, this.query)
            .subscribe((response) => {
                this.items = response;
                this.dt.reload();
                this.viewHandler.changeViewEvent("list");
            });
    }

    getWorkplaceTree(Item: any) {
        return this.workplaceUtilService.convertToTree(
            Item.fullname.split("-")
        );
    }

    newOrEdit(view: string) {
        if (view == "edit") {
            this.viewHandler.SelectedItem = this.SelectedItem;
        }
        this.viewHandler.changeViewEvent(view);
    }

    removeWorkplace() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title: ` حذف مكان ألعمل`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم حذف جميع بيانات هذا ألمكان بما فيها الملفات ألمتعلقة بشكل نهائي من قاعدة ألبيانات .`,
                confirmationMessage: "هل أنت متأكد من أتمام عملية ألحذف ؟",
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.employeeworkplaceService
                    .removeWorkplace(this.SelectedItem._id)
                    .subscribe(() => {
                        this.findAll();
                    });
            }
        });
    }
    ngOnDestroy() { }
}

@Component({
    selector: "app-workplace-form",
    templateUrl: "./templates/workplace-form.html",
})
export class WorkplaceFormComponent implements OnInit {
    @Input() Item: MinstryWorkplace;
    @Input() titles: string[] = [];
    @Input() type: string = "";
    @Output() viewChange: EventEmitter<any> = new EventEmitter<any>();
    employmentTypes: string[] = ["دائمي", "عقد", "أجور يومية"];
    SelectedWorkplace: any = null;
    treeData: any[] = [];

    constructor(
        private employeeworkplaceService: EmployeeWorkplacesService,
        private structureService: StructureService,
        public workplaceUtilService: WorkplaceUtilService,
        public viewHandler: WorkplaceViewsHandler,
        private dialog: MatDialog,
        public University: UniversityService,
        private DataPersistant: DataPersistanceService
    ) { }
    ngOnInit() {
        this.Item.fromDate = new Date(this.Item.fromDate);
        this.Item.toDate = new Date(this.Item.toDate);
        this.Item.fromDocumentDate = new Date(this.Item.fromDocumentDate);
        this.Item.toDocumentDate = new Date(this.Item.toDocumentDate);
        this.getWorkplaceTree();
    }
    submit() {
        if (this.Item._id == null) {
            this.employeeworkplaceService
                .create(this.viewHandler.userId, this.Item.toFormData())
                .subscribe((response) => {
                    this.viewHandler.trigerSubmissionEvent();
                });
        } else {
            this.employeeworkplaceService
                .update(this.Item._id, this.Item.toFormData())
                .subscribe((response) => {
                    this.viewHandler.trigerSubmissionEvent();
                });
        }
    }

    onDataChange() {
        const stringArray = this.treeData.map((obj: { name: string }) => obj.name);
        this.Item.fullname = stringArray.join("-");
    }

    async openUniversityDialog() {
        const dialogRef = this.dialog.open(PreloadedStructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: this.University.Name,
                treeData: await this.DataPersistant.getUniversityData()
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getWorkplace(result._id);
            }
        });
    }


    async openMinstryDialog() {
        const dialogRef = this.dialog.open(PreloadedStructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: "وزارة ألتعليم ألعالي وألدوائر ألتابعه لها",
                treeData: await this.DataPersistant.getMinistryData()
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getWorkplace(result._id);
            }
        });
    }


    getWorkplace(selectedworkplaceId: string) {
        this.structureService
            .findOne(selectedworkplaceId)
            .subscribe((response) => {
                this.Item.fullname =
                    this.workplaceUtilService.getFullPath(response);
                this.Item.workplaceId = selectedworkplaceId;
                this.getWorkplaceTree();
                this.Item.office = response.ancestors[0].name;
            });
    }

    getWorkplaceTree() {
        if (this.Item.isMoh) {
            this.treeData = this.workplaceUtilService.convertToTree(
                this.Item.fullname.split("-")
            );
        } else {
            if (this.Item.fullname.length) {
                this.treeData = this.Item.fullname.split("-").map((name: string) => ({ name }));
            }
        }
    }

    removeFile(workplaceId: string, fileId: string) {
        this.employeeworkplaceService
            .removeWorkplaceFile(workplaceId, { fileId })
            .subscribe((response) => { });
    }
}

@Component({
    selector: "app-employee-university-workplaces",
    templateUrl: "./templates/workplaces.html",
})
export class UniversityWorkplacesComponent implements OnInit {
    titles: string[] = [];
    view: string = "list";
    title: string = this.University.Name;
    query: any = {}


    workplace: MinstryWorkplace = new MinstryWorkplace();
    constructor(private viewHandler: WorkplaceViewsHandler,
        private University: UniversityService) { }

    ngOnInit(): void {
        this.title = this.University.Name;
        this.query = {
            $and: [
                { isMoh: true },
                { office: { $eq: this.University.Name } }
            ]
        };

        this.viewHandler.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.workplace = new MinstryWorkplace();
        if (view == "edit") {
            this.workplace = Object.assign(
                this.workplace,
                this.viewHandler.SelectedItem
            );
        }
        this.view = view;
    }
}


@Component({
    selector: "app-employee-moh-workplaces",
    templateUrl: "./templates/workplaces.html",
})
export class MohWorkplacesComponent {
    view: string = "list";
    title: string = "وزارة ألتعليم ألعالي";
    query: any = {
        $and: [
            { isMoh: true },
            { office: { $ne: this.University.Name } }
        ]
    };
    titles: string[] = [];

    workplace: MinstryWorkplace = new MinstryWorkplace();
    constructor(private viewHandler: WorkplaceViewsHandler, private University: UniversityService) { }

    ngOnInit(): void {
        this.viewHandler.getViewEvent().subscribe((view) => {
            if (view == "list") {
                this.view = view;
            } else {
                this.newOrEdit(view);
            }
        });
    }
    newOrEdit(view: string) {
        this.workplace = new MinstryWorkplace();
        if (view == "edit") {
            this.workplace = Object.assign(
                this.workplace,
                this.viewHandler.SelectedItem
            );
        }
        this.view = view;
    }
}

@Component({
    selector: "app-employee-other-workplaces",
    templateUrl: "./templates/workplaces.html",
})
export class OtherWorkplacesComponent {
    view: string = "list";
    title: string = "وزارات و دوائر أخرى";
    query: any = {
        isMoh: false,
    };
    titles: string[] = ['ألوزارة أو ألرئاسة', 'ألمديرية أو ألدائره', 'ألقسم', 'ألشعبه', 'ألوحده'];

    workplace: MinstryWorkplace = new MinstryWorkplace();
    constructor(private viewHandler: WorkplaceViewsHandler) { }

    ngOnInit(): void {
        this.viewHandler.getViewEvent().subscribe((view) => {
            view == "list" ? this.view = view : this.newOrEdit(view)
        });
    }
    newOrEdit(view: string) {
        this.workplace = new MinstryWorkplace(false);
        if (view == "edit") {
            this.workplace = Object.assign(
                this.workplace,
                this.viewHandler.SelectedItem
            );
        }
        this.view = view;
    }
}


@Component({
    selector: "app-employee-current-workplace",
    templateUrl: "./templates/current.component.html",
})
export class CurrentWorkplacesComponent implements OnInit {
    userId: string = null;
    MainWorkplace: MinstryWorkplace = new MinstryWorkplace();
    SecondaryWorkplace: MinstryWorkplace = null;
    terminateSecondaryWorkplace: boolean = false;
    isUniversityWorkplaceEdited = false;
    constructor(
        private Activeroute: ActivatedRoute,
        private employeeworkplaceService: EmployeeWorkplacesService,
        private userService: UsersService,
        private structureService: StructureService,
        private dialog: MatDialog,
        public workplaceUtilService: WorkplaceUtilService
    ) { }

    async ngOnInit() {
        this.userId = await this.Activeroute.parent.parent.snapshot.params[
            "userId"
        ];

        this.getUser();

        this.employeeworkplaceService
            .findMainWorkplace(this.userId)
            .subscribe((response) => {
                if (response) {
                    this.MainWorkplace = Object.assign(
                        this.MainWorkplace,
                        response
                    );
                    this.MainWorkplace.fromDate = new Date(
                        this.MainWorkplace.fromDate
                    );
                    this.MainWorkplace.fromDocumentDate = new Date(
                        this.MainWorkplace.fromDocumentDate
                    );
                }
            });
    }

    user: User = new User();

    getWorkplaceTree(Item: any) {
        return this.workplaceUtilService.convertToTree(
            Item.fullname.split("-")
        );
    }

    getUser() {
        this.userService.findOne(this.userId).subscribe((response) => {
            this.user = response;
        });
    }

    openDialog() {
        const dialogRef = this.dialog.open(StructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: "جامعة ألموصل",
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getWorkplace(result._id);
            }
        });
    }
    getWorkplace(selectedworkplaceId: string) {
        this.structureService
            .findOne(selectedworkplaceId)
            .subscribe((response) => {
                this.MainWorkplace.fullname =
                    this.workplaceUtilService.getFullPath(response);
                this.MainWorkplace.workplaceId = selectedworkplaceId;
                this.isUniversityWorkplaceEdited = true;

            });
    }

    submitMainWorkplace() {
        if (this.isUniversityWorkplaceEdited == true) {
            this.userService
                .updateWorkplace(this.userId, {
                    workplace: this.MainWorkplace.workplaceId,
                })
                .subscribe();
        }
        this.employeeworkplaceService
            .update(this.MainWorkplace._id, this.MainWorkplace.toFormData())
            .subscribe((response) => {
                this.MainWorkplace = Object.assign(
                    new MinstryWorkplace(),
                    response
                );
                this.MainWorkplace.fromDate = new Date(
                    this.MainWorkplace.fromDate
                );
            });
    }

    removeFile(workplaceId: string, fileId: string) {
        this.employeeworkplaceService
            .removeWorkplaceFile(workplaceId, { fileId })
            .subscribe((response) => { });
    }
}

@Component({
    selector: "app-employee-temporary-workplace",
    templateUrl: "./templates/temporary-workplace.component.html",
})
export class TemporaryWorkplacesComponent implements OnInit {
    userId: string = null;
    SecondaryWorkplace: MinstryWorkplace = null;
    terminateSecondaryWorkplace: boolean = false;

    constructor(
        private Activeroute: ActivatedRoute,
        private employeeworkplaceService: EmployeeWorkplacesService,
        private userService: UsersService,
        private structureService: StructureService,
        private dialog: MatDialog,
        public workplaceUtilService: WorkplaceUtilService,
        private viewHandler: WorkplaceViewsHandler,
        private router: Router
    ) { }

    async ngOnInit() {
        this.userId = await this.Activeroute.parent.parent.snapshot.params[
            "userId"
        ];
        this.getUser();
        this.getSecondaryWorkplace();
    }

    getSecondaryWorkplace() {
        this.employeeworkplaceService
            .findSecondaryWorkplace(this.userId)
            .subscribe((response) => {
                this.SecondaryWorkplace = null;
                this.viewHandler.hasTemporaryWorkkplace;
                if (response) {
                    this.SecondaryWorkplace = Object.assign(
                        new MinstryWorkplace(),
                        response
                    );
                    this.SecondaryWorkplace.fromDate = new Date(
                        this.SecondaryWorkplace.fromDate
                    );

                    this.SecondaryWorkplace.fromDocumentDate = new Date(
                        this.SecondaryWorkplace.fromDocumentDate
                    );
                    this.SecondaryWorkplace.toDate = new Date();
                    this.SecondaryWorkplace.toDocumentDate = new Date();
                } else {
                    this.viewHandler.hasTemporaryWorkkplace = false;
                    this.Activeroute;
                    this.router.navigate(['currentworkplace'], { relativeTo: this.Activeroute.parent });

                }
            });
    }

    openDialog() {
        const dialogRef = this.dialog.open(StructureDialogComponent, {
            width: "61vw",
            position: {
                top: "65px",
            },
            data: {
                title: "جامعة ألموصل",
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getWorkplace(result._id);
            }
        });
    }

    getWorkplace(selectedworkplaceId: string) {
        this.structureService
            .findOne(selectedworkplaceId)
            .subscribe((response) => {
                this.SecondaryWorkplace.fullname =
                    this.workplaceUtilService.getFullPath(response);
                this.SecondaryWorkplace.workplaceId = selectedworkplaceId;
            });
    }
    removeSecondaryWorkplace() {
        if (this.terminateSecondaryWorkplace == true) {
            this.removeSecondaryWorkplaceDialog();
        } else {
            this.SecondaryWorkplace.secondaryWorkplace = true;
            this.submitSecondaryWorkplace();
        }
    }

    user: User = new User();

    getWorkplaceTree(Item: any) {
        return this.workplaceUtilService.convertToTree(
            Item.fullname.split("-")
        );
    }

    getUser() {
        this.userService.findOne(this.userId).subscribe((response) => {
            this.user = response;
        });
    }

    removeSecondaryWorkplaceDialog() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: "550px",
            position: {
                top: "65px",
            },
            data: {
                title: `أنهاء تنسيب`,
                warningMessage: `<span class="text-danger">تحذير : </span> سيتم أنهاء تنسيب ألموظف [${this.user.fullname} ] من موقع ألتنسيب ألحالي .`,
                confirmationMessage: "هل أنت متأكد من عملية أنهاء ألتنسيب ؟",
                yesNo: { yes: "نعم", no: "كلا" },
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.SecondaryWorkplace.secondaryWorkplace = false;
            } else {
                this.terminateSecondaryWorkplace = false;
            }
            this.submitSecondaryWorkplace();
        });
    }

    submitSecondaryWorkplace() {
        this.employeeworkplaceService
            .update(
                this.SecondaryWorkplace._id,
                this.SecondaryWorkplace.toFormData()
            )
            .subscribe((response) => {
                this.getSecondaryWorkplace();
            });
    }
    removeFile(workplaceId: string, fileId: string) {
        this.employeeworkplaceService
            .removeWorkplaceFile(workplaceId, { fileId })
            .subscribe((response) => { });
    }

    setData() {
        // this.noDataMessage=`أن ألموظف [ ${this.user.fullname} ] غير منسب ألى أي جهة أخرى في ألوقت ألحالي .`
    }
    noDataMessage: string = null;
}
