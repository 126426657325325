import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable, lastValueFrom } from "rxjs";
import { HttpLoadingService } from "../shared/http-loading.service";
import { StructureService } from "../system-manager/structure-http-service";
import { ItemNode } from "src/app/models/system/structure";
import { query } from "@angular/animations";
import { UniversityService } from "./university-info";

@Injectable({
    providedIn: "root",
})
export class DataPersistanceService {
    private universityStructure: ItemNode[] = [];
    private ministryStructure: ItemNode[] = [];

    private degreeIraqiUniversities: ItemNode[] = [];
    constructor(
        private structureService: StructureService,
        private University: UniversityService
    ) {}

    async getUniversityData(): Promise<ItemNode[]> {
        if (this.universityStructure.length == 0) {
            this.universityStructure = await this.getData({
                name: this.University.Name,
            });
        }
        return this.universityStructure;
    }

    async getMinistryData(): Promise<ItemNode[]> {
        if (this.ministryStructure.length == 0) {
            this.ministryStructure = await this.getData({
                parent: null,
                restrictSearch: {
                    name: { $ne: this.University.Name },
                    type: { $ne: "جامعة أهلية" },
                },
            });
        }
        return this.ministryStructure;
    }

    async getIraqiUniversities(): Promise<ItemNode[]> {
        if (this.degreeIraqiUniversities.length == 0) {
            this.degreeIraqiUniversities = await this.getData({
                type: { $in: ["جامعة حكومية", "جامعة أهلية"] },
                restrictSearch: {
                    type: {
                        $in: [
                            "جامعة حكومية",
                            "جامعة أهلية",
                            "كلية",
                            "كلية أهلية",
                            "قسم علمي",
                            "فرع علمي",
                        ],
                    },
                },
            });
        }
        return this.degreeIraqiUniversities;
    }

    async getData(query: any): Promise<ItemNode[]> {
        const targetData = await lastValueFrom(
            this.structureService.findAll(query)
        );
        return targetData;
    }
}
