<div [ngClass]="{ 'd-none': view != 'list' }">
    <app-datse-filter
        [filterDates]="vacationUtilService.filterDates"
        [header]="'فرز ألاجازات ألزمنية حسب ألفترة'"
        (filterChange)="onFilterChange($event)"
    >
    </app-datse-filter>
    <div class="card mt-5">
        <div class="card-header">
            <h4><i class="fa fa-clock"></i> ألاجازات ألزمنية</h4>
            <div
                class="toolbar d-flex d-inline-flex w-30 justify-content-between"
            >
                <button
                    class="btn btn-print"
                    (click)="newOrEdit('new')"
                    title="أضافة أجازة زمنية جديدة"
                >
                    <i class="fa fa-plus-circle fa-2x"></i>
                </button>
            </div>
        </div>
        <div class="card-body px-0">
            <table
                id="timeoff"
                datatable
                [dtOptions]="this.dt.dtOptions"
                [dtTrigger]="this.dt.dtTrigger"
                class="table w-100 table-bordered table-striped border-0"
            >
                <thead>
                    <tr>
                        <th class="text-right d-none d-md-table-cell">#</th>
                        <th>ألتاريخ</th>
                        <th class="d-md-table-cell">
                            <span class="d-block d-md-none"> من</span>
                            <span class="d-none d-md-block"> من ألساعه</span>
                        </th>
                        <th class="d-md-table-cell">
                            <span class="d-block d-md-none"> ألى</span>
                            <span class="d-none d-md-block"> ألى ألساعه</span>
                        </th>
                        <th class="d-none d-md-table-cell">فترة ألاجازة</th>
                        <th class="text-center">
                            <i class="fa fa-ellipsis-v"></i>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items; let i = index">
                        <th class="d-none d-md-table-cell">{{ i + 1 }}</th>
                        <td class="pr-3">{{ formatDate(item.date) }}</td>
                        <td class="pr-3 d-md-table-cell">{{ item.from }}</td>
                        <td class="pr-3 d-md-table-cell">{{ item.to }}</td>
                        <td class="pr-3 d-none d-md-table-cell">
                            {{ formatTime(item.duration) }}
                        </td>
                        <th class="text-center">
                            <div class="dropdown">
                                <button
                                    class="btn"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i
                                        class="fa fa-ellipsis-v text-secondary"
                                    ></i>
                                </button>
                                <ul
                                    class="dropdown-menu bg-creamy"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <li
                                        class="dropdown-item"
                                        (click)="
                                            SelectedItem = item;
                                            newOrEdit('edit')
                                        "
                                    >
                                        <i
                                            class="fa fa-edit fa-lg pl-1 text-secondary"
                                        ></i>
                                        تعديل ألاجازة
                                    </li>
                                    <li
                                        class="dropdown-item"
                                        (click)="
                                            this.SelectedItem = item;
                                            this.delet()
                                        "
                                    >
                                        <i
                                            class="fa fa-trash text-danger fa-lg px-1 pl-2"
                                        ></i>
                                        حذف ألاجازة
                                    </li>
                                </ul>
                            </div>
                        </th>
                    </tr>
                </tbody>
            </table>

            <hr />
            <div class="row px-3 table-statistics">
                <div class="col-6 col-md-4">
                    <label>مجموع ألاجازات ألزمنية</label>
                </div>
                <div class="col-6 col-md-4">
                    <ul>
                        <li>
                            <span class="pl-2"> {{ totalDurationHours }}</span>
                            ساعه
                        </li>
                        <li>
                            <span class="pl-2"> {{ totalDurationDays }}</span>
                            يوم
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<app-time-off-form
    *ngIf="view == 'new'"
    [userId]="userId"
    (viewChange)="this.findAll()"
></app-time-off-form>
<app-time-off-form
    *ngIf="view == 'edit'"
    [Item]="SelectedItem"
    (viewChange)="this.findAll()"
></app-time-off-form>
