

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="bg-transparent  tree-title " dir="rtl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding  [mat-dialog-close]="node" >
        <!-- use a disabled button to provide padding for tree leaf -->

            <button mat-icon-button disabled class="mat-icon-rtl-mirror">
                <mat-icon class=" text-secondary" >
                    remove
                </mat-icon>
            </button>
            <span class="nowrap hover-green " >
                {{node.name}}
            </span>
            <sub class="badge badge-pill badge-warning mx-5">{{ node.type }}</sub>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="text-secondary " [mat-dialog-close]="node">

            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'remove' : 'add'}}
                </mat-icon>
            </button>
            <span class="nowrap hover-green" >{{node.name}}</span>
            <sub class="badge badge-pill badge-secondary mx-5">{{ node.type }}</sub>
    </mat-tree-node>
</mat-tree>