import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { BasicInfoUtilService } from "src/app/hr/employee/bacic-info/basicinfo-util-service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { EmployeeWorkplacesService } from "src/app/services/employee/employee-workplaces";
import {
    ProfileService,
    BasicInfo,
} from "src/app/services/employee/profile.service";
import { StructureService } from "src/app/services/system-manager/structure-http-service";
import { WorkplaceUtilService } from "src/app/services/util/workplace.service";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
    constructor(
        public authService: AuthenticationService,
        public employeeWorkplaceService: EmployeeWorkplacesService,
        private structureService: StructureService,
        public workplaceUtilService: WorkplaceUtilService,
        public basicInfoService: BasicInfoUtilService,
        private profileService: ProfileService,
        private router: Router
    ) {}

    userDetails: any;
    workplaceList: string[];
    userId: string;
    basicInfo: BasicInfo | null;

    ngOnInit() {
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(["/login"]);
            return;
        }
        this.userId = this.authService.getUserDetails().userId;
        this.profileService.findOne(this.userId).subscribe((basicInfo) => {
            this.basicInfo = basicInfo;
        });

        this.basicInfoService.userId = this.authService.getUserDetails().userId;
        this.basicInfoService.getUserPhoto();
        this.getWorkplace();
    }

    getWorkplace() {
        this.structureService
            .findOne(this.authService.getUserDetails().workplaceId)
            .subscribe((response) => {
                this.workplaceList =
                    this.workplaceUtilService.getBranchesList(response);
            });
    }
}
