import { Component, Input, OnInit, ViewChild ,AfterViewInit, Output,EventEmitter} from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { DatatableUtil } from "src/app/classes/datatable";
import { SystemGradeService } from "src/app/services/system-manager/system-grades.service";



@Component({
    selector: "app-system-grades",
    templateUrl: "./system-grades.component.html",
    styleUrls: ["./system-grades.component.scss"],
})export class SystemGradesComponent{

}


