<div [ngClass]="{'d-none':view!='list'}">
    <div class="card mt-6">
        <div class="card-header">
            <h4><i class="fas fa-list pl-3"></i> ألدرجة {{Item.grade}} : ألعناوين ألوظيفية </h4>
            <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
                <button class="btn btn-print" (click)="submit()"  title="حفظ ألدرجه ">
                    <i class="fa fa-save fa-lg"></i>
                </button>
                <button class="btn btn-print"  title="رجوع ألى ألقائمه" (click)="viewChange.emit('list')">
                    <i class="fa fa-share fa-lg"></i>
                </button>
            </div>
        </div>
        <div class="card-block px-0">
    
            <table id="upgradesummary" datatable [dtOptions]="this.dt.dtOptions" [dtTrigger]="this.dt.dtTrigger" class="table table-bordered table-striped border-0 my-0">
            <thead>
                <tr >
                    <th>#</th>
                    <th >ألعنوان ألوظيفي </th>
                    <th > مخصصات ألعنوان ألوظيفي؟  </th>
                    
                    <th class="text-center" style="width:15%;"><i class="fa fa-ellipsis-v"></i></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of Item.jobTitles;let i=index;">
                    <th>{{i+1}}</th>
                    <th class="pr-3 text-secondary">{{item.jobTitle}}</th>
                    <td class="text-center">{{item.titleAllowances}}</td>
                    <th class="text-center">
                        <div class="dropdown">
                            <button class="btn " type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                aria-haspopup="true">
                                <i class="fa fa-ellipsis-v text-secondary"></i>
                            </button>
                            <ul class="dropdown-menu bg-creamy " aria-labelledby="dropdownMenuButton">
                                <li class="dropdown-item " (click)="SelectedItem=item;newOrEdit('edit')"><i
                                        class="fa fa-edit fa-lg pl-1 text-secondary"></i>
                                    تعديل  ألعنوان ألوظيفي
                                </li>
                                
                            </ul>
                        </div>
                    </th>
                </tr>
            </tbody>
        </table>
        </div>
    </div>

</div>
<div class="card mt-6" *ngIf="view=='edit'">
    <div class="card-header">
        <h4><i class="fas fa-list pl-3"></i> تعديل ألعنوان ألوظيفي : {{SelectedItem.jobTitle}} </h4>
        <div class="toolbar d-flex d-inline-flex w-30 justify-content-between">
            <button class="btn btn-print" (click)="submit()"  title="حفظ ألعنوان">
                <i class="fa fa-save fa-lg"></i>
            </button>
            <button class="btn btn-print"  title="رجوع ألى ألقائمه" (click)="this.view='list'">
                <i class="fa fa-share fa-lg"></i>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="row" >
            <div class="col-md-4">
                <label for="fullname"> ألعنوان ألوظيفي    :</label>
                <input type="text" class="form-control" id="fullname" name="fullname" [(ngModel)]="SelectedItem.jobTitle">
            </div>
            <div class="col-md-4 offset-md-4">
                <label for="salary">   مخصصات ألعنوان    :</label>
                <input type="number" class="form-control text-center" id="salary" name="salary" [(ngModel)]="SelectedItem.titleAllowances">
            </div>
        </div>
    </div>
</div>