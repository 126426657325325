import { Files } from "../../../classes/Files";

export class NoDegree {
    _id: string = null;
    type: string = "أمي";
    isCurrentDegree: boolean = false;
    isEmploymentDegree: boolean = false;
    isRehireDegree: boolean = false;
    notes: string = "";
    degreeDate: Date = new Date();

    constructor(type: string = "أمي") {
        this.type = type;
    }
    toFormData(): FormData {
        var data = new FormData();
        if (this._id != null) {
            data.append("_id", this._id);
        }
        data.append("type", this.type);
        data.append("notes", this.notes);
        data.append("isCurrentDegree", this.isCurrentDegree.toString());
        data.append("isEmploymentDegree", this.isEmploymentDegree.toString());
        return data;
    }
}

export class DegreeForm {
    _id: string = null;
    isCurrentDegree: boolean = false;
    isEmploymentDegree: boolean = false;
    isRehireDegree: boolean = false;
    type: string = "";
    degreeDate: Date = new Date();
    documentNumber: string = "";
    country: string = "ألعراق";
    province: string = "نينوى";
    county: string = "ألموصل";
    isValid = false;
    studyingType: string = "صباحي";
    educationType: string = "حكومي";
    notes: string = "";

    Files: Files = new Files();
    files: any[] = [];
    constructor(type: string) {
        this.type = type;
    }

    toFormData(): FormData {
        var data = new FormData();
        if (this._id != null) {
            data.append("_id", this._id);
        }
        data.append("type", this.type);
        data.append("isCurrentDegree", this.isCurrentDegree.toString());
        data.append("isEmploymentDegree", this.isEmploymentDegree.toString());
        data.append("isRehireDegree", this.isRehireDegree.toString());
        data.append("documentNumber", this.documentNumber);
        data.append("degreeDate", this.degreeDate?.toString());
        data.append("country", this.country);
        data.append("province", this.province);
        data.append("county", this.county);
        data.append("studyingType", this.studyingType);
        data.append("notes", this.notes);
        data.append("educationType", this.educationType);

        this.Files.appendFilesToForm(data);

        return data;
    }
}
export class ElementryDegreeForm extends DegreeForm {
    schoolName: string = "";
    constructor(type: string) {
        super(type);
    }
    override toFormData(): FormData {
        var data = super.toFormData();
        data.append("schoolName", this.schoolName);
        return data;
    }
}
export class IntermediateDegreeForm extends ElementryDegreeForm {
    constructor(type: string) {
        super(type);
    }
}

export class HisghschoolDegreeForm extends ElementryDegreeForm {
    mainBranch: string = "";
    branch = "";
    subBranch = "";
    constructor(type: string) {
        super(type);
    }

    override toFormData(): FormData {
        var data = super.toFormData();
        data.append("mainBranch", this.mainBranch);
        data.append("branch", this.branch);
        data.append("subBranch", this.subBranch);
        return data;
    }
}

export class UndergraduateDegreeForm extends DegreeForm {
    university: string = "";
    college: string = "";
    department: string = "";
    gpa: number = 0;
    scholarshipType: string = "";
    generalSpecialization: string = "";
    fieldOfSpecialization: string = "";
    studyVacationId: string = null;

    constructor(type: string) {
        super(type);
    }

    override toFormData(): FormData {
        var data = super.toFormData();
        data.append("university", this.university);
        data.append("college", this.college);
        data.append("department", this.department);
        data.append("generalSpecialization", this.generalSpecialization);
        data.append("fieldOfSpecialization", this.fieldOfSpecialization);
        if (this.gpa != 0) {
            data.append("gpa", this.gpa.toString());
        }
        if (this.studyVacationId != null) {
            data.append("studyVacationId", this.studyVacationId);
        }
        data.append("scholarshipType", this.scholarshipType);
        return data;
    }
}

export class GraduateDegreeForm extends UndergraduateDegreeForm {
    phdType: string = "";
    constructor(type: string) {
        super(type);
    }

    override toFormData(): FormData {
        var data = super.toFormData();
        if (this.type == "دكتوراه") {
            data.append("phdType", this.phdType);
        }
        return data;
    }
}
