import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./home/account/login/login.component";
import { PageNotFoundComponent } from "./home/page-not-found/page-not-found.component";
import { WelcomePageComponent } from "./home/welcome-page/welcome-page.component";
import { ProfileComponent } from "./home/profile/profile.component";
import { TestComponent } from "./home/test/test.component";

const routes: Routes = [
    { path: "", redirectTo: "/login", pathMatch: "full" },
    { path: "login", component: LoginComponent },
    { path: "welcome", component: WelcomePageComponent },
    { path: "profile", component: ProfileComponent },
    { path: "test", component: TestComponent },
    { path: "**", component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
