<form
    #frm="ngForm"
    class="needs-validation"
    (ngSubmit)="basicInfoService.submit(frm)"
>
    <div class="card">
        <div class="card-header">
            <h4><i class="fa fa-map-marker-alt"></i> عنوان ألسكن</h4>
            <div
                class="toolbar d-flex d-inline-flex w-30 justify-content-between"
            >
                <button
                    type="submit"
                    class="btn btn-print"
                    title="حفظ ألمعلومات "
                >
                    <i class="fa fa-save fa-lg"></i>
                </button>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <app-selectpicker
                        [values]="basicInfoService.provinces"
                        [selected]="basicInfoService.employee.address.province"
                        [label]="'المحافظة'"
                        (valueChange)="basicInfoService.onProvinceChange($event)"
                    >
                    </app-selectpicker>
                </div>
                <div class="col-md-4">
                    <label for="county" class="form-label">ألقضاء :</label>
                    <select
                        #mySelect
                        class="custom-select form-control"
                        [(ngModel)]="basicInfoService.employee.address.county"
                        name="county"
                        [value]="basicInfoService.employee.address.county"
                        required
                    >
                        <option
                            *ngFor="let item of basicInfoService.cities;let i=index;"
                            [value]="item"
                        >
                            {{item}}
                        </option>
                    </select>
                </div>
                <div class="col-md-4">
                    <label for="city" class="form-label">
                        ألمدينة أو الناحية :
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        name="city"
                        id="city"
                        placeholder=""
                        [(ngModel)]="basicInfoService.employee.address.city"
                        required
                        #city="ngModel"
                        lang="ar"
                        [ngClass]="{ 'is-invalid':  city.errors && frm.submitted}"
                    />
                    <div *ngIf="city.invalid" class="invalid-feedback">
                        <div *ngIf="city.errors.required!=null">
                            أسم ألمدينة أو ألناحية مطلوب
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label for="quarter" class="form-label">
                        أسم ألحي أو ألمنطقة :
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        name="quarter"
                        id="quarter"
                        placeholder=""
                        [(ngModel)]="basicInfoService.employee.address.quarter"
                        required
                        #quarter="ngModel"
                        lang="ar"
                        [ngClass]="{ 'is-invalid':  quarter.errors && frm.submitted}"
                    />
                    <div *ngIf="quarter.invalid" class="invalid-feedback">
                        <div *ngIf="quarter.errors.required!=null">
                            أسم ألحي أو ألمنطقة مطلوب
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <label for="nearestPlace" class="form-label">
                        أقرب نقطة دالة :
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        name="nearestPlace"
                        id="nearestPlace"
                        placeholder=""
                        [(ngModel)]="basicInfoService.employee.address.nearestPlace"
                        #nearestPlace="ngModel"
                        lang="ar"
                        [ngClass]="{ 'is-invalid':  nearestPlace.errors && frm.submitted}"
                    />
                    <div *ngIf="nearestPlace.invalid" class="invalid-feedback">
                        <div *ngIf="nearestPlace.errors.required!=null">
                            اقرب نقطة دالة مطلوب
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                    <label for="street" class="form-label"> رقم ألمحلة: </label>
                    <input
                        type="text"
                        class="form-control"
                        name="street"
                        id="street"
                        placeholder=""
                        [(ngModel)]="basicInfoService.employee.address.street"
                        required
                        #street="ngModel"
                        lang="ar"
                        [ngClass]="{ 'is-invalid':  street.errors && frm.submitted}"
                    />
                    <div *ngIf="street.invalid" class="invalid-feedback">
                        <div *ngIf="street.errors.required!=null">
                            حقل ألمحلة مطلوب
                        </div>
                    </div>
                </div>

                <div class="col-4">
                    <label for="street" class="form-label"> ألزقاق: </label>
                    <input
                        type="text"
                        class="form-control"
                        name="substreet"
                        id="substreet"
                        placeholder=""
                        [(ngModel)]="basicInfoService.employee.address.subStreet"
                        required
                        #subStreet="ngModel"
                        lang="ar"
                        [ngClass]="{ 'is-invalid':  subStreet.errors && frm.submitted}"
                    />
                    <div *ngIf="subStreet.invalid" class="invalid-feedback">
                        <div *ngIf="subStreet.errors.required!=null">
                            ألزقاق مطلوب
                        </div>
                    </div>
                </div>

                <div class="col-4">
                    <label for="unitNumber" class="form-label">
                        رقم ألدار :
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        name="unitNumber"
                        id="unitNumber"
                        placeholder=""
                        [(ngModel)]="basicInfoService.employee.address.unitNumber"
                        required
                        #unitNumber="ngModel"
                        lang="ar"
                        [ngClass]="{ 'is-invalid':  unitNumber.errors && frm.submitted}"
                    />
                    <div *ngIf="unitNumber.invalid" class="invalid-feedback">
                        <div *ngIf="unitNumber.errors.required!=null">
                            رقم ألدار مطلوب
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div
    class="spinner text-secondary bg-creamy"
    *ngIf="basicInfoService.isLoading"
>
    جارٍ الحفظ
</div>
