<div class="wrapper">
    <div class="contents">
        <div class="container">
            <app-page-header-info title2="ألاجازات"></app-page-header-info>
            <div class="content-container my-7 mx-md-4">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class="sidebar left-sidebar border-right">
        <button class="btn close-sidebar-button border border-radius-25 btn-light">
            <i class="fa fa-times hover-danger text-dark"></i>
        </button>
        <ul class="list-unstyled mb-10 ">
            <li class="border-top-0">
                <a routerLink="summary" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> خلاصة ألأجازات
               </a>
            </li>
            <li>
                <a routerLink="timeoff" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> ألأجازات ألزمنية
               </a>
            </li>
            <li >
                <a class="text-nowrap" routerLink="normal-short" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> ألاجازات ألاعتيادية ألقصيرة
               </a>
            </li>
            <li >
                <a class="text-nowrap" routerLink="normal-long" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> ألاجازات ألاعتيادية ألطويلة
               </a>
            </li>
            <li >
                <a routerLink="absense" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> ألغيابات
               </a>
            </li>
            <li >
                <a routerLink="unpaid" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> ألاجازات بدون راتب
               </a>
            </li>
            <li >
                <a routerLink="study" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> ألاجازات ألدراسيه
               </a>
            </li>
            <li >
                <a routerLink="sick-leave" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> ألاجازات المرضية
               </a>
            </li>

            <li >
                <a routerLink="companionship" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> أجازات ألمصاحبه
               </a>
            </li>
            <li >
                <a routerLink="care" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> أجازات ألأعاله
               </a>
            </li>

            <li >
                <a routerLink="motherhood" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> أجازات ألامومة
               </a>
            </li>
            <li >
                <a routerLink="five-years" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> أجازة الخمس سنوات
               </a>
            </li>
            <li >
                <a routerLink="disability" routerLinkActive="active" >
                   <i class="fa fa-list  px-4"></i> أجازات ألعجز ألصحي  
               </a>
            </li>
        </ul>

    </div>
</div>
