<form>
    <div class="card-body">
        <div class="row border border-radius-25   bg-white mb-2">
            <div class="col-md-4">
                <div class="icheck-greensea ml-3">
                    <input class="box" type="checkbox" name="employmentDegree" [(ngModel)]="item.isEmploymentDegree" id="isEmploymentDegree">
                    <label for="isEmploymentDegree"> ألشهادة ألمتعين عليها ؟ </label>
                </div>
            </div>
            <div class="col-md-4">
                <div class="icheck-greensea ml-3">
                    <input class="box" type="checkbox" name="isRehireDegree" [(ngModel)]="item.isRehireDegree" id="isRehireDegree">
                    <label for="isRehireDegree">  شهادة أعادة ألتعيين ؟ </label>
                </div>
            </div>
            <div class="col-md-4">
                <div class="icheck-greensea ml-3">
                    <input class="box" type="checkbox" name="currentDegree" [(ngModel)]="item.isCurrentDegree" id="isCurrentDegree">
                    <label for="isCurrentDegree"> ألشهادة ألحالية ؟ </label>
                </div>
            </div>
        </div>

        
        <app-location-info [Item]="item"></app-location-info>
        <div class="row">
            <div class="col-md-4">
                <app-datepicker [input]="item.degreeDate" [name]="'degreeDate'" [label]="'تاريخ ألحصول على ألشهادة'"
                    (dateChange)="item.degreeDate=$event">
                </app-datepicker>
            </div>
            <div class="col-md-4" >
                <label for="documentNumber" class="form-label">   رقم ألأمر ألأداري للشهادة :</label>
                <div class="input-group">
                    <input type="text" id="documentNumber" name="documentNumber" #Booknumber="ngModel" class="form-control text-center"
                        [(ngModel)]="item.documentNumber">
                        <div class="input-group-prepend">
                            <span class="input-group-text font-bold">
                               #
                            </span>
                        </div>
                </div>
            </div>
            <div class="col-md-4">
                <label for="gpa" class="form-label">معدل ألتخرج (أختياري) :</label> 
                <div class="input-group">
                    <input type="number" class="form-control text-center" name="gpa" id="gpa" placeholder="" [(ngModel)]="item.gpa">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-user-graduate" aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"  *ngIf="degreeUtilService.isContryIraq(item.country)==false">
            <div class="col-md-4 offset-md-8">
                <label for="scholarship" class="form-label">نوع ألدراسة خارج ألقطر :</label>
                <select class="custom-select shadow-none" id="scholarship" name="scholarship"
                    [(ngModel)]="item.scholarshipType" required>
                    <option *ngFor="let scholarship of ScolarshipsTypes;let i=index;" [value]="scholarship">
                        {{scholarship}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row mx-0 mb-1 ">
            <div class="col-md-3 mt-1  pr-0">
                <label class="form-label mr-1"> نوع ألجامعة </label> <span class="float-none float-md-left px-2">:</span>
            </div>
            <div class="col-md-7 d-flex border border-radius-20"  >
                <div class="icheck-greensea ml-3 w-50" *ngFor="let type of HighEducationTypes;let i=index">
                    <input class="box" type="radio" name="educationType" [(ngModel)]="item.educationType" [value]="type"
                        id="educationType_{{i}}">
                    <label for="educationType_{{i}}" class="px-5"> {{type}} </label>
                </div>
            </div>
        </div>
        <div class="row  mx-0 pb-1">
            <div class="col-md-3 mt-2 pr-0">
                <label class="form-label mr-1"> ألدراسة </label> <span class="float-none float-md-left px-2">:</span>
            </div>
            <div class="col-md-7 d-flex  border border-radius-20" >
                <div class="icheck-greensea ml-3  w-50" *ngFor="let type of HighStudyingTypes;let i=index">
                    <input class="box" type="radio" name="studyingTyp" [(ngModel)]="item.studyingType" [value]="type"
                        id="studyingType_{{i}}">
                    <label for="studyingType_{{i}}" class="px-5"> {{type}} </label>
                </div>
            </div>

        </div>

        <div class="row mb-1">
            <div class="col-md-4 ">
                <label for="university" class="form-label mt-2">ألجامعة المانحة للشهادة</label>
                <span class="float-none float-md-left px-2 mt-2">:</span>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <input type="text" class="form-control" name="university" id="university" placeholder=""
                        [(ngModel)]="item.university" required #university="ngModel"
                        [ngClass]="{ 'is-invalid':  university.errors && degreeUtilService.isDegreeFormSubmitted}">
                    <div class="input-group-prepend">
                        <span class="input-group-text" (click)="degreeUtilService.openUniversityDialog(item)"><i class="fa fa-school fa-xs"></i></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-1">
            <div class="col-md-4 ">
                <label for="university" class="form-label mt-2"> ألمعهد</label>
                <span class="float-none float-md-left px-2 mt-2">:</span>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <input type="text" class="form-control" name="college" id="college" placeholder=""
                        [(ngModel)]="item.college" required #college="ngModel"
                        [ngClass]="{ 'is-invalid':  college.errors && degreeUtilService.isDegreeFormSubmitted}">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-university"></i></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 ">
                <label for="university" class=" mt-2"> ألقسم أو ( ألفرع ) </label>
                <span class="float-none float-md-left px-2 mt-2">:</span>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <input type="text" class="form-control" name="department" id="department" placeholder=""
                        [(ngModel)]="item.department" required #department="ngModel"
                        [ngClass]="{ 'is-invalid':  department.errors && degreeUtilService.isDegreeFormSubmitted}">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fa fa-university"></i></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-1">
            <div class="col-md-4 ">
                <label for="university" class=" mt-2"> ألتخصص ألعام </label>
                <span class="float-none float-md-left px-2 mt-2">:</span>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <input type="text" class="form-control" name="generalSpecialization" id="generalSpecialization"
                        placeholder="" [(ngModel)]="item.generalSpecialization" required
                        #generalSpecialization="ngModel"
                        [ngClass]="{ 'is-invalid':  generalSpecialization.errors && degreeUtilService.isDegreeFormSubmitted}">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 ">
                <label for="university" class=" mt-2"> ألتخصص ألدقيق </label>
                <span class="float-none float-md-left px-2 mt-2">:</span>
            </div>
            <div class="col-md-6">
                <div class="input-group">
                    <input type="text" class="form-control" name="fieldOfSpecialization" id="fieldOfSpecialization"
                        placeholder="" [(ngModel)]="item.fieldOfSpecialization" required
                        #fieldOfSpecialization="ngModel"
                        [ngClass]="{ 'is-invalid':  fieldOfSpecialization.errors && degreeUtilService.isDegreeFormSubmitted}">
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="outline" dir="rtl" class="w-100 py-0">
                    <mat-label class=" px-3">ألملاحضات </mat-label>
                    <textarea matInput placeholder="" name="notes" rows="3" class="py-0" [(ngModel)]="item.notes"></textarea>
                    <mat-icon matSuffix class="text-secondary ">comment</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <app-files [Files]="item.Files" [uploadedFiles]="item.files"
            (fileDelete)="degreeUtilService.removeFile(item._id,$event)" [header]="'ألوثائق و ألاوامر ألادارية'">
        </app-files>
    </div>
</form>