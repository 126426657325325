<div class="my-1">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" dir="rtl" class="bg-transparent" >
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="30" class="custom-tree-node value"> 
            <!-- use a disabled button to provide padding for tree leaf -->
            <div class="d-flex lighten-font-color">
                <mat-icon class="mt-1 pl-2">remove</mat-icon>  <span style="font-size: 15px !important;">{{node.name}}</span>
            </div>
            
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding  matTreeNodePaddingIndent="30" class="custom-tree-node value">
            <div class="d-flex lighten-font-color">
                <mat-icon class="mt-1 pl-2">remove</mat-icon> <span style="font-size: 15px !important;">{{node.name}}</span>
            </div>
           
        </mat-tree-node>
    </mat-tree>
</div>
