
import { Files, ImageDimension, ImageFile } from "../../../classes/Files";
export class Documents {
    documents: Array<any> = [];
    addDocument(item: any) {
        this.documents.push(item);
    }
    removeDocument(item: any) {
        var index = this.documents.indexOf(item);
        this.documents.splice(index, 1);
    }

    isValid(): boolean {
        var length = this.documents.length;
        var validDocuments = this.documents.filter(
            (item) => item.isValid == true
        ).length;
        var result = length != 0 && validDocuments === length;
        return result;
    }
}

export class Document {
    type: string = "";
    isValid: boolean = false;
    isNew: boolean = true;
    faceOne: ImageFile = new ImageFile();
    faceTwo: ImageFile = new ImageFile();
    constructor(type: string) {
        this.type = type;
    }

    toFormData() {
        var data = new FormData();
        if (this.faceOne.file.name.length) {
            data.append(
                "faceone",
                this.faceOne.file,
                encodeURIComponent(
                    this.type +
                        "- وجه" +
                        "." +
                        this.getFileExtension(this.faceOne.file)
                )
            );
        }
        if (this.faceTwo.file.name.length) {
            data.append(
                "facetwo",
                this.faceTwo.file,
                encodeURIComponent(
                    this.type +
                        "- ظهر" +
                        "." +
                        this.getFileExtension(this.faceTwo.file)
                )
            );
        }

        var document = JSON.parse(JSON.stringify(this));
        delete document.faceOne;
        delete document.faceTwo;
        delete document.dimensions;
        delete document.isValid;
        data.append("document", JSON.stringify(document));
        return data;
    }

    getFileExtension(file: File): string {
        const fileName = file.name;
        const lastDotIndex = fileName.lastIndexOf(".");
        if (lastDotIndex === -1) {
            return ""; 
        }
        
        return fileName.substring(lastDotIndex + 1).toLowerCase();
    }
}

export class NationalId extends Document {
    dimensions: ImageDimension;
    nationalIdNumber: string = "";
    name: string = "";
    father: string = "";
    grandFather: string = "";
    sureName: string = "";
    mother: string = "";
    grandMother: string = "";
    gender: string = "";
    bloodCategory: string = "";
    idNumber: string = "";
    issuer: string = "";
    issueDate: string = "";
    expirationDate: string = "";
    placeOfBirth: string = "";
    dateOfBirth: string = "";
    familyIdNumber: string = "";

    faceone: any = null;
    facetwo: any = null;

    constructor(type: string) {
        super(type);
        this.dimensions = {
            width: "400px !important",
            height: "250px !important",
        };
    }
}
export class Passport extends Document {
    dimensions: ImageDimension;
    passportNumber: string = "";
    passportType: string = "P";
    arabicFullName: string = "";
    englishFullName: string = "IBRAHIM JAMEEL MUJHID";
    country: string = "IRQ";
    arabicSurname: string = "";
    englishSurname: string = "";
    arabicNationality: string = "عراقي";
    englishNationality: string = "IRAQI";
    arabicGender: string = "ذكر";
    englishGender: string = "M";
    arabicMotherName: string = "";
    englishMotherName: string = "";
    issueDate: string = "";
    expirationDate: string = "";
    dateOfBirth: string = "";
    arabicIssuer: string = "";
    englishIssuer: string = "";
    placeOfBirth: string = "";
    placeOfBirthCountry: string = "IRQ";

    faceone: any = null;

    constructor(type: string) {
        super(type);
        this.dimensions = {
            width: "400px !important",
            height: "500px !important",
        };
    }
}
export class RationCard extends Document {
    dimensions: ImageDimension;
    province: string = "";
    fromYear: number;
    toYear: number;
    cardNumber: string = "";
    houseHoldName = "";
    numberOfFamilyMembers: number;
    address: { m: string; z: string; d: string } = {
        m: "",
        z: "",
        d: "",
    };
    rationCenterNumber: string = "";
    rationCenterName: string = "";
    foodAgentNumber: string = "";
    foodAgentName = "";
    wheatAgentNumber: string = "";
    wheatAgentName: string = "";
    issueDate: string = "";
    familyMembers: Array<{ name: string }> = [];

    faceone: any = null;

    constructor(type: string) {
        super(type);
        this.dimensions = {
            width: "400px !important",
            height: "750px !important",
        };
        this.numberOfFamilyMembers = this.familyMembers.length;
    }
    addMember() {
        this.familyMembers.push({ name: "" });
    }
    removeMember(i: number) {
        this.familyMembers.splice(i, 1);
    }
}

export class CertificateOfNationality extends Document {
    dimensions: ImageDimension;
    walletNumber: string = "";
    certificateDate: string = "";
    certificateNumber: string = "";
    name: string = "";
    dateAndPlaceOfBirth: string = "";
    religion: string = "";
    defects: string = "";
    fatherName: string = "";
    fatherPlaceOfBirth: string = "";
    motherName: string = "";
    motherPlaceOfBirth: string = "";

    faceone: any = null;

    constructor(type: string) {
        super(type);
        this.dimensions = {
            width: "400px !important",
            height: "500px !important",
        };
    }
}
export class UniversityId extends Document {
    dimensions: ImageDimension;

    faceone: any = null;
    facetwo: any = null;

    constructor(type: string) {
        super(type);
        this.dimensions = {
            width: "400px !important",
            height: "350px !important",
        };
    }
}
export class CivilId extends Document {
    dimensions: ImageDimension;
    office: string = "";
    recordNumber: string = "";
    sheetNumber: string = "";
    cardNumber: string = "";
    name: string = "";
    fatherName: string = "";
    motherName: string = "";
    sureName: string = "";
    gender: string = "";
    employeeName: string = "";
    issueDate: string = "";
    officerName: string = "";

    job: string = "";
    religion: string = "";
    dateOfBirth: string = "";
    dateOfBirthWritten: string = "";
    placeOfBirth: string = "";
    maritialStatus: string = "";
    partenerName: string = "";
    placeOfRecording: string = "";
    defects: string = "";
    eyeColor: string = "";
    faceColor: string = "";
    hairColor: string = "";
    length: string = "";
    blood: string = "";

    faceone: any = null;
    facetwo: any = null;

    constructor(type: string) {
        super(type);
        this.dimensions = {
            width: "400px !important",
            height: "400px !important",
        };
    }
}
export class AddressCard extends Document {
    dimensions: ImageDimension;
    informationOffice: string = "";
    householdName: string = "";
    address: { m: string; z: string; d: string } = {
        m: "",
        z: "",
        d: "",
    };
    cardNumber: number;
    cardType: string = "";
    char: string = "";
    sequence: string ="";
    issueDate: string = "";
    officerName: string = "";

    faceone: any = null;
    facetwo: any = null;

    constructor(type: string) {
        super(type);
        this.dimensions = {
            width: "400px !important",
            height: "250px !important",
        };
    }
}
