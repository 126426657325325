import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { HttpLoadingService } from "../shared/http-loading.service";

@Injectable({
    providedIn: "root",
})
export class SystemRolesService {
    private url = environment.ApiUrl + "/roles/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    create(data: any): Observable<any> {
        return this.http.post(this.url , data);
    }

    findAll(): Observable<any> {
        return this.http.get(this.url + `?cache_bust=${new Date().getTime()}`);
    }

    update(roleId: string, data: any): Observable<any> {
        return this.http.patch(this.url + roleId, data);
    }

    remove(role: string): Observable<any> {
        return this.http.delete(this.url + role);
    }
}



@Injectable({
    providedIn: "root",
})
export class USerRolesService {
    private url = environment.ApiUrl + "/userroles/";
    constructor(
        private http: HttpClient,
        public loadingService: HttpLoadingService
    ) {}

    create(data: any): Observable<any> {
        return this.http.post(this.url , data);
    }

    findAll(userId:string): Observable<any> {
        return this.http.get(this.url +userId+ `?cache_bust=${new Date().getTime()}`);
    }

    update(roleId: string, data: any): Observable<any> {
        return this.http.patch(this.url + roleId, data);
    }

    remove(roleId: string): Observable<any> {
        return this.http.delete(this.url + roleId);
    }

    getRoleUsers(roleId:string):Observable<any>{
        return this.http.get(this.url +"roleusers/"+roleId+ `?cache_bust=${new Date().getTime()}`);
    }
}

