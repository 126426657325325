import {
    Component,
    OnInit,
} from "@angular/core";


@Component({
    selector: "app-system-roles",
    templateUrl: "./system-roles.html",
})export class SystemRolesComponent implements OnInit{
    ngOnInit(): void {
        
    }
}