import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DatePeriod, DateUtil, TimeUtil } from "src/app/classes/Utils";
import { VacationSummaryService } from "src/app/services/employee/vacations/vacation-summary.service";
import { VacationUtilService } from "../vacation-util.service";

class VacationRecord {
    title: string = "";
    count: number = 0;
    duration: string;
    formatedDuration: string;
    unit: string = "يوم";
    constructor(
        title: string,
        unit: string = "يوم",
        duration: number = 0,
        count: number = 0,
        totalDays: number = 0
    ) {
        this.title = title;
        this.count = count;
        this.unit = unit;
        if (this.unit == "ساعه") {
            this.duration = TimeUtil.formatTime(duration);
            this.formatedDuration =
                totalDays.toString().padStart(2, "0") + " يوم";
        } else {
            this.duration = duration.toString();
            this.formatedDuration = DateUtil.formatTotalDuration(duration);
        }
    }
}

class VacationSummaryRecord {
    title: string;
    records: Array<VacationRecord> = [];
    constructor(title: string) {
        this.title = title;
    }
}

@Component({
    selector: "app-vacation-sumarry",
    templateUrl: "./vacation-sumarry.component.html",
})
export class VacationSumarryComponent implements OnInit {
    userId: string = null;
    userVacations: any;
    allVacations: VacationRecord[] = [];

    unpaidCredit=731;
    employeeHireDate:Date=new Date("1-3-2012");

    isFilter = false;
    totalDays = 0;
    constructor(
        private vacationSummaryService: VacationSummaryService,
        private Activeroute: ActivatedRoute,
        public vacationUtilService: VacationUtilService
    ) {}

    async ngOnInit() {
        this.userId = await this.Activeroute.parent.parent.snapshot.params[
            "userId"
        ];
        this.findAll();
    }
    findAll() {
        if (this.isFilter) {
            this.filter();
            return;
        }
        this.vacationSummaryService
            .findAll(this.userId, this.vacationUtilService.isCurrentDate)
            .subscribe((response) => {
                if (response.length) {
                    this.userVacations = response[0];
                    this.resetAll();
                }
            });
    }

    filter() {
        const filterDates = {
            fromDate: this.vacationUtilService.filterDates.fromDate,
            toDate: this.vacationUtilService.filterDates.toDate,
            isCurrentDate: this.vacationUtilService.isCurrentDate,
        };
        this.vacationSummaryService
            .filter(this.userId, filterDates)
            .subscribe((response) => {
                if (response.length) {
                    this.userVacations = response[0];
                    this.resetAll();
                }
            });
    }
    onFilterChange(event: boolean) {
        this.isFilter = event;
        this.findAll();
    }

    resetAll() {
        this.totalDays = 0;
        this.allVacations = [];
        this.VacationSummaryRecords=[];
        this.CreditSummaryRecords=[];
        this.getAllVacations();
        this.getVacationSummary();
        this.getCreditSummary();
    }

    getAllVacations() {
        var timeOffRecord = new VacationRecord("ألأجازات ألزمنيه", "ساعه");
        if (this.userVacations.timeOff.length) {
            var timeOff = this.userVacations.timeOff[0];
            this.totalDays += timeOff.totalDays;
            timeOffRecord = new VacationRecord(
                "ألأجازات ألزمنيه",
                "ساعه",
                timeOff.duration,
                timeOff.count,
                timeOff.totalDays
            );
        }
        this.allVacations.push(timeOffRecord);
        this.vacationUtilService.vacationDict.forEach((vacation) => {
            var matchedVacation = this.userVacations.vacations.find(
                (v) => v._id == vacation._id
            );
            if (matchedVacation) {
                this.totalDays += matchedVacation.duration;
                this.allVacations.push(
                    new VacationRecord(
                        vacation.title,
                        "يوم",
                        matchedVacation.duration,
                        matchedVacation.count
                    )
                );
            } else {
                this.allVacations.push(
                    new VacationRecord(vacation.title, "يوم")
                );
            }
        });
    }

    VacationSummaryRecords:Array<VacationSummaryRecord>=[];
    getVacationSummary() {
        var allVacationsRecord = new VacationSummaryRecord("جميع ألاجازات");
        allVacationsRecord.records.push(
            new VacationRecord(
                "مجموع ألأجازات ألكلي",
                "يوم",
                this.totalDays,
                0
            )
        );

        var groupedByServiceRecord = new VacationSummaryRecord(
            "ألاجازات حسب نوع ألخدمه"
        );
        var retirmentCountlessRecord: VacationRecord = new VacationRecord(
            "لا تحتسب لأغراض ألخدمة ألتقاعديه",
            "يوم"
        );
        var matchedRecord = this.userVacations.retirementCountlessGroup.find(
            (v) => v._id == true
        );
        if (matchedRecord) {
            retirmentCountlessRecord = new VacationRecord(
                "لا تحتسب لأغراض ألخدمة ألتقاعديه",
                "يوم",
                matchedRecord.duration,
                matchedRecord.count
            );
        }
        var upgradeCountlessRecord: VacationRecord = new VacationRecord(
            " لا تحتسب لأغراض ألعلاوة وألترفيع",
            "يوم"
        );
        matchedRecord = this.userVacations.upgradeCountlessGroup.find(
            (v) => v._id == true
        );
        if (matchedRecord) {
            upgradeCountlessRecord = new VacationRecord(
                "لا تحتسب لأغراض ألعلاوة وألترفيع",
                "يوم",
                matchedRecord.duration,
                matchedRecord.count
            );
        }

        groupedByServiceRecord.records.push(upgradeCountlessRecord);
        groupedByServiceRecord.records.push(retirmentCountlessRecord);


        var groupedByPayrollRecord = new VacationSummaryRecord(
            "ألاجازات حسب نوع ألراتب"
        );
        this.vacationUtilService.paymentTypes.forEach((payment) => {
            var matchedVacation = this.userVacations.paymentTypeGroup.find(
                (v) => v._id == payment
            );
            if (matchedVacation) {
                groupedByPayrollRecord.records.push(
                    new VacationRecord(
                        payment,
                        "يوم",
                        matchedVacation.duration,
                        matchedVacation.count
                    )
                );
            } else {
                groupedByPayrollRecord.records.push(
                    new VacationRecord(payment, "يوم")
                );
            }
        });
       
        this.VacationSummaryRecords.push(groupedByServiceRecord);
        this.VacationSummaryRecords.push(groupedByPayrollRecord);
        this.VacationSummaryRecords.push(allVacationsRecord);
    }

    CreditSummaryRecords:Array<VacationSummaryRecord>=[];
    getCreditSummary(){
        var currentDate=new Date();
        var employeeHireDate=new Date("2012-04-01")
        const diffMonths = (currentDate.getFullYear() - employeeHireDate.getFullYear()) * 12 +
            (currentDate.getMonth() - employeeHireDate.getMonth()); 
        var deservedCredit:number= diffMonths*3;
        var takenCredit:number=0;
        var numberOfVacations=0;
        for(var i=0;i<3;i++){
            takenCredit=takenCredit+ parseInt(this.allVacations[i].duration, 10);
            numberOfVacations=numberOfVacations+this.allVacations[i].count;
        }
        numberOfVacations=numberOfVacations-this.allVacations[0].count-1;
        var normalCreditRecord = new VacationSummaryRecord("رصيد ألأجازات ألأعتياديه");
        normalCreditRecord.records.push(new VacationRecord("رصيد ألأجازات ألأعتياديه ألمستحق",'يوم',deservedCredit,diffMonths));
        normalCreditRecord.records.push(new VacationRecord("ألأجاز ألمحسوبه من ألرصيد ألأعتيادي",'يوم',takenCredit,numberOfVacations));
        normalCreditRecord.records.push(new VacationRecord("رصيد ألأجازات ألأعتياديه ألمتبقي",'يوم',deservedCredit-takenCredit,numberOfVacations));
        this.CreditSummaryRecords.push(normalCreditRecord);

        var unpaidTakenCredit=parseInt(this.allVacations[4].duration);
        var unpaidCreditRecord = new VacationSummaryRecord("رصيد ألأجازات بدون راتب");
        unpaidCreditRecord.records.push(new VacationRecord("رصيد ألأجازات ألمستحق",'يوم',this.unpaidCredit,0));
        unpaidCreditRecord.records.push(new VacationRecord("ألأجاز بدون راتب ألمحسوبه من ألرصيد",'يوم',unpaidTakenCredit,this.allVacations[4].count));
        unpaidCreditRecord.records.push(new VacationRecord("رصيد ألأجازات  ألمتبقي",'يوم',this.unpaidCredit-unpaidTakenCredit,numberOfVacations));
        this.CreditSummaryRecords.push(unpaidCreditRecord);



        
    }
}
