<div class="wrapper ">
    <div class="sidebar right-sidebar border-left">
        <ul class="list-unstyled">
            <li>
                <a routerLink="Organizational-structures" routerLinkActive="active" >
                    <i class="fas fa-university px-4 "></i> ألهياكل ألتنظيمية
               </a>
            </li>
            <li>
                <a routerLink="grades" routerLinkActive="active" >
                    <i class="fas fa-signal px-4"></i> ألدرجات ألوظيفيه
               </a>
            </li>

            <li>
                <a routerLink="roles" routerLinkActive="active" >
                    <i class="fas fa-user-lock px-4"></i> ألادوار و ألصلاحيات
               </a>
            </li>

            <li>
                <a routerLink="allowances" routerLinkActive="active" >
                    <i class="fas fa-money-bill-alt px-4"></i> ألمخصصات ألمالبة
               </a>
            </li>
        </ul>
    </div>
    <div class="contents">
            <router-outlet></router-outlet>
    </div>
</div>

