<div>
    <div *ngIf="Item == null">
        <div class="card">
            <div class="card-header">
                <h4 class="pr-1">
                    <i class="fa fa-id-card-alt px-2"></i> أضافة وثيقه جديده
                </h4>
                <div
                    class="toolbar d-flex d-inline-flex w-30 justify-content-between"
                >
                    <button
                        class="btn btn-print"
                        title="رجوع ألى ألقائمه"
                        (click)="viewChange.emit('list')"
                    >
                        <i class="fa fa-share fa-lg"></i>
                    </button>
                </div>
            </div>
            <div
                class="card-body"
                cdkDropList
                style="min-height: 400px; background-image: none"
                id="container"
                (cdkDropListDropped)="dropDocument($event)"
            >
                <div class="d-flex"></div>
            </div>
        </div>
    </div>
    <div
        class="card"
        [id]="Item.type"
        [ngSwitch]="Item.type"
        *ngIf="Item != null"
    >
        <div class="card-header">
            <h4 class="pr-1">
                <i class="fa fa-id-card px-2"></i> {{ Item.type }}
            </h4>

            <div
                class="toolbar d-flex d-inline-flex w-30 justify-content-between"
            >
                <button
                    class="btn btn-print"
                    (click)="submit()"
                    [title]="'حفظ'"
                >
                    <i class="fa fa-save fa-lg"></i>
                </button>
                <button
                    class="btn btn-print"
                    title="رجوع ألى ألقائمه"
                    (click)="viewChange.emit('list')"
                >
                    <i class="fa fa-share fa-lg"></i>
                </button>
            </div>
        </div>
        <div class="card-body px-0">
            <app-document-national-id
                *ngSwitchCase="'ألبطاقة ألوطنية'"
                [item]="Item"
            ></app-document-national-id>
            <app-document-civil-id
                *ngSwitchCase="'هوية ألأحوال ألمدنية'"
                [item]="Item"
            ></app-document-civil-id>
            <app-document-address-card
                *ngSwitchCase="'بطاقة ألسكن'"
                [item]="Item"
            ></app-document-address-card>
            <app-document-certificate-of-nationality
                *ngSwitchCase="'ألجنسية ألعراقية'"
                [item]="Item"
            ></app-document-certificate-of-nationality>
            <app-document-ration-card
                *ngSwitchCase="'ألبطاقة ألتموينية'"
                [item]="Item"
            ></app-document-ration-card>
            <app-document-passport
                *ngSwitchCase="'جواز ألسفر'"
                [item]="Item"
            ></app-document-passport>
            <app-document-university-id
                *ngSwitchCase="'ألهوية ألجامعية'"
                [item]="Item"
            ></app-document-university-id>
        </div>
    </div>
</div>
<div class="spinner text-secondary bg-creamy" *ngIf="isLoading">جارٍ الحفظ</div>
