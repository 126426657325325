import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
@Injectable({
    providedIn: "root",
})
export class EmployeeSummariesService {
    private url = environment.ApiUrl + "/employeesummaries/";

    constructor(private http: HttpClient) {}

    findGeneralSummary(userId: string): Observable<any> {
        return this.http.get(
            this.url +
                "generalSummary/" +
                userId +
                `?cache_bust=${new Date().getTime()}`
        );
    }
}
