import { Files } from "src/app/classes/Files";

export class FacultyPromotionModel {
    _id: string = null;
    isCurrent: boolean = false;
    type: string = "";
    promotionDate: Date = new Date();
    documentDate: Date = new Date();
    documentNumber: string = "";
    generalSpecialization: string = "";
    fieldOfSpecialization: string = "";
    Files: Files = new Files();
    promotionReason: string = "أعتيادية";
    notes: string = "";
    files: any[] = [];
    constructor(type: string) {
        this.type = type;
    }

    toFormData(): FormData {
        var data = new FormData();
        if (this._id != null) {
            data.append("_id", this._id);
        }
        data.append("type", this.type);
        data.append("documentNumber", this.documentNumber);
        data.append("promotionDate", this.promotionDate?.toString());
        data.append("documentDate", this.documentDate?.toString());
        data.append("generalSpecialization", this.generalSpecialization);
        data.append("fieldOfSpecialization", this.fieldOfSpecialization);
        data.append("promotionReason", this.promotionReason);
        data.append("notes", this.notes);
        this.Files.appendFilesToForm(data);
        return data;
    }
}
